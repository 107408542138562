import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { set } from "lodash";
import { BillingInterface, PaymentInterface } from "./types";

export const useClinicSlice = createSlice({
  name: "paymentState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCancelSubscription: (state, action: PayloadAction<boolean>) => {
      state.cancelSubscription = action.payload;
    },
    setPaymentSuccessful: (state, action: PayloadAction<boolean>) => {
      state.paymentSuccessful = action.payload;
    },

    clearFrom: (state) => {
      state.form._id = "";
      state.form.companyName = "";
      state.form.email = "";
      state.form.taxId = "";
      state.form.address = "";
    },
    doGetEdit: (state) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},

    setDataForEdit: (state, action: PayloadAction<PaymentInterface>) => {
      state.form = action.payload;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    doCancelSubscription: (
      state,
      action: PayloadAction<{ callback: (data: any) => void }>
    ) => {},
    doUpdateSubscription: (
      state,
      action: PayloadAction<{ callback: (data: any) => void }>
    ) => {},
    doGetBillingList: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    setBillingList: (state, action: PayloadAction<Array<BillingInterface>>) => {
      state.billingList = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setShotColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
