import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { GoogleAuthInterface, PasswordInterface } from "./types";
import { set } from "lodash";

export const useClinicSlice = createSlice({
  name: "updatePasswordState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },

    clearFrom: (state) => {
      state.form.password = "";
      state.form.newPassword = "";
      state.form.confirmPassword = "";
    },
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    //google Auth
    doGetGoogleAuthDetail: (
      state,
      action: PayloadAction<{
        callback: (passwordUpdateCount: number, googleLogin: boolean) => void;
      }>
    ) => {},
    updateGoogleAuthFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `googleAuthForm.${action.payload.key}`, action.payload.value);
    },
    doUpdateGoogleAuth: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    setGoogleDataForEdit: (
      state,
      action: PayloadAction<GoogleAuthInterface>
    ) => {
      state.googleAuthForm = action.payload;
    },

    toggleShowPassword: (state) => {
      state.password = !state.password;
    },
    toggleShowNewPassword: (state) => {
      state.newPassword = !state.newPassword;
    },
    toggleShowConfirmPassword: (state) => {
      state.confirmPassword = !state.confirmPassword;
    },
    doUpdateGooglePassword: (state) => {},
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
