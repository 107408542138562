import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  doProjectShare,
  doAddProjectMember,
  doGetProjectInvitationDetail,
  doGetProjectSharedMember,
  doGetSharedProjectMembers,
  doVerifyProjectInvitation,
  doGetProjectMember,
  doGetProjectNotificationInvitationDetail,
  doVerifyProjectNotificationInvitation,
  GetProjectViaLinkInvitation,
  VerifyProjectViaLink,
} from "../../../utils/request";
import {
  selectForm,
  selectProjectInvitationForm,
  selectProjectMemberForm,
} from "./selector";
import {
  ProjectInvitationInterface,
  ProjectMemberInterface,
  SharedFormInterface,
  accessType,
} from "./types";
import CatchBlockFunction from "../../../Components/hooks/CatchError";
import { t } from "i18next";
import CustomToast from "../../../Components/CustomToast";
import { actions } from "./slice";

export function* doGetProjectMembersListRequest(action: { payload: string }) {
  yield delay(500);
  if (
    action.payload === undefined ||
    action.payload === null ||
    action.payload === "undefined"
  ) {
    return;
  }

  try {
    const response: AxiosResponse = yield call(
      doGetProjectSharedMember,
      action.payload
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setProjectMembersList(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetSharedProjectRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      doGetSharedProjectMembers,
      action.payload.id
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setDataForEdit(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doShareProjectRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: SharedFormInterface = yield select(selectForm);
  if (form.accessType !== accessType.ANYONE_WITH_LINK) {
    if (form.projectMember?.length == 0) {
      CustomToast(
        `${t("ERROR_MESSAGE.DASHBOARD_MESSAGES.SHARE_WITH_MEMBER")}`,
        "ERROR"
      );
      return;
    }
  }

  yield put(actions.setLoading(true));
  let data = {
    projectId: form.projectId,
    accessType: form.accessType,
    linkAccess: form.linkAccess,
    linkPassword:
      form?.linkPassword?.length > 0
        ? btoa(form.linkPassword)
        : form.linkPassword,
    projectMember: form.projectMember,
  };
  try {
    const response: AxiosResponse = yield call(doProjectShare, data);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetProjectInvitationDetailsRequest(action: {
  payload: string;
}) {
  yield delay(500);

  yield put(actions?.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      doGetProjectInvitationDetail,
      `?token=${action.payload}`
    );
    yield put(actions?.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setProjectDetails(response.data.data));
  } catch (error: any) {
    yield put(actions?.setLoading(false));
    if (error?.response?.data?.statusCode == 7004) {
      setTimeout(() => {
        if (localStorage.hasOwnProperty("token")) {
          sessionStorage.clear();
          window.location.replace("/dashboard");
        } else {
          window.location.replace("/sign-in");
        }
      }, 5000);
    }

    CatchBlockFunction(error);
  }
}

export function* doVerifyProjectInvitationRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: ProjectInvitationInterface = yield select(
    selectProjectInvitationForm
  );
  let data = {
    token: form?.token,
    password: btoa(form?.password as string),
  };
  let data2 = {
    token: form?.token,
  };

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      doVerifyProjectInvitation,
      String(form?.password)?.length > 0 ? data : data2
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doAddProjectMembersRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: ProjectMemberInterface = yield select(selectProjectMemberForm);

  if (form.projectMember?.length == 0) {
    CustomToast(
      `${t("ERROR_MESSAGE.DASHBOARD_MESSAGES.ADD_AT_LEAST_ONE_USER")}`,
      "ERROR"
    );

    return;
  }

  yield put(actions.setLoading(true));
  let data = {
    projectId: form.projectId,
    projectMember: form.projectMember,
  };
  try {
    const response: AxiosResponse = yield call(doAddProjectMember, data);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doEditProjectMembersRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      doGetProjectMember,
      action.payload
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setProjectMemberDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

// accepect project notification invitation
export function* doVerifyProjectNotificationInvitationRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  const form: ProjectInvitationInterface = yield select(
    selectProjectInvitationForm
  );
  let data = {
    notificationId: action.payload.id,
    password: btoa(form?.password as string),
  };
  let data2 = {
    notificationId: action.payload.id,
  };

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      doVerifyProjectNotificationInvitation,
      String(form?.password)?.length > 0 ? data : data2
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doGetProjectNotificationInvitationDetailsRequest(action: {
  payload: string;
}) {
  yield delay(500);

  yield put(actions?.setLoading(true));
  if (action.payload == undefined || action.payload == null) {
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      doGetProjectNotificationInvitationDetail,
      action.payload
    );

    yield put(actions?.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setProjectDetails(response.data.data));
  } catch (error: any) {
    yield put(actions?.setLoading(false));
    if (error?.response?.data?.statusCode == 7004) {
      setTimeout(() => {
        if (localStorage.hasOwnProperty("token")) {
          sessionStorage.clear();
          window.location.replace("/dashboard");
        } else {
          window.location.replace("/sign-in");
        }
      }, 5000);
    }

    CatchBlockFunction(error);
  }
}
export function* doGetProjectViaLinkInvitationRequest(action: {
  payload: string;
}) {
  yield delay(500);
  if (action.payload == undefined || action.payload == null) {
    return;
  }
  yield put(actions?.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      GetProjectViaLinkInvitation,
      action.payload
    );

    yield put(actions?.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setProjectDetails(response.data.data));
  } catch (error: any) {
    yield put(actions?.setLoading(false));

    if (error?.response?.data?.statusCode == 7004) {
      setTimeout(() => {
        if (localStorage.hasOwnProperty("token")) {
          sessionStorage.clear();
          window.location.replace("/dashboard");
        } else {
          window.location.replace("/sign-in");
        }
      }, 5000);
    }

    CatchBlockFunction(error);
  }
}
export function* doVerifyProjectViaLinkInvitationRequest(action: {
  payload: { code: string; callback: any };
}) {
  yield delay(500);
  let data = {
    link: action.payload.code,
  };

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      VerifyProjectViaLink,
      action.payload.code
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* SharedProjectRepoSaga() {
  yield takeLatest(
    actions.doGetProjectMembersList,
    doGetProjectMembersListRequest
  );
  yield takeLatest(actions.doGetSharedProjectEdit, doGetSharedProjectRequest);
  yield takeLatest(actions.doShareProject, doShareProjectRequest);
  yield takeLatest(
    actions.doGetProjectInvitationDetails,
    doGetProjectInvitationDetailsRequest
  );
  yield takeLatest(
    actions.doVerifyProjectInvitation,
    doVerifyProjectInvitationRequest
  );

  yield takeLatest(actions.doAddProjectMember, doAddProjectMembersRequest);
  yield takeLatest(actions.doGetProjectMemberEdit, doEditProjectMembersRequest);
  yield takeLatest(
    actions.doVerifyProjectNotificationInvitation,
    doVerifyProjectNotificationInvitationRequest
  );
  yield takeLatest(
    actions.doGetProjectNotificationInvitation,
    doGetProjectNotificationInvitationDetailsRequest
  );
  yield takeLatest(
    actions.doGetProjectViaLinkInvitation,
    doGetProjectViaLinkInvitationRequest
  );
  yield takeLatest(
    actions.doVerifyProjectViaLinkInvitation,
    doVerifyProjectViaLinkInvitationRequest
  );
}
