import Queue from "../../Components/Common/Queue";
import { log } from "../../utils/logger";

export default class ItemProvider {

  private _isBusy: boolean=false;

  constructor() {

  }

  public enqueue(job: any) {
    //   log("Enqueing", job);
    // we'll wrap the job in a promise and include the resolve
    // and reject functions in the job we'll enqueue, so we can
    // control when we resolve and execute them sequentially

    Queue.push(job);

    log("enqueue", Queue);
    // we'll add a nextJob function and call it when we enqueue a new job;
    // we'll use _isBusy to make sure we're executing the next job sequentially
    this.nextJob();
  }

  private nextJob() {
    if (this._isBusy) return;
    const next = Queue.shift();
    log("next", next, Queue);

    // if the array is empty shift() will return undefined
    if (next) {
      this._isBusy = true;
      next()
        .then((value: any) => {
          log(value);
          this._isBusy = false;
          this.nextJob();
        })
        .catch((error: any) => {
          console.error(error);
          this._isBusy = false;
          this.nextJob();
        });
    }
  }
}
