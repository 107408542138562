import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { set } from "lodash";
import {
  TeamInterface,
  TeamDetailsInterface,
  TeamRestoreDetailsInterface,
  ProjectRestoreDetailsInterface,
} from "./types";

export const useClinicSlice = createSlice({
  name: "teamState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTeamLoading: (state, action: PayloadAction<boolean>) => {
      state.teamLoading = action.payload;
    },
    doAddTeamProject: (state, action: PayloadAction<{ callback: any }>) => {},
    doGetList: (state) => {},
    setList: (state, action: PayloadAction<Array<TeamInterface>>) => {
      state.list = action.payload;
    },
    doGetSharedTeamList: (state) => {},
    setSharedTeamList: (state, action: PayloadAction<Array<TeamInterface>>) => {
      state.sharedTeamList = action.payload;
    },
    updateTeamInvitationFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `teamInvitation.${action.payload.key}`, action.payload.value);
    },
    doVerifyTeamInvitation: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    doGetTeamInvitationDetails: (state, action: PayloadAction<string>) => {},
    setTeamDetails: (state, action: PayloadAction<TeamDetailsInterface>) => {
      state.teamInvitationDetails = action.payload;
    },
    // team restore
    doRestoreTeam: (state, action: PayloadAction<{ callback: any }>) => {},
    doGetTeamRestoreDetails: (state, action: PayloadAction<string>) => {},
    setTeamRestoreDetails: (
      state,
      action: PayloadAction<TeamRestoreDetailsInterface>
    ) => {
      state.teamRestoreDetails = action.payload;
    },
    updateTeamRestoreFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `teamRestore.${action.payload.key}`, action.payload.value);
    },
    //*******Project restore */
    doRestoreProject: (
      state,
      action: PayloadAction<{ token: string; callback: any }>
    ) => {},
    doGetProjectRestoreDetails: (state, action: PayloadAction<string>) => {},
    setProjectRestoreDetails: (
      state,
      action: PayloadAction<ProjectRestoreDetailsInterface>
    ) => {
      state.projectRestoreDetails = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
