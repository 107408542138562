import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../../types";

const selectDomain = (state: RootState) => {
  if (state && state.teamState) {
    return state.teamState;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectTeamLoading = createSelector(
  [selectDomain],
  (state) => state.teamLoading
);
export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectSharedTeamList = createSelector(
  [selectDomain],
  (state) => state.sharedTeamList
);
export const selectTeamInvitationForm = createSelector(
  [selectDomain],
  (state) => state.teamInvitation
);
export const selectTeamInvitationDetailsForm = createSelector(
  [selectDomain],
  (state) => state.teamInvitationDetails
);
export const selectTeamRestoreDetailsForm = createSelector(
  [selectDomain],
  (state) => state.teamRestoreDetails
);
export const selectProjectRestoreDetails = createSelector(
  [selectDomain],
  (state) => state.projectRestoreDetails
);
export const selectTeamRestoreForm = createSelector(
  [selectDomain],
  (state) => state.teamRestore
);
