import { PaymentState } from ".";

export const initialState: PaymentState = {
  loading: false,
  form: {
    _id: "",
    companyName: "",
    email: "",
    taxId: "",
    address: "",
  },
  paymentSuccessful: false,
  cancelSubscription: false,
  billingList: [],
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  sortColumn: "",
  sortDir: "",
};
