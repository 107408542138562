import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  BillingHistory,
  CancelSubscription,
  doGetPaymentDetails,
  doUpdatePaymentDetails,
  getAPI,
} from "../../../utils/request";
import {
  selectForm,
  selectPageNo,
  selectPageSize,
  selectSortColumn,
  selectSortDir,
} from "./selector";
import { t } from "i18next";
import { PaymentInterface } from "./types";
import CatchBlockFunction from "../../../Components/hooks/CatchError";
import CustomToast from "../../../Components/CustomToast";
import { actions } from "./slice";
import { emailRegex } from "../../../utils/constants";

export function* doGetPaymentDetailsRequest() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetPaymentDetails);
    if (response && !response.data) {
      return;
    }
    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdatePaymentDetailsRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: PaymentInterface = yield select(selectForm);
  if (form.companyName.length === 0) {
    CustomToast(
      `${t("ERROR_MESSAGE.SUBSCRIPTION_MESSAGES.ENTER_COMPANY_NAME")}`,
      "ERROR"
    );
    return;
  }
  if (form.email.length === 0) {
    CustomToast(
      `${t("ERROR_MESSAGE.SUBSCRIPTION_MESSAGES.ENTER_BILLING_EMAIL")}`,
      "ERROR"
    );
    return;
  }
  if (!emailRegex.test(form.email)) {
    CustomToast(
      `${t("ERROR_MESSAGE.SUBSCRIPTION_MESSAGES.ENTER_VALID_BILLING_EMAIL")}`,
      "ERROR"
    );
    return;
  }
  if (form.address.length === 0) {
    CustomToast(
      `${t("ERROR_MESSAGE.SUBSCRIPTION_MESSAGES.ENTER_BILLING_ADDRESS")}`,
      "ERROR"
    );
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doUpdatePaymentDetails, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doCancelSubscriptionRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  yield put(actions.setCancelSubscription(true));
  try {
    const response: AxiosResponse = yield call(CancelSubscription);
    yield put(actions.setCancelSubscription(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield call(action.payload.callback(response.data.data));
  } catch (error: any) {
    yield put(actions.setCancelSubscription(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateSubscriptionRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  yield put(actions.setCancelSubscription(true));
  try {
    const response: AxiosResponse = yield call(
      getAPI,
      `order/payment-method-update`
    );
    yield put(actions.setCancelSubscription(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield call(action.payload.callback(response.data.data));
  } catch (error: any) {
    yield put(actions.setCancelSubscription(false));
    CatchBlockFunction(error);
  }
}
export function* doGetBillingListRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  yield put(actions.setLoading(true));
  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const sortColumn: string = yield select(selectSortColumn);
    const sortDir: string = yield select(selectSortDir);
    const response: AxiosResponse = yield call(
      BillingHistory,
      `?pageNo=${pageNo}&pageSize=${pageSize}${
        sortColumn.length > 0 ? "&sortColumn=" + sortColumn : ""
      }${sortDir.length > 0 ? "&sortDir=" + sortDir : ""}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta.totalPages));
    yield put(actions.setBillingList(response.data.data.results));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* PaymentRepoSaga() {
  yield takeLatest(actions.doGetEdit, doGetPaymentDetailsRequest);
  yield takeLatest(actions.doUpdate, doUpdatePaymentDetailsRequest);
  yield takeLatest(actions.doCancelSubscription, doCancelSubscriptionRequest);
  yield takeLatest(actions.doUpdateSubscription, doUpdateSubscriptionRequest);
  yield takeLatest(actions.doGetBillingList, doGetBillingListRequest);
}
