export interface NotificationSettingInterface {
  projectActivity: boolean;
  mention: boolean;
  inAppNotification: boolean;
  timeZone: string;
  notificationTime: NotificationTimeEnum;
}

export enum NotificationTimeEnum {
  HOURLY = 1,
  DAILY = 2,
}

export interface NotificationListInterface {
  _id: string;
  title: string;
  japaneseTitle: string;
  userId: string;
  name: string;
  receiverId: string;
  type: NotificationTypeEnum;
  readStatus: NotificationReadStatusEnum;
  versionNumber: number;
  versionCount: number;
  createdAt: string;
  updatedAt: string;
  parentFileId: string;
  profilePic: string;
  commentId: string;
  replyId: string;
  reply: string;
  comment: string;
  fileId: string;
  fileVersionId: string;
  projectId: string;
  projectName: string;
  projectOwner: string;
  fileName: string;
  fileVersionName: string;
  projectPhoto: string;
  hasPassword: boolean;
  projectRootFolderId: string;
}
export interface TimeZonesListInterface {
  _id: string;
  timeZone: string;
  timeZoneName: string;
  offset: number;
  value: string;
}

export enum NotificationTypeEnum {
  USER_SIGNUP = 1,
  USER_MENTIONED = 2,
  COMMENT_ADD = 3,
  REPLY_ADD = 4,
  REPLY_MARK_AS_COMPLETE = 5, //
  COMMENT_MARK_AS_COMPLETE = 6, //
  REPLY_MARK_INCOMPLETE = 7, //
  COMMENT_MARK_INCOMPLETE = 8, //
  FILE_VERSION_ADD = 9,
  VERSION_STATUS_UPDATE = 10, //
  FOLDER_ADD = 11, //
  PROJECT_SHARE = 12,
  PROJECT_DELETE = 13,
  FILE_ADDED = 14,
  PLAN_UPGRADE = 15,
  PLAN_DOWNGRADE = 16,
  PAYMENT_SUCCESS = 17,
  PAYMENT_FAIL = 18,
  PAYMENT_CANCEL = 21,
  PROJECT_ACCESS_REMOVE = 22,
  PAYMENT_EXPIRE_REMINDER = 23,
}

export enum NotificationReadStatusEnum {
  FALSE = 0,
  TRUE = 1,
}

export interface NotificationSettingState {
  form: NotificationSettingInterface;
  loading: boolean;
  pageChangeLoading: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  notifications: NotificationListInterface[];
  unreadNotifications: NotificationListInterface[];
  newNotification: number;
  timeZones: TimeZonesListInterface[];
  markAllRead: boolean;
}

export type InitialNotificationSettingState = NotificationSettingState;
