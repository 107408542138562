import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { set } from "lodash";
import {
  CommentFormInterface,
  CommentTypeEnum,
  CreateVersionInterface,
  FileVersionsListInterface,
  MembersListInterface,
  ProjectFormInterface,
  ReviewFileInterface,
  SelectedQualityFileInterface,
  UploadedFilesInterface,
  AttachedFiguresDataInterface,
  CommentIndicatorListInterface,
  UserFileShareInterface,
  IVersion,
  IReviewNavbar,
} from "./types";

export const useClinicSlice = createSlice({
  name: "reviewState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFileDataLoading: (state, action: PayloadAction<boolean>) => {
      state.fileDataLoading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setCarouselLoading: (state, action: PayloadAction<boolean>) => {
      state.carouselLoading = action.payload;
    },
    toggleAccessLink: (state, action: PayloadAction<boolean>) => {
      state.accessLink = action.payload;
    },
    toggleFileCarousel: (state, action: PayloadAction<boolean>) => {
      state.fileCarousel = action.payload;
    },
    setVersion: (state, action: PayloadAction<string>) => {
      state.version = action.payload;
    },
    setCompareVersion: (
      state,
      action: PayloadAction<{ index: number; version: number }>
    ) => {
      state.fileVersions[action.payload.index].versionNumber =
        action.payload.version;
    },
    toggleCompare: (state, action: PayloadAction<boolean>) => {
      state.compare = action.payload;
    },
    toggleShowComment: (state, action: PayloadAction<boolean>) => {
      state.showComment = action.payload;
    },
    setFileNotForShare: (state, action: PayloadAction<boolean>) => {
      state.fileNotForShare = action.payload;
    },
    doGetReviewFile: (state, action: PayloadAction<string>) => {},
    setReviewFileData: (state, action: PayloadAction<ReviewFileInterface>) => {
      state.reviewFileForm = action.payload;

      if (action?.payload?.possibleResolution?.length >= 1) {
        const originalIndex = action?.payload?.possibleResolution?.findIndex(
          (element) => {
            return element.filePath !== "";
          }
        );

        state.selectedQualityFile.filePath =
          action.payload.possibleResolution[originalIndex].filePath;
        state.selectedQualityFile.height =
          action.payload.possibleResolution[originalIndex].height;
      }
    },
    clearReviewFileForm: (state) => {
      state.reviewFileForm.filePath = "";
      state.reviewFileForm.name = "";
      state.selectedQualityFile.filePath = "";
      state.selectedQualityFile.height = 0;
    },

    doGetFoldersFile: (state, action: PayloadAction<string>) => {},
    setFoldersFile: (
      state,
      action: PayloadAction<Array<ReviewFileInterface>>
    ) => {
      state.folderFiles = action.payload;
    },
    setCommentIndicatorList: (
      state,
      action: PayloadAction<Array<CommentIndicatorListInterface>>
    ) => {
      state.commentIndicatorList = action.payload;
    },
    doAddProgressBarIndicator: (state, action: PayloadAction<string>) => {},
    doGetIndicatorList: (state, action: PayloadAction<string>) => {},
    doSendComment: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    updateCommentFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `commentForm.${action.payload.key}`, action.payload.value);
    },
    clearCommentForm: (state) => {
      state.commentForm._id = "";
      state.commentForm.fileId = "";
      state.commentForm.fileVersionId = "";
      state.commentForm.comment = "";
      state.commentForm.rawComment = "";
      // state.commentForm.commentType = CommentTypeEnum.PUBLIC;
      state.commentForm.attachedFiles = [];
      state.commentForm.attachedFigure = [];
      state.commentForm.startTime = "";
      state.commentForm.endTime = "";
      state.commentForm.userId = "";
      state.commentForm.name = "";
      state.commentForm.profilePic = "";
      state.commentForm.createdAt = "";
      state.commentForm.updatedAt = "";
      state.commentForm.width = 0;
      state.commentForm.mentionedMember = [];
      state.commentForm.millisecondEndTime = 0;
      state.commentForm.millisecondStartTime = 0;
      state.isMessageClear = true;
    },
    setCommentFormData: (
      state,
      action: PayloadAction<CommentFormInterface>
    ) => {
      state.commentForm = action.payload;
    },

    setProjectData: (state, action: PayloadAction<ProjectFormInterface>) => {
      state.projectData = action.payload;
    },
    //Members list
    doGetMembersList: (state, action: PayloadAction<string>) => {},
    doGetSelfMembersList: (state, action: PayloadAction<string>) => {},
    setMembersList: (
      state,
      action: PayloadAction<Array<MembersListInterface>>
    ) => {
      state.membersList = action.payload;
    },
    setSelfMembersList: (
      state,
      action: PayloadAction<Array<MembersListInterface>>
    ) => {
      state.selfMembersList = action.payload;
    },
    //file versions list
    doGetFileVersionsList: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    setFileVersionsList: (
      state,
      action: PayloadAction<Array<FileVersionsListInterface>>
    ) => {
      state.fileVersions = action.payload;
    },
    // file upload
    addFile: (
      state,
      action: PayloadAction<{
        fileName: any;
        fileVersionId: string;
        callback: (signedUrl: any, fileNameWithPrefix: any) => void;
      }>
    ) => {},
    uploadFile: (
      state,
      action: PayloadAction<{
        data: any;
        signedUrl: string;
        result: any;
        callback: () => void;
      }>
    ) => {},
    setUploadedFile: (state, action: PayloadAction<UploadedFilesInterface>) => {
      state.uploadedFiles.push(action.payload);
    },
    removeUploadedFile: (state, action: PayloadAction<string>) => {
      let index = "";
      for (const ele of state.uploadedFiles) {
        if (ele.fileName == action.payload) {
          index = state.uploadedFiles.indexOf(ele).toString();
        }
      }
      state.uploadedFiles[Number(index)].remove = true;
    },
    setAttachedFiles: (state, action: PayloadAction<any>) => {
      state.commentForm.attachedFiles = action.payload;
    },
    clearUploadedFiles: (state) => {
      state.uploadedFiles = [];
    },

    // ---------- Canvas Draw Start ------------ //

    toggleAddLine: (state, action: PayloadAction<boolean>) => {
      state.addLine = action.payload;
    },
    toggleDrawPencil: (state, action: PayloadAction<boolean>) => {
      state.drawPencil = action.payload;
    },
    toggleDrawArrow: (state, action: PayloadAction<boolean>) => {
      state.drawArrow = action.payload;
    },
    toggleDrawRect: (state, action: PayloadAction<boolean>) => {
      state.drawRect = action.payload;
    },
    toggleRedo: (state, action: PayloadAction<boolean>) => {
      state.redo = action.payload;
    },
    toggleUndo: (state, action: PayloadAction<boolean>) => {
      state.undo = action.payload;
    },
    toggleDrawData: (state, action: PayloadAction<boolean>) => {
      state.drawData = action.payload;
    },
    setDrawColor: (state, action: PayloadAction<string>) => {
      state.drawColor = action.payload;
    },
    setClearCanvas: (state, action: PayloadAction<boolean>) => {
      state.clearCanvas = action.payload;
    },

    // ---------- Canvas Draw End------------ //
    // version upload
    addVersion: (
      state,
      action: PayloadAction<{
        fileName: string;
        projectId: string;
        fileId: string;
        callback: (responseData: any) => void;
      }>
    ) => {},
    uploadVersion: (
      state,
      action: PayloadAction<{
        data: any;
        signedUrl: string;
        result: any;
        callback: () => void;
      }>
    ) => {},
    setCreateVersionData: (
      state,
      action: PayloadAction<CreateVersionInterface>
    ) => {
      state.createVersionData = action.payload;
    },
    doCreateVersion: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    setUploadingVersion: (state, action: PayloadAction<boolean>) => {
      state.uploadingVersion = action.payload;
    },
    clearCreateVersionData: (state) => {
      state.createVersionData.fileId = "";
      state.createVersionData.fileName = "";
      state.createVersionData.filePath = "";
      state.createVersionData.fileSize = "";
      state.createVersionData.projectId = "";
    },
    setUpdatedVersionsList: (state, action: PayloadAction<string>) => {
      state.updatedVersionsList.push(action.payload);
    },
    clearUpdatedVersionsList: (state) => {
      state.updatedVersionsList = [];
    },
    doUpdateVersionOrder: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},

    setAttachedFigureData: (
      state,
      action: PayloadAction<AttachedFiguresDataInterface | null>
    ) => {
      state.attachedFiguresData = action.payload;
    },
    setSelectedQualityFile: (
      state,
      action: PayloadAction<SelectedQualityFileInterface>
    ) => {
      state.selectedQualityFile = action.payload;
    },
    setStartTimeValue: (state, action: PayloadAction<string>) => {
      state.startTime = action.payload;
    },
    setEndTimeValue: (state, action: PayloadAction<string>) => {
      state.startTime = action.payload;
    },
    toggleIsPressed: (state, action: PayloadAction<boolean>) => {
      state.isPressed = action.payload;
    },

    toggleMarkTimeStamp: (state, action: PayloadAction<boolean>) => {
      state.markTimeStamp = action.payload;
    },
    setShowTimeLine: (state, action: PayloadAction<boolean>) => {
      state.showTimeLine = action.payload;
    },
    setShowTimeLinePress: (state, action: PayloadAction<boolean>) => {
      state.showTimeLinePress = action.payload;
    },

    toggleRenameVersion: (state, action: PayloadAction<boolean>) => {
      state.renameVersion = action.payload;
    },
    doRenameFileVersion: (
      state,
      action: PayloadAction<{ id: string; callback: any }>
    ) => {},
    updateRenameFileVersionFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `renameFileVersion.${action.payload.key}`,
        action.payload.value
      );
    },
    clearRenameFileVersionForm: (state) => {
      state.renameFileVersion.name = "";
    },
    doGetUserFileShareDetail: (
      state,
      action: PayloadAction<{
        id: string;
        hash: string;
        callback: (responseData: UserFileShareInterface) => void;
      }>
    ) => {},
    setUserFileShareDetail: (
      state,
      action: PayloadAction<UserFileShareInterface>
    ) => {
      state.userFileShareDetails = action.payload;
    },
    doVerifyAccessLinkPassword: (
      state,
      action: PayloadAction<{
        id: string;
        hash: string;
        callback: () => void;
      }>
    ) => {},
    updateAccessLinkFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `accessLinkForm.${action.payload.key}`, action.payload.value);
    },
    setCurrentAuthModal: (state, action: PayloadAction<string>) => {
      state.currentAuthModal = action.payload;
    },
    toggleShowRegister: (state, action: PayloadAction<boolean>) => {
      state.showRegister = action.payload;
    },
    setPlayCurrentTimeMode: (state, action: PayloadAction<number | null>) => {
      state.playCurrentTime = action.payload;
    },
    setSelectedCompereFile: (state, action: PayloadAction<string | null>) => {
      state.selectedCompereFile = action.payload;
    },
    setSelectedVersionV1: (state, action: PayloadAction<string | null>) => {
      state.versionV1 = action.payload;
    },
    setSelectedVersionV2: (state, action: PayloadAction<string | null>) => {
      state.versionV2 = action.payload;
    },
    setParentId: (state, action: PayloadAction<string | null>) => {
      state.parentId = action.payload;
    },
    setSelectedCompereIndex: (state, action: PayloadAction<number>) => {
      state.selectedCompereIndex = action.payload;
    },

    setVersionData: (
      state,
      action: PayloadAction<{ id: string; index: number }>
    ) => {
      const fileIndex = state.fileVersions.findIndex(
        (x) => x._id == action.payload.id
      );
      if (fileIndex == -1) {
        return;
      }
      const item: IVersion = {
        file_id: action.payload.id,
        fileData: state.fileVersions[fileIndex],
      };
      if (action.payload.index == 0) {
        if (state.versions.length > 0) {
          state.versions[action.payload.index] = item;
        } else {
          state.versions.push(item);
        }
      } else if (action.payload.index == 1) {
        if (state.versions.length == 2) {
          state.versions[action.payload.index] = item;
        } else {
          if (state.versions.length < 2) {
            state.versions.push(item);
          }
        }
      }
    },
    clearDrawSelection: (state) => {
      state.addLine = false;
      state.drawArrow = false;
      state.drawPencil = false;
      state.drawRect = false;
      state.undo = false;
      state.redo = false;
    },
    setResetState: (state) => {
      state.commentForm = initialState.commentForm;
      state.commentIndicatorList = initialState.commentIndicatorList;
      state.projectData = initialState.projectData;
      state.fileDataLoading = initialState.fileDataLoading;
      state.buttonLoading = initialState.buttonLoading;
      state.carouselLoading = initialState.carouselLoading;
      state.accessLink = initialState.accessLink;
      state.fileCarousel = initialState.fileCarousel;
      state.version = initialState.version;
      state.fileVersions = initialState.fileVersions;
      state.compare = initialState.compare;
      state.showComment = initialState.showComment;
      state.fileNotForShare = initialState.fileNotForShare;
      state.reviewFileForm = initialState.reviewFileForm;
      state.selectedQualityFile = initialState.selectedQualityFile;

      state.folderFiles = initialState.folderFiles;
      state.membersList = initialState.membersList;
      state.uploadedFiles = initialState.uploadedFiles;
      state.addLine = initialState.addLine;
      state.drawPencil = initialState.drawPencil;
      state.drawArrow = initialState.drawArrow;
      state.drawRect = initialState.drawRect;
      state.redo = initialState.redo;
      state.undo = initialState.undo;
      state.drawData = initialState.drawData;
      state.drawColor = initialState.drawColor;
      state.clearCanvas = initialState.clearCanvas;
      state.createVersionData = initialState.createVersionData;
      state.uploadingVersion = initialState.uploadingVersion;
      state.updatedVersionsList = initialState.updatedVersionsList;
      state.attachedFiguresData = initialState.attachedFiguresData;
      state.startTime = initialState.startTime;
      state.isPressed = initialState.isPressed;
      state.markTimeStamp = initialState.markTimeStamp;
      state.showTimeLine = initialState.showTimeLine;
      state.renameVersion = initialState.renameVersion;
      state.renameFileVersion = initialState.renameFileVersion;
      state.userFileShareDetails = initialState.userFileShareDetails;
      state.currentAuthModal = initialState.currentAuthModal;
      state.showRegister = initialState.showRegister;
      state.playCurrentTime = initialState.playCurrentTime;
      state.selectedCompereFile = initialState.selectedCompereFile;
      state.versionV1 = initialState.versionV1;
      state.versionV2 = initialState.versionV2;
      state.parentId = initialState.parentId;
    },
    setReviewNavbarHeight: (state, action: PayloadAction<IReviewNavbar>) => {
      state.reviewNavbar = action.payload;
    },
    setReviewCompareOptionBar: (
      state,
      action: PayloadAction<IReviewNavbar>
    ) => {
      state.reviewCompareOptionBar = action.payload;
    },
    setOptionBarHeight: (state, action: PayloadAction<IReviewNavbar>) => {
      state.reviewOptionBar = action.payload;
    },
    setPinSelection: (state, action: PayloadAction<boolean>) => {
      state.pinSelection = action.payload;
    },
    setShiftDrag: (state, action: PayloadAction<boolean>) => {
      state.shiftDrag = action.payload;
    },

    setOtpHash: (state, action: PayloadAction<string>) => {
      state.otpHash = action.payload;
    },
    setIsMessageClear: (state, action: PayloadAction<boolean>) => {
      state.isMessageClear = action.payload;
    },
    //any one with this link
    doGetFileViaLink: (
      state,
      action: PayloadAction<{
        code: string;
        callback: (responseData: UserFileShareInterface) => void;
      }>
    ) => {},
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
