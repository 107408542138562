import { ProjectMemberTypeEnum, SharedProjectState, accessType } from ".";

export const initialState: SharedProjectState = {
  loading: false,
  shareProject: false,
  projectInvitation: {
    token: "",
    password: "",
  },
  projectInvitationDetails: {
    projectId: "",
    fileId: "",
    projectName: "",
    projectOwner: "",
    projectPhoto: "",
    projectRootFolderId: "",
    hasPassword: false,
    alreadyShared: false,
    allowedProject: 0,
    totalProject: 0,
    memberRole: ProjectMemberTypeEnum.COLLABORATOR,
  },
  projectMemberList: [],
  form: {
    projectId: "",
    linkAccess: "",
    linkPassword: "",
    projectName: "",
    accessType: accessType.ONLY_INVITED,
    search: "",
    projectMember: [],
    email: "",
    remove: false,
  },
  sharedWithList: [],
  userForm: {
    _id: "",
    name: "",
    email: "",
    remove: false,
    isNew: true,
    profilePic: "",
    isInvited: false,
    invitationExpired: false,
    userStatus: 0,
  },
  addUser: false,
  projectMember: {
    search: "",
    projectId: "",
    projectMember: [],
    _id: "",
    name: "",
    profilePic: "",
    userId: "",
  },
  projectNotificationInvitationDetails: {
    projectId: "",
    fileId: "",
    notificationId: "",
    projectName: "",
    projectOwner: "",
    projectPhoto: "",
    hasPassword: false,
  },
};
