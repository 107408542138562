import { TeamState } from ".";

export const initialState: TeamState = {
  loading: false,
  teamLoading: false,
  notificationList: [],
  list: [],
  teamInvitation: {
    token: "",
  },
  teamInvitationDetails: {
    teamName: "",
    teamOwner: "",
    teamPhoto: "",
    projectId: "",
    fileId: "",
    teamId: "",
  },
  teamRestore: {
    token: "",
  },
  projectRestoreDetails: {
    _id: "",
    projectName: "",
    projectOwner: "",
    projectPhoto: "",
    projectId: "",
    fileId: "",
  },
  sharedTeamList: [],
  teamRestoreDetails: {
    _id: "",
    teamName: "",
    teamOwner: "",
    teamPhoto: "",
    projectId: "",
    fileId: "",
    teamId: "",
  },

};
