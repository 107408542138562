import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  doUpdateNotificationSettings,
  doGetNotificationSettings,
  doGetAllNotification,
  doReadAllNotification,
  doGetTimezones,
  doGetUnreadNotifications,
  NotificationRead,
} from "../../../utils/request";
import {
  selectForm,
  selectNotifications,
  selectPageNo,
  selectPageSize,
} from "./selector";
import { NotificationSettingInterface } from "./types";
import CatchBlockFunction from "../../../Components/hooks/CatchError";
import CustomToast from "../../../Components/CustomToast";
import { actions } from "./slice";

export function* doGetNotificationSettingsRequest() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetNotificationSettings);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateNotificationSettingsRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: NotificationSettingInterface = yield select(selectForm);

  let data = {
    projectActivity: form.projectActivity,
    mention: form.mention,
    inAppNotification: form.inAppNotification,
    notificationTime: Number(form.notificationTime),
    timeZone: form.timeZone,
  };
  try {
    const response: AxiosResponse = yield call(
      doUpdateNotificationSettings,
      data
    );
    if (response && !response.data) {
      return;
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
//notifications list
export function* doGetAllNotificationsListRequest() {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const response: AxiosResponse = yield call(
      doGetAllNotification,
      `?pageNo=${pageNo}&pageSize=${pageSize}`
    );
    yield put(actions.setLoading(false));
    yield put(actions.setPageChangeLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield put(actions.setTotalRow(response.data.data.meta.totalPages));
    yield put(actions.setNotificationsList(response.data.data.results));
    yield put(
      actions.setNewNotificationsCount(response.data.data.newNotification)
    );
  } catch (error: any) {
    yield put(actions.setLoading(false));
    yield put(actions.setPageChangeLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetUnreadNotificationsListRequest() {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doGetUnreadNotifications);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield put(actions.setUnreadNotificationsList(response.data.data.data));
    yield put(
      actions.setNewNotificationsCount(response.data.data.newNotification)
    );
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doReadAllNotificationsRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setMarkAllReadLoading(true));
    const response: AxiosResponse = yield call(doReadAllNotification);

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setMarkAllReadLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetTimezonesListRequest() {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doGetTimezones);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield put(actions.setTimeZonesList(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doAsReadNotificationsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      NotificationRead,
      action.payload.id
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* NotificationSettingRepoSaga() {
  yield takeLatest(actions.doUpdate, doUpdateNotificationSettingsRequest);
  yield takeLatest(
    actions.doGetNotificationSettings,
    doGetNotificationSettingsRequest
  );
  yield takeLatest(
    actions.doGetNotificationsList,
    doGetAllNotificationsListRequest
  );
  yield takeLatest(
    actions.doGetUnreadNotificationsList,
    doGetUnreadNotificationsListRequest
  );
  yield takeLatest(
    actions.doReadAllNotifications,
    doReadAllNotificationsRequest
  );

  yield takeLatest(actions.doGetTimeZonesList, doGetTimezonesListRequest);
  yield takeLatest(actions.doAsReadNotifications, doAsReadNotificationsRequest);
}
