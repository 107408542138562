import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import {
  NotificationListInterface,
  NotificationSettingInterface,
  NotificationTimeEnum,
  TimeZonesListInterface,
} from "./types";
import { set } from "lodash";

export const useClinicSlice = createSlice({
  name: "notificationSettingState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageChangeLoading: (state, action: PayloadAction<boolean>) => {
      state.pageChangeLoading = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },

    clearFrom: (state) => {
      state.form.projectActivity = false;
      state.form.mention = false;
      state.form.inAppNotification = false;
      state.form.notificationTime = NotificationTimeEnum.HOURLY;
      state.form.timeZone = "UTC-05:00 Eastern Time";
    },
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetNotificationSettings: (state) => {},
    setDataForEdit: (
      state,
      action: PayloadAction<NotificationSettingInterface>
    ) => {
      state.form = action.payload;
    },

    //notifications
    doGetNotificationsList: (state) => {},
    setNotificationsList: (
      state,
      action: PayloadAction<Array<NotificationListInterface>>
    ) => {
      state.notifications = action.payload;
    },
    doGetUnreadNotificationsList: (state) => {},
    setUnreadNotificationsList: (
      state,
      action: PayloadAction<Array<NotificationListInterface>>
    ) => {
      state.unreadNotifications = action.payload;
    },
    setNewNotificationsCount: (state, action: PayloadAction<number>) => {
      state.newNotification = action.payload;
    },
    doReadAllNotifications: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    doGetTimeZonesList: (state) => {},
    setTimeZonesList: (
      state,
      action: PayloadAction<Array<TimeZonesListInterface>>
    ) => {
      state.timeZones = action.payload;
    },

    doAsReadNotifications: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    setMarkAllReadLoading: (state, action: PayloadAction<boolean>) => {
      state.markAllRead = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
