import { CancelTokenSource } from "axios";
import {
  FileStatusEnum,
  PossibleResolutionInterface,
  UploadStatusEnum,
} from "../../../Review/types";
import { SharedWithInterface, accessType } from "../../SharedProject/types";
import { ProjectStatusEnum } from "../../types";

export interface FolderInterface {
  projectId: string;
  fileId: string;
  name: string;
  color: string;
  isPrivate: boolean;
}
export interface FileInterface {
  _id: string;
  id?: string;
  userName: string;
  filePath: string;
  projectId: string;
  parentFileId: string;
  name: string;
  color: string;
  isPrivate: boolean;
  status: FileStatusEnum;
  possibleResolution: PossibleResolutionInterface[];
  fileType: FileTypeEnum;
  videoTimeDuration: number;
  fileData: PreviewFilesDataInterface[];
  fileCount: number;
  uploadStatus: UploadStatusEnum;
  fileVersionId: string;
  fileThumbnailPath: string;
  mimeType: string;
  commentCount: number;
  profilePic: string;
  createdAt: string;
  total: number;
  completedTotal: number;
  uploadProgress: number;
  versionCount: number;
  thumbnailFileStatus?: ThumbnailFileStatusEnum;
  fileUploadStatus?: FileUploadStatusEnum;
  height?: number;
  size?: number;
  sortField?: string;
  userId?: string;
  width?: number;
}
export enum FileUploadStatusEnum {
  IN_PROGRESS = 1,
  UPLOADED = 2,
  FAILED = 3,
}
export interface FolderNameInterface {
  name: string;
  description: string;
  projectId: string;
  fileId: string;
  filePathData: FolderNameListInterface[];
  userRole: UserProjectRoleEnum;
  canAddUser: boolean;
  canDownload: boolean;
  projectOwner: boolean;
  isActive: ProjectStatusEnum;
  projectCreated: boolean;
  canDownloadPrivateFile: boolean;
  canDeleteFile: boolean;
}

export enum UserProjectRoleEnum {
  COLLABORATOR = 1,
  VIEWER = 2,
  EDITOR = 3,
}
export enum ThumbnailFileStatusEnum {
  IN_PROGRESS = 1,
  PROCESSED = 2,
  FAILED = 3,
}
export interface PreviewFilesDataInterface {
  fileType: FileTypeEnum;
  fileThumbnailPath: string;
}
export interface FolderNameListInterface {
  name: string;
  fileId: string;
}
export interface MakePrivateInterface {
  fileId: string;
  isPrivate: boolean;
}
export interface RenameFileAndFolderInterface {
  name: string;
}
export interface MoveFileAndFolderInterface {
  fileId: string;
}
export interface FileStatusInterface {
  fileId: string;
  fileVersionId: string;
  status: FileStatusEnum;
}
export interface FolderStructureInterface {
  _id: string;
  name: string;
  type: FileTypeEnum;
  data: FolderStructureInterface[];
  canMove: boolean;
  canCopy: boolean;
  filePath: string;
  contentType: FileContentTypeEnum;
}
export enum FileContentTypeEnum {
  PROJECT = 1,
  FOLDER = 2,
}
export interface CreateFileInterface {
  fileId: string;
  projectId: string;
  fileName: string;
  filePath: string;
  fileSize: string;
  isFolder: boolean;
  folderId: string;
}
export interface SharedFileInterface {
  fileId: string;
  fileVersionId: string;
  linkAccess: string;
  linkPassword: string;
  accessType: accessType;
  search: string;
  name: string;
  fileMember: SharedWithInterface[];
}

export enum FileTypeEnum {
  FOLDER = 1,
  FILE = 0,
}
export interface UploadFolderDetails {
  folderName: string;
  fileId: string;
}
export interface CurrentFolderUploadInterface {
  folderId: string;
  totalCount: number;
  currentCount: number;
}
export interface folderFileListInterface {
  _id: string;
  name: string;
  filePath: string;
}
export interface FileAndFolderState {
  pageNo: number;
  pageSize: number;
  totalRow: number;
  hasMore: boolean;
  form: FolderInterface;
  loading: boolean;
  buttonLoading: boolean;
  rename: boolean;
  delete: boolean;
  progress: boolean;
  createFolder: boolean;
  folderColor: string;
  move: boolean;
  list: FileInterface[];
  folderNameData: FolderNameInterface;
  imageUploadProgress: number;
  makePrivate: MakePrivateInterface;
  status: FileStatusInterface;
  renameFile: RenameFileAndFolderInterface;
  moveFile: MoveFileAndFolderInterface;
  folderStructure: FolderStructureInterface[];
  searchFolder: string;
  //upload file
  createFile: CreateFileInterface;
  showDelete: boolean;
  showLeaveProject: boolean;
  uploadFolderId: string;
  uploadFileId: string[];
  uploadFileCount: number;
  totalFileCount: number;
  uploadingFile: boolean;
  fileInProcess: boolean;
  sortColumn: string;
  sortDir: string;
  shareFile: boolean;
  sharedFileForm: SharedFileInterface;
  userForm: SharedWithInterface;
  currentFolderUpload: CurrentFolderUploadInterface[];
  selectedId: string;
  selectedFileVersionId: string;
  uploadQueue: any[];
  uploadDoneQueue: any[];
  inProcessQueue: string[];
  isQueueBusy: boolean;
  isCancel: boolean;
  cancelToken: CancelTokenSource | null;
  folderFileList: folderFileListInterface[];
  folderDownloadLoading: boolean;
  isDownloadingFiles: boolean;
  isFolderSearch: boolean;
}

export type InitialFileAndFolderState = FileAndFolderState;
