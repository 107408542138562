import { CommentTypeEnum, ReviewCommentListState } from ".";
import { UserProjectRoleEnum } from "../../../Dashboard/FileAndFolder/types";

export const initialState: ReviewCommentListState = {
  loading: false,
  buttonLoading: false,
  accessLink: false,
  fileCarousel: false,
  deleteComment: false,
  version: "v1",
  carouselLoading: false,
  commentForm: {
    fileId: "",
    rawComment: "",
    fileVersionId: "",
    comment: "",
    commentType: CommentTypeEnum.PUBLIC,
    startTime: "",
    endTime: "",
    attachedFigure: [],
    attachedFiles: [],
    mentionedMember: [],
  },
  commentList: [],
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  hasMore: false,
  filter: "1",
  sortDir: "desc",
  projectData: {
    name: "",
    description: "",
    projectId: "",
    fileId: "",
    filePathData: [],
    userRole: UserProjectRoleEnum.VIEWER,
    canAddUser: false,
    canDownload: false,
  },

  uploadedFiles: [],
  replyCommentForm: {
    commentId: "",
    reply: "",
    rawReply: "",
    mentionedMember: [],
  },
  editComment: null,
  editReplyComment: null,
  showReply: null,
  commentOnHoverSelectedId: null,
  currentReplyComment: null,
  currentComment: null,
  selectedOnClickComment: null,
  clickOnCommentOptions: false,
  commentUpdate: false,
  currentCommentSelectedId: null,
};
