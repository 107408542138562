import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../types";

const selectDomain = (state: RootState) => {
  if (state && state.reviewState) {
    return state.reviewState;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectButtonLoading = createSelector(
  [selectDomain],
  (state) => state.buttonLoading
);
export const selectCarouselLoading = createSelector(
  [selectDomain],
  (state) => state.carouselLoading
);
export const selectAccessLink = createSelector(
  [selectDomain],
  (state) => state.accessLink
);
export const selectFileCarousel = createSelector(
  [selectDomain],
  (state) => state.fileCarousel
);
export const selectVersion = createSelector(
  [selectDomain],
  (state) => state.version
);
export const selectCompare = createSelector(
  [selectDomain],
  (state) => state.compare
);
export const selectShowComment = createSelector(
  [selectDomain],
  (state) => state.showComment
);
export const selectReviewFileForm = createSelector(
  [selectDomain],
  (state) => state.reviewFileForm
);
export const selectFoldersFile = createSelector(
  [selectDomain],
  (state) => state.folderFiles
);
export const selectCommentForm = createSelector(
  [selectDomain],
  (state) => state.commentForm
);
export const selectProjectData = createSelector(
  [selectDomain],
  (state) => state.projectData
);
export const selectFileVersionsList = createSelector(
  [selectDomain],
  (state) => state.fileVersions
);
export const selectUploadedFiles = createSelector(
  [selectDomain],
  (state) => state.uploadedFiles
);
export const selectMembersList = createSelector(
  [selectDomain],
  (state) => state.membersList
);
export const selectSelfMembersList = createSelector(
  [selectDomain],
  (state) => state.selfMembersList
);

// ---------- Canvas Draw Start ------------ //

export const selectAddLine = createSelector(
  [selectDomain],
  (state) => state.addLine
);
export const selectDrawPencil = createSelector(
  [selectDomain],
  (state) => state.drawPencil
);
export const selectDrawArrow = createSelector(
  [selectDomain],
  (state) => state.drawArrow
);
export const selectDrawRect = createSelector(
  [selectDomain],
  (state) => state.drawRect
);
export const selectRedo = createSelector([selectDomain], (state) => state.redo);
export const selectUndo = createSelector([selectDomain], (state) => state.undo);
export const selectDrawData = createSelector(
  [selectDomain],
  (state) => state.drawData
);
export const selectDrawColor = createSelector(
  [selectDomain],
  (state) => state.drawColor
);
export const selectClearCanvas = createSelector(
  [selectDomain],
  (state) => state.clearCanvas
);

// ---------- Canvas Draw End------------ //
export const selectCreateVersionData = createSelector(
  [selectDomain],
  (state) => state.createVersionData
);
export const selectUploadingVersion = createSelector(
  [selectDomain],
  (state) => state.uploadingVersion
);
export const selectUpdatedVersionsList = createSelector(
  [selectDomain],
  (state) => state.updatedVersionsList
);
export const selectAttachedFiguresData = createSelector(
  [selectDomain],
  (state) => state.attachedFiguresData
);
export const selectSelectedQualityFile = createSelector(
  [selectDomain],
  (state) => state.selectedQualityFile
);
export const selectStartTimeValue = createSelector(
  [selectDomain],
  (state) => state.startTime
);
export const selectEndTimeValue = createSelector(
  [selectDomain],
  (state) => state.endTime
);
export const selectMarkTimeStamp = createSelector(
  [selectDomain],
  (state) => state.markTimeStamp
);
export const selectIsPressed = createSelector(
  [selectDomain],
  (state) => state.isPressed
);

export const selectCommentIndicatorList = createSelector(
  [selectDomain],
  (state) => state.commentIndicatorList
);
export const selectRenameFileVersionForm = createSelector(
  [selectDomain],
  (state) => state.renameFileVersion
);
export const selectRenameVersion = createSelector(
  [selectDomain],
  (state) => state.renameVersion
);
export const selectAccessLinkForm = createSelector(
  [selectDomain],
  (state) => state.accessLinkForm
);
export const selectUserFileShareDetails = createSelector(
  [selectDomain],
  (state) => state.userFileShareDetails
);
export const selectCurrentAuthModal = createSelector(
  [selectDomain],
  (state) => state.currentAuthModal
);
export const selectShowRegister = createSelector(
  [selectDomain],
  (state) => state.showRegister
);
export const selectFileNotForShare = createSelector(
  [selectDomain],
  (state) => state.fileNotForShare
);
export const selectPlayCurrentTime = createSelector(
  [selectDomain],
  (state) => state.playCurrentTime
);
export const selectSelectedCompereFile = createSelector(
  [selectDomain],
  (state) => state.selectedCompereFile
);
export const selectSelectedVersionV1 = createSelector(
  [selectDomain],
  (state) => state.versionV1
);
export const selectSelectedVersionV2 = createSelector(
  [selectDomain],
  (state) => state.versionV1
);
export const selectParentId = createSelector(
  [selectDomain],
  (state) => state.parentId
);
export const selectShowTimeLine = createSelector(
  [selectDomain],
  (state) => state.showTimeLine
);
export const selectFileDataLoading = createSelector(
  [selectDomain],
  (state) => state.fileDataLoading
);
export const selectVersions = createSelector(
  [selectDomain],
  (state) => state.versions
);
export const selectSelectedCompereIndex = createSelector(
  [selectDomain],
  (state) => state.selectedCompereIndex
);
export const selectReviewNavbar = createSelector(
  [selectDomain],
  (state) => state.reviewNavbar
);
export const selectReviewCompareOptionBar = createSelector(
  [selectDomain],
  (state) => state.reviewCompareOptionBar
);
export const selectReviewOptionBar = createSelector(
  [selectDomain],
  (state) => state.reviewOptionBar
);
export const selectPinSelection = createSelector(
  [selectDomain],
  (state) => state.pinSelection
);
export const selectShiftDrag = createSelector(
  [selectDomain],
  (state) => state.shiftDrag
);

export const selectShowTimeLinePressed = createSelector(
  [selectDomain],
  (state) => state.showTimeLinePress
);
export const selectOtpHash = createSelector(
  [selectDomain],
  (state) => state.otpHash
);
export const selectIsMessageClear = createSelector(
  [selectDomain],
  (state) => state.isMessageClear
);
