import {
  FolderNameListInterface,
  UserProjectRoleEnum,
} from "../../../Dashboard/FileAndFolder/types";

export interface CommentFormInterface {
  fileId: string;
  fileVersionId: string;
  comment: string;
  rawComment: string;
  commentType: CommentTypeEnum;
  startTime: string;
  endTime: string;
  attachedFigure: [];
  attachedFiles: UploadedFilesInterface[];
  mentionedMember: string[];
}
export interface ReplyCommentFormInterface {
  commentId: string;
  reply: string;
  rawReply: string;
  mentionedMember: string[];
}
export interface ProjectFormInterface {
  name: string;
  description: string;
  projectId: string;
  fileId: string;
  filePathData: FolderNameListInterface[];
  userRole: UserProjectRoleEnum;
  canAddUser: boolean;
  canDownload: boolean;
}
export interface ReplyDataInterface {
  _id: string;
  userId: string;
  commentId: string;
  name: string;
  profilePic: string;
  likesCount: number;
  like: boolean;
  markAsComplete: boolean;
  reply: string;
  rawReply: string;
  createdAt: string;
  updatedAt: string;
}
export interface CommentListInterface {
  _id: string;
  userId: string;
  name: string;
  profilePic: string;
  likesCount: number;
  like: boolean;
  markAsComplete: boolean;
  commentType: number;
  hasFigure: boolean;
  hasAttchedFile: boolean;
  attachedFile: [];
  comment: string;
  rawComment: string;
  mimeType: string;
  newComment: boolean;
  replyData: ReplyDataInterface[];
  createdAt: string;
  updatedAt: string;
  startTime: string;
  endTime: string;
  millisecondStartTime: number;
  millisecondEndTime: number;
  attachedFigure: any;
  drawPadHeight: string;
  drawPadWidth: string;
  width: number;
}
export interface MembersListInterface {
  _id: string;
  name: string;
  email: string;
  profilePic: string;
}
export interface UploadedFilesInterface {
  fileName: string;
  filePath: string;
  remove: boolean;
}
export interface ReplyInterface {
  comment: string;
}
export enum FileStatusEnum {
  NO_STATUS = 0,
  IN_REVIEW = 1,
  IN_PROGRESS = 2,
  APPROVED = 3,
}
export enum FileTypeEnum {
  FILE = 0,
  FOLDER = 1,
}
export enum CommentTypeEnum {
  PUBLIC = 1,
  PRIVATE = 2,
}
export enum CommentFilterEnum {
  ALL = 1,
  RESLOVED = 2,
}
export interface ReviewCommentListState {
  pageNo: number;
  pageSize: number;
  totalRow: number;
  hasMore: boolean;
  filter: string;
  sortDir: string;
  loading: boolean;
  buttonLoading: boolean;
  carouselLoading: boolean;
  accessLink: boolean;
  fileCarousel: boolean;
  version: string;
  commentForm: CommentFormInterface;
  replyCommentForm: ReplyCommentFormInterface;
  commentList: CommentListInterface[];
  deleteComment: boolean;
  projectData: ProjectFormInterface;
  uploadedFiles: UploadedFilesInterface[];
  editComment: number | null;
  editReplyComment: number | null;
  showReply: number | null;

  commentOnHoverSelectedId: string | null;
  selectedOnClickComment: string | null;
  currentReplyComment: string | null;
  currentComment: string | null;
  currentCommentSelectedId: string | null;
  clickOnCommentOptions: boolean;
  commentUpdate: boolean;
}

export type InitialReviewCommentListState = ReviewCommentListState;
