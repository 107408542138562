import { FolderStructureInterface } from "../../Redux/Dashboard/FileAndFolder/types";

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function updatePropertyById(
  id: string,
  data: FolderStructureInterface,
  child: FolderStructureInterface[]
) {
  if (data._id == id) {
    data.data = child;
  }
  if (data.data !== undefined && data.data.length > 0) {
    for (let i = 0; i < data.data.length; i++) {
      data.data[i] = updatePropertyById(id, data.data[i], child);
    }
  }

  return data;
}
export function updateRootTraversalPropertyById(
  id: string,
  data: FolderStructureInterface[],
  child: FolderStructureInterface[]
) {
  for (let i = 0; i < data.length; i++) {
    data[i] = updatePropertyById(id, data[i], child);
  }

  return data;
}
