import { t } from "i18next";
import CustomToast from "../../CustomToast";
import errorCode from "../ErrorCode/Index";
import ErrorRender from "./ErrorRender";

export default function CatchBlockFunction(error: any) {
  if (errorCode(error?.response?.data?.statusCode)) {
    if (error?.response?.data?.statusCode == 505) {
      CustomToast(error?.response?.data?.message, "INFO");
      return;
    } else if (error?.response?.data?.statusCode == 4003) {
      CustomToast(error?.response?.data?.message, "ERROR");
      setTimeout(() => {
        sessionStorage.clear();
        window.location.replace("/dashboard");
      }, 2000);
      return;
    } else if (error?.response?.status == 401) {
      localStorage.removeItem("token");
      localStorage.clear();
    } else if (error?.response?.data.errors) {
      ErrorRender(error);
    } else {
      CustomToast(error?.response?.data?.message, "ERROR");
      return;
    }
  } else {
    if (
      error.message !== "Cannot read properties of undefined (reading 'fileId')"
    ) {
      if (error.response.data.message !== "Content not exist error.") {
        if (error.message !== "e is undefined") {
          if (
            error.message !==
            "call: argument of type {context, fn} has undefined or null `fn`"
          ) {
            if (error.message !== "mime.getType is not a function") {
              if (
                error.message !==
                "undefined is not an object (evaluating 'e.context')"
              ) {
                if (
                  error.message !==
                  "Cannot read properties of undefined (reading 'context')"
                ) {
                  if (
                    error.message !==
                    "Cannot read properties of undefined (reading '0')"
                  ) {
                    if (
                      error.message !== "call: argument fn is undefined or null"
                    ) {
                      if (
                        error.message !==
                        "Cannot read properties of undefined (reading 'results')"
                      ) {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      CustomToast(
        `${t("ERROR_MESSAGE.FILE_SIZE_EXTENSION.DUPLICATE_IMAGE")}`,
        "ERROR"
      );
    }
  }
}
