export interface OverviewInterface {
  projects: number;
  users: number;
  storage: number;
  teams: number;
  maxProjects: number;
  maxUsers: number;
  maxStorage: number;
  maxTeams: number;
  renewDate: string;
  plan: PlanEnumInterface;
}
export interface PlanListInterface {
  _id: string;
  name: string;
  projects: number;
  users: number;
  storage: number;
  teams: number;
  monthlyAmount: number;
  yearlyAmount: number;
  versioning: boolean;
  deleteProject: boolean;
  planType: PlanEnumInterface;
  badge: string;
  featureList: [{ _id: string; name: string; japaneseName: string }];
  fileMaxRatio: number;
  mostPopular: boolean;
  description: string;
  japaneseDescription: string;
  japaneseName: string;
}
export interface PlanOrderInterface {
  _id: string;
  publicKey: string;
}
export interface PlanSubscriptionInterface {
  orderId: string;
  accountHolderName: string;
  address: TakeAddressInterface;
}
export interface TakeAddressInterface {
  city: string;
  country: string;
  line1: string;
  line2: string;
  postal_code: string;
  state: string;
}

export enum SubscriptionTypeEnum {
  MONTHLY = 1,
  YEARLY = 2,
}

export enum PlanEnumInterface {
  FREE = 1,
  BASIC = 2,
  PROFESSIONAL = 3,
}

export interface OverviewState {
  form: OverviewInterface;
  loading: boolean;
  plan: string;
  billed: SubscriptionTypeEnum;
  planList: PlanListInterface[];
  buttonLoading: boolean;
  planOrderData: PlanOrderInterface;
  planSubscription: PlanSubscriptionInterface;
  stripProcess: boolean;
}

export type InitialOverviewState = OverviewState;
