import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import {
  ProjectDetailsInterface,
  ProjectMemberInterface,
  ProjectNotificationDetailsInterface,
  SharedFormInterface,
  SharedWithInterface,
  accessType,
} from "./types";
import { set } from "lodash";

export const useClinicSlice = createSlice({
  name: "sharedProjectState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    toggleShareProject: (state, action: PayloadAction<boolean>) => {
      state.shareProject = action.payload;
    },
    toggleAddUser: (state, action: PayloadAction<boolean>) => {
      state.addUser = action.payload;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    clearFrom: (state) => {
      // state.sharedForm.projectId = "";
      state.form.linkAccess = "";
      state.form.search = "";
      state.form.projectName = "";
      state.form.linkPassword = "";
      state.form.accessType = accessType.ONLY_INVITED;
      state.form.projectMember = [];
    },
    updateUserFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `userForm.${action.payload.key}`, action.payload.value);
    },
    setUserDataForEdit: (state, action: PayloadAction<SharedWithInterface>) => {
      
      state.userForm.name = action.payload.name;
      state.userForm.email = action.payload.email;
      state.userForm._id = action.payload._id;
      state.userForm.remove = action.payload.remove;
      state.userForm.isNew = action.payload.isNew;
    },
    setDataForEdit: (state, action: PayloadAction<SharedFormInterface>) => {
      state.form = action.payload;
    },
    doShareProject: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    doGetSharedProjectEdit: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    doAddUser: (
      state,
      action: PayloadAction<{ email: string; isRemove: boolean | undefined }>
    ) => {
      let index = "";
      for (const ele of state.form.projectMember) {
        if (ele.email == action.payload.email) {
          index = state.form.projectMember.indexOf(ele).toString();
        }
      }
      if (index?.length > 0) {
        state.form.projectMember[Number(index)].remove = false;
      } else {
        state.form.projectMember.push({
          _id: state.userForm._id,
          email: state.userForm.email,
          name: state.userForm.name,
          remove: state.userForm.remove,
          isNew: state.userForm.isNew,
        });
      }
    },
    doRemoveUser: (
      state,
      action: PayloadAction<{ index: number; id: string }>
    ) => {
      state.form.projectMember[action.payload.index].remove = true;
    },
    clearAddUserFrom: (state) => {
      state.form.search = "";
    },
    doGetProjectMembersList: (state, action: PayloadAction<string>) => {},
    setProjectMembersList: (
      state,
      action: PayloadAction<Array<ProjectMemberInterface>>
    ) => {
      state.projectMemberList = action.payload;
    },
    updateProjectInvitationFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `projectInvitation.${action.payload.key}`,
        action.payload.value
      );
    },
    doVerifyProjectInvitation: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    doGetProjectInvitationDetails: (state, action: PayloadAction<string>) => {},
    setProjectDetails: (
      state,
      action: PayloadAction<ProjectDetailsInterface>
    ) => {
      state.projectInvitationDetails = action.payload;
    },
    clearProjectInvitationForm: (state) => {
      state.projectInvitation.password = "";
      state.projectInvitation.token = "";
    },
    // add user
    updateAddProjectMemberFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `projectMember.${action.payload.key}`, action.payload.value);
    },
    clearProjectMemberFrom: (state) => {
      state.projectMember.search = "";
      state.projectMember.projectId = "";
      state.projectMember.projectMember = [];
    },
    clearUserFormFrom: (state) => {
      state.userForm._id = "";
      state.userForm.email = "";
      state.userForm.name = "";
      state.userForm.remove = false;
    },
    setProjectMemberDataForEdit: (
      state,
      action: PayloadAction<ProjectMemberInterface>
    ) => {
      state.projectMember = action.payload;
    },
    doAddProjectMember: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    doGetProjectMemberEdit: (state, action: PayloadAction<string>) => {},
    doAddProjectUser: (state) => {
      state.projectMember.projectMember.push({
        _id: state.userForm._id,
        email: state.userForm.email,
        name: state.userForm.name,
        remove: state.userForm.remove,
        isNew: state.userForm.isNew,
      });
    },
    doRemoveProjectUser: (
      state,
      action: PayloadAction<{ index: number; id: string }>
    ) => {
      state.projectMember.projectMember[action.payload.index].remove = true;
    },
    clearAddProjectMemberFrom: (state) => {
      state.projectMember.search = "";
    },
    doRemoveProjectMember: (
      state,
      action: PayloadAction<{ index: number; id: string }>
    ) => {
      state.projectMember.projectMember[action.payload.index].remove = true;
    },
    doVerifyProjectNotificationInvitation: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    setProjectNotificationInvitationDetails: (
      state,
      action: PayloadAction<ProjectNotificationDetailsInterface>
    ) => {
      state.projectNotificationInvitationDetails = action.payload;
    },
    doGetProjectNotificationInvitation: (
      state,
      action: PayloadAction<string>
    ) => {},
    doGetProjectViaLinkInvitation: (state, action: PayloadAction<string>) => {},
    doVerifyProjectViaLinkInvitation: (
      state,
      action: PayloadAction<{ code: string; callback: () => void }>
    ) => {},
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
