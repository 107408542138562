export interface SharedFormInterface {
  projectId: string;
  linkAccess: string;
  linkPassword: string;
  accessType: accessType;
  search: string;
  projectName: string;
  projectMember: SharedWithInterface[];
  email: string;
  remove: boolean;
}

export enum accessType {
  ANYONE_WITH_LINK = 1,
  ONLY_INVITED = 2,
  PASSWORD_PROTECTED = 3,
}

export interface SharedWithInterface {
  _id: string;
  name: string;
  email: string;
  remove: boolean;
  isNew: boolean;
  profilePic?: string;
  isInvited?: boolean;
  invitationExpired?: boolean;
  userStatus?: number;
}
export interface ProjectInvitationInterface {
  token: string;
  password?: string;
}
export interface ProjectNotificationInvitationInterface {
  token: string;
  notificationId: string;
  password?: string;
}
export interface ProjectDetailsInterface {
  projectId: string;
  fileId: string;
  projectRootFolderId: string;
  projectName: string;
  projectOwner: string;
  projectPhoto: string;
  hasPassword: boolean;
  alreadyShared: boolean;
  allowedProject: number;
  totalProject: number;
  memberRole: ProjectMemberTypeEnum;
}

export enum ProjectMemberTypeEnum {
  COLLABORATOR = 1,
  VIEWER = 2,
  EDITOR = 3,
}
export interface ProjectNotificationDetailsInterface {
  projectId: string;
  fileId: string;
  notificationId: string;
  projectName: string;
  projectOwner: string;
  projectPhoto: string;
  hasPassword: boolean;
}
export interface ProjectMemberInterface {
  projectId: string;
  search: string;
  projectMember: SharedWithInterface[];
  name: string;
  profilePic: string;
  userId: string;
  _id: string;
}
export interface SharedProjectState {
  loading: boolean;
  shareProject: boolean;
  projectInvitation: ProjectInvitationInterface;
  projectInvitationDetails: ProjectDetailsInterface;
  projectMemberList: ProjectMemberInterface[];
  form: SharedFormInterface;
  sharedWithList: SharedWithInterface[];
  userForm: SharedWithInterface;
  // add user
  addUser: boolean;
  projectMember: ProjectMemberInterface;
  projectNotificationInvitationDetails: ProjectNotificationDetailsInterface;
}

export type InitialSharedProjectState = SharedProjectState;
