import { Close } from "@mui/icons-material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Injector from "./Components/hooks/Injector";
import MainRoutes from "./routes/MainRoutes";

function App() {
  const CloseButton = ({ closeToast }: any) => (
    <Close
      onClick={closeToast}
      className="toast-close"
      sx={{
        width: 20,
        height: 20,
        margin: "auto 0",
        alignSelf: "baseline",
      }}
    />
  );
  return (
    <>
      <Injector>
        <ToastContainer closeButton={CloseButton} />
        <MainRoutes />
      </Injector>
    </>
  );
}

export default App;
