import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CatchBlockFunction from "../../Components/hooks/CatchError";
import CustomToast from "../../Components/CustomToast";
import { t } from "i18next";
import {
  doGetReviewFile,
  doGetFolderFiles,
  doSendComments,
  doGetFileVersions,
  commentFileSignedUrl,
  uploadCommentFiles,
  fileVersionSignedUrl,
  uploadFilesVersion,
  createFileVersions,
  doUpdateVersionsOrder,
  doGetAttachedFigures,
  doGetIndicatorList,
  doRenameFileVersion,
  GetUserFileShareDetail,
  doVerifyAccessLinkPassword,
  GetSelfMentionMemberList,
  GetFileViaLink,
} from "../../utils/request";
import {
  AccessLinkInterface,
  CommentFormInterface,
  CreateVersionInterface,
} from "./types";
import {
  selectAccessLinkForm,
  selectCommentForm,
  selectCreateVersionData,
  selectRenameFileVersionForm,
  selectUpdatedVersionsList,
} from "./selector";
import { actions as dashboardAction } from "../Dashboard/slice";
import { RenameFileAndFolderInterface } from "../Dashboard/FileAndFolder/types";

export function* doGetReviewFileRequest(action: { payload: string }) {
  yield delay(500);
  if (action.payload == undefined || action.payload == null) {
    return;
  }
  try {
    yield put(actions.setFileDataLoading(true));
    const response: AxiosResponse = yield call(doGetReviewFile, action.payload);
    yield put(actions.setFileDataLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setReviewFileData(response.data.data));
    yield put(actions.setProjectData(response.data.data.projectData));
  } catch (error: any) {
    if (
      error?.response?.data?.statusCode === 4005 ||
      error?.response?.data?.statusCode === 4006 ||
      error?.response?.data?.statusCode === 7002
    ) {
      yield put(dashboardAction.setIsContentExist(true));
      return;
    }

    if (error?.response?.data?.statusCode === 7003) {
      yield put(dashboardAction.setIsDeactivated(true));
      return;
    }
    yield put(actions.setFileDataLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetFoldersFilesRequest(action: { payload: string }) {
  yield delay(500);

  try {
    yield put(actions.setCarouselLoading(true));
    const response: AxiosResponse = yield call(
      doGetFolderFiles,
      action.payload
    );
    yield put(actions.setCarouselLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setFoldersFile(response.data.data));
  } catch (error: any) {
    yield put(actions.setCarouselLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doAddCommentRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: CommentFormInterface = yield select(selectCommentForm);

  if (form.comment.length == 0 && form.attachedFigure.length == 0) {
    CustomToast(`${t("ERROR_MESSAGE.REVIEW_MESSAGES.DO_EDIT")}`, "ERROR");
    return;
  }

  yield put(actions.setCarouselLoading(true));
  try {
    const response: AxiosResponse = yield call(doSendComments, form);
    yield put(actions.setCarouselLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setCarouselLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doGetMembersListRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      GetSelfMentionMemberList,
      action.payload
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setMembersList(response.data.data));
  } catch (error: any) {
    if (
      error?.response?.data?.statusCode === 4005 ||
      error?.response?.data?.statusCode === 7002
    ) {
      yield put(dashboardAction.setIsContentExist(true));
      return;
    }
    CatchBlockFunction(error);
  }
}
export function* doGetSelfMembersListRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      GetSelfMentionMemberList,
      action.payload
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setSelfMembersList(response.data.data));
  } catch (error: any) {
    if (
      error?.response?.data?.statusCode === 4005 ||
      error?.response?.data?.statusCode === 7002
    ) {
      yield put(dashboardAction.setIsContentExist(true));
      return;
    }
    CatchBlockFunction(error);
  }
}
export function* doGetFileVersionsListRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  if (action.payload.id == undefined || action.payload.id == null) {
    return;
  }
  try {
    const response: AxiosResponse = yield call(
      doGetFileVersions,
      action.payload.id
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setFileVersionsList(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    if (
      error?.response?.data?.statusCode === 4005 ||
      error?.response?.data?.statusCode === 4006 ||
      error?.response?.data?.statusCode === 7002
    ) {
      yield put(dashboardAction.setIsContentExist(true));
      return;
    }
    CatchBlockFunction(error);
  }
}
//file upload
export function* FileUploadRequest(action: {
  payload: {
    fileName: string;
    fileVersionId: string;
    callback: any;
  };
}) {
  try {
    const response: AxiosResponse = yield call(
      commentFileSignedUrl,
      action.payload
    );
    if (!response.status) {
      CustomToast(response.data.message, "ERROR");
    }
    yield call(
      action?.payload?.callback(
        response.data.data.signedUrl,
        response.data.data?.fileNameWithPrefix
      )
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UploadImageRequest(action: {
  payload: { data: any; signedUrl: string; result: any; callback: any };
}) {
  try {
    const response: AxiosResponse = yield call(
      uploadCommentFiles,
      action.payload.signedUrl,
      action.payload.data,
      action.payload.result
    );
    if (!response.status) {
      CustomToast(response.data.message, "ERROR");
    }
    // CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
//version upload
export function* FileVersionUploadRequest(action: {
  payload: {
    fileName: string;
    projectId: string;
    fileId: string;
    callback: any;
  };
}) {
  try {
    const response: AxiosResponse = yield call(
      fileVersionSignedUrl,
      action.payload
    );
    if (!response.status) {
      CustomToast(response.data.message, "ERROR");
    }
    yield call(action?.payload?.callback(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UploadFileVersionRequest(action: {
  payload: { data: any; signedUrl: string; result: any; callback: any };
}) {
  try {
    const response: AxiosResponse = yield call(
      uploadFilesVersion,
      action.payload.signedUrl,
      action.payload.data,
      action.payload.result
    );
    if (!response.status) {
      CustomToast(response.data.message, "ERROR");
    }
    // CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* createVersionRequest(action: { payload: { callback: any } }) {
  const form: CreateVersionInterface = yield select(selectCreateVersionData);

  try {
    const response: AxiosResponse = yield call(createFileVersions, form);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateVersionsOrderRequest(action: {
  payload: { id: string; callback: any };
}) {
  const list: [] = yield select(selectUpdatedVersionsList);
  let data = {
    fileVersion: list,
  };

  try {
    const response: AxiosResponse = yield call(
      doUpdateVersionsOrder,
      action.payload.id,
      data
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
    }
    // CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetAttachedFiguresRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      doGetAttachedFigures,
      action.payload.id
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
    }

    yield put(actions.setAttachedFigureData(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetIndicatorListRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      doGetIndicatorList,
      action.payload
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield put(actions.setCommentIndicatorList(response.data.data));
  } catch (error: any) {
    if (
      error?.response?.data?.statusCode === 4005 ||
      error?.response?.data?.statusCode === 4006 ||
      error?.response?.data?.statusCode === 7002
    ) {
      yield put(dashboardAction.setIsContentExist(true));
      return;
    }
    CatchBlockFunction(error);
  }
}
//Rename file version
export function* doRenameFileVersionRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  const form: RenameFileAndFolderInterface = yield select(
    selectRenameFileVersionForm
  );
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      doRenameFileVersion,
      action.payload.id,
      form
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetUserFileShareDetailRequest(action: {
  payload: { id: string; hash: string; callback: any };
}) {
  yield delay(500);

  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      GetUserFileShareDetail,
      `/${action.payload.id}?hash=${action.payload.hash}`
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setUserFileShareDetail(response.data?.data));
    yield call(action?.payload?.callback(response.data?.data));
  } catch (error: any) {
    if (error?.response?.data?.statusCode === 4007) {
      yield put(actions.setFileNotForShare(true));
      return;
    }
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doVerifyAccessLinkPasswordRequest(action: {
  payload: { id: string; hash: string; callback: any };
}) {
  yield delay(500);
  const form: AccessLinkInterface = yield select(selectAccessLinkForm);
  let data = {
    linkPassword: btoa(form?.linkPassword),
    hash: action.payload.hash,
  };

  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      doVerifyAccessLinkPassword,
      action.payload.id,
      data
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
//any one with this link
export function* doGetFileViaLinkRequest(action: {
  payload: { code: string; callback: any };
}) {
  yield delay(500);

  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      GetFileViaLink,
      action.payload.code
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield call(action?.payload?.callback(response.data?.data));
  } catch (error: any) {
    if (error?.response?.data?.statusCode === 4007) {
      yield put(actions.setFileNotForShare(true));
      return;
    }
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* reviewRepoSaga() {
  yield takeLatest(actions.doGetReviewFile, doGetReviewFileRequest);
  yield takeLatest(actions.doGetFoldersFile, doGetFoldersFilesRequest);
  yield takeLatest(actions.doSendComment, doAddCommentRequest);
  yield takeLatest(actions.doGetMembersList, doGetMembersListRequest);
  yield takeLatest(actions.doGetFileVersionsList, doGetFileVersionsListRequest);
  //file upload
  yield takeLatest(actions.addFile, FileUploadRequest);
  yield takeLatest(actions.uploadFile, UploadImageRequest);
  //version upload
  yield takeLatest(actions.addVersion, FileVersionUploadRequest);
  yield takeLatest(actions.uploadVersion, UploadFileVersionRequest);
  yield takeLatest(actions.doCreateVersion, createVersionRequest);
  yield takeLatest(actions.doUpdateVersionOrder, doUpdateVersionsOrderRequest);
  yield takeLatest(actions.doRenameFileVersion, doRenameFileVersionRequest);
  yield takeLatest(
    actions.doGetUserFileShareDetail,
    doGetUserFileShareDetailRequest
  );
  yield takeLatest(
    actions.doVerifyAccessLinkPassword,
    doVerifyAccessLinkPasswordRequest
  );
  yield takeLatest(actions.doGetFileViaLink, doGetFileViaLinkRequest);
  yield takeLatest(actions.doGetIndicatorList, doGetIndicatorListRequest);
}
