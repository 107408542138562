import axios from "axios";
let url = "";
let statucurl = "";
let app_language = "";
let app_logger = "";
let mainUrl = "";
let sub_url = "";
url = import.meta.env.VITE_APP_API || "";
statucurl = import.meta.env.VITE_APP_ASSETS_URL || "";
app_language = import.meta.env.VITE_APP_LANGUAGE || "";
app_logger = import.meta.env.VITE_APP_LOGGER || "true";
mainUrl = import.meta.env.VITE_APP_MAIN_URL || "";
sub_url = import.meta.env.VITE_APP_SUB_URL || "";

export const path = () => {
  return statucurl;
};
export default axios.create({
  baseURL: url,
  headers: {},
});
export const apiPath = () => {
  return url;
};
export const BASE_URL = () => {
  return mainUrl;
};
export const SUB_URL = () => {
  return sub_url;
};

export const APP_LANGUAGE = app_language;
export const APP_LOGGER = Boolean(app_logger);
