import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { ProfileInterface } from "./types";
import { set } from "lodash";
import { PlanEnumInterface } from "../../Subscription/Overview/types";

export const useClinicSlice = createSlice({
  name: "profileState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUpdateLoading: (state, action: PayloadAction<boolean>) => {
      state.updateLoading = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    toggleConfirmProfile: (state, action: PayloadAction<boolean>) => {
      state.confirmProfile = action.payload;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },

    clearFrom: (state) => {
      state.form._id = "";
      state.form.name = "";
      state.form.email = "";
      state.form.language = "";
      state.form.profilePic = "";
      state.form.passwordUpdateCount = 0;
      state.form.googleId = "";
      state.form.emailVerified = false;
      state.form.googleAuthentication = "";
      state.form.loginCount = 0;
      state.form.canDeleteProject = false;
      state.form.planExpiryDate = "";
      state.form.planId = "";
      state.form.planType = PlanEnumInterface.FREE;
      state.form.planTitle = "";
      state.form.canCancelled = false;
    },
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetUserProfileDetails: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},

    setDataForEdit: (state, action: PayloadAction<ProfileInterface>) => {
      state.form = action.payload;
    },
    // file upload
    addImage: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>
    ) => {},
    UploadImage: (
      state,
      action: PayloadAction<{
        data: any;
        signedUrl: string;
        result: any;
        callback: () => void;
      }>
    ) => {},
    doDeleteAccount: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
