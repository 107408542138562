import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { actions as loginAction } from "../slice";
import { AxiosResponse } from "axios";
import {
  sendForgetPasswordCodeRequest,
  updatePasswordRequest,
  verifyForgotPasswordCodeRequest,
} from "../../../utils/request";
import { selectForgotPasswordForm, selectResetPasswordForm } from "./selector";
import { ForgetPasswordInterface, ResetPasswordInterface } from "./types";
import CatchBlockFunction from "../../../Components/hooks/CatchError";
import CustomToast from "../../../Components/CustomToast";
import { selectEmailVerificationCodeForm } from "../selector";
import { EmailVerificationInterface } from "../types";
import { t } from "i18next";
export function* doGetForgePasswordRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: ForgetPasswordInterface = yield select(selectForgotPasswordForm);
  if (form.email.length == 0) {
    CustomToast(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_EMAIL")}`, "ERROR");
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      sendForgetPasswordCodeRequest,
      form
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(loginAction.setVerificationCode(response.data.data));
    yield call(action?.payload?.callback(response.data.data.hash));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doVerifyForgotPasswordCodeRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: EmailVerificationInterface = yield select(
    selectEmailVerificationCodeForm
  );
  if (form.code.length == 0) {
    CustomToast(`${t("ERROR_MESSAGE.AUTH_MESSAGES.ENTER_OTP")}`, "ERROR");
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      verifyForgotPasswordCodeRequest,
      form
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.setResetPasswordCode(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdatePasswordRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: ResetPasswordInterface = yield select(selectResetPasswordForm);
  if (form.password.length === 0) {
    CustomToast(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_PASSWORD")}`, "ERROR");
    return;
  }
  if (form.confirmPassword.length === 0) {
    CustomToast(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_NEW_PASSWORD")}`, "ERROR");
    return;
  }
  let data = {
    password: btoa(form.password),
    confirmPassword: btoa(form.confirmPassword),
    hash: form.hash,
  };
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(updatePasswordRequest, data);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* forgotPasswordRepoSaga() {
  yield takeLatest(actions.doSendForgotPasswordCode, doGetForgePasswordRequest);
  yield takeLatest(
    actions.doVerifyForgotPasswordCode,
    doVerifyForgotPasswordCodeRequest
  );
  yield takeLatest(actions.doUpdatePassword, doUpdatePasswordRequest);
}
