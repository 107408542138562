import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import {
  CreateFileInterface,
  CurrentFolderUploadInterface,
  FileInterface,
  FileUploadStatusEnum,
  FolderInterface,
  FolderNameInterface,
  FolderStructureInterface,
  SharedFileInterface,
  UserProjectRoleEnum,
  folderFileListInterface,
} from "./types";
import { set } from "lodash";
import { FileStatusEnum, UploadStatusEnum } from "../../Review/types";
import { SharedWithInterface, accessType } from "../SharedProject/types";
import { updateRootTraversalPropertyById } from "../../../utils/helper";
import { CancelTokenSource } from "axios";
import { log } from "../../../utils/logger";

export const useClinicSlice = createSlice({
  name: "fileAndFolderState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageSizeOnWindowChange: (state, action: PayloadAction<number>) => {
      if (state.pageSize !== action.payload) {
        state.pageSize = action.payload;
        state.pageNo = 1;
      }
    },
    setFolderFileList: (
      state,
      action: PayloadAction<Array<folderFileListInterface>>
    ) => {
      state.folderFileList = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setHasMore: (state, action: PayloadAction<boolean>) => {
      state.hasMore = action.payload;
    },
    toggleRename: (state, action: PayloadAction<boolean>) => {
      state.rename = action.payload;
    },
    toggleDelete: (state, action: PayloadAction<boolean>) => {
      state.delete = action.payload;
    },
    toggleCreateFolder: (state, action: PayloadAction<boolean>) => {
      state.createFolder = action.payload;
    },
    toggleMove: (state, action: PayloadAction<boolean>) => {
      state.move = action.payload;
    },
    toggleProgress: (state, action: PayloadAction<boolean>) => {
      state.progress = action.payload;
    },
    toggleShareFile: (state, action: PayloadAction<boolean>) => {
      state.shareFile = action.payload;
    },
    setDownloadingFiles: (state, action: PayloadAction<boolean>) => {
      state.isDownloadingFiles = action.payload;
    },
    setFolderColor: (state, action: PayloadAction<string>) => {
      state.folderColor = action.payload;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    clearFrom: (state) => {
      state.form.name = "";
      state.form.color = "";
      state.form.isPrivate = false;
    },
    doCreateFolder: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    doGetFileAndFolderList: (
      state,
      action: PayloadAction<{ id: string; callback: any }>
    ) => {},
    doGetFolderFileList: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    doGetFileData: (
      state,
      action: PayloadAction<{ id: string; folderId: string; callback: any }>
    ) => {},
    setList: (state, action: PayloadAction<Array<FileInterface>>) => {
      try {
        if (state.uploadQueue.length > 0) {
          const updateList = action.payload;
          const uploadQueue = state.uploadQueue;
          const inProcessQueue = state.inProcessQueue;
          for (let index = 0; index < updateList.length; index++) {
            const f = updateList[index];
            const uploadFileIndex = uploadQueue.findIndex((y) => y.id == f._id);
            const uploadFolderIndex = uploadQueue.findIndex(
              (y) => y.folder_id == f._id
            );
            if (uploadFileIndex !== -1) {
              if (!updateList[index].uploadStatus) {
                updateList[index].uploadStatus = UploadStatusEnum.IN_QUEUE;
                updateList[index].status = FileStatusEnum.NO_STATUS;
                updateList[index]._id = uploadQueue[uploadFileIndex].id;
                updateList[index].name =
                  uploadQueue[uploadFileIndex].element.name;
                updateList[index].uploadProgress = 0;
              }
            } else if (uploadFolderIndex !== -1) {
              if (!updateList[index].uploadStatus) {
                updateList[index].uploadStatus = UploadStatusEnum.IN_QUEUE;
                updateList[index].fileData = [];
                updateList[index].fileCount =
                  uploadQueue[uploadFolderIndex].responseData.len;
                updateList[index].total =
                  uploadQueue[uploadFolderIndex].responseData.len;
                updateList[index].completedTotal =
                  uploadQueue[uploadFolderIndex].responseData.len -
                  uploadQueue.filter((x) => x.folder_id == f._id).length;
              }
            }
            const inProcessIndex = inProcessQueue.findIndex((y) => y == f._id);
            if (inProcessIndex !== -1) {
              updateList[index].uploadStatus = UploadStatusEnum.PROCESSING;
            }
          }
          log("folderList", updateList);
          state.list = updateList;
        } else {
          const updateList = action.payload;
          for (let index = 0; index < updateList.length; index++) {
            if (updateList[index].fileUploadStatus == 1) {
              updateList[index].uploadStatus = UploadStatusEnum.PROCESSING;
            }
          }
          state.list = updateList;
        }
      } catch (error) {
        state.list = action.payload;
      }
    },
    addFileItemList: (state, action: PayloadAction<FileInterface>) => {
      state.list.unshift(action.payload);
    },
    uploadFolderStatus: (state, action: PayloadAction<{ id: string }>) => {
      const items = state.list;
      const index = items.findIndex((x) => x._id == action.payload.id);
      if (index !== -1) {
        if (state.list[index].completedTotal + 1 == state.list[index].total) {
          state.list[index].uploadStatus = UploadStatusEnum.COMPLETED;
        }
        state.list[index].completedTotal = state.list[index].completedTotal + 1;
      }
    },
    uploadFolderRemoveFailedCount: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      const items = state.list;
      const index = items.findIndex((x) => x._id == action.payload.id);
      if (index !== -1) {
        state.list[index].total = state.list[index].total - 1;
        state.list[index].completedTotal = state.list[index].completedTotal - 1;
        state.list[index].fileCount = state.list[index].fileCount - 1;
      }
    },
    uploadFileProgressStatus: (
      state,
      action: PayloadAction<{ id: string; progress: number }>
    ) => {
      const items = state.list;
      const index = items.findIndex((x) => x._id == action.payload.id);
      if (index !== -1) {
        state.list[index].uploadProgress = action.payload.progress;
        state.list[index].uploadStatus = UploadStatusEnum.UPLOADING;
      }
    },
    uploadFileStatus: (
      state,
      action: PayloadAction<{ id: string; status: UploadStatusEnum }>
    ) => {
      const items = state.list;
      const index = items.findIndex((x) => x._id == action.payload.id);
      if (index !== -1) {
        state.list[index].uploadStatus = action.payload.status;
      }
    },
    updateFileStatusAfterComplete: (
      state,
      action: PayloadAction<{
        id: string;
        folderId: string;
        data: FileInterface;
      }>
    ) => {
      const items = state.list;
      const index = items.findIndex((x) => x._id == action.payload.id);
      let itemData = action.payload.data;
      if (index !== -1) {
        if (itemData.fileUploadStatus == FileUploadStatusEnum.UPLOADED) {
          state.list[index] = itemData;
        }
      } else {
        const indexFolder = items.findIndex(
          (x) => x._id == action.payload.folderId
        );
        if (action.payload.folderId.length > 0) {
          const uploadQueue = state.uploadQueue;
          const uploadFolderIndex = uploadQueue.findIndex(
            (y) => y.folder_id == action.payload.folderId
          );
          if (uploadFolderIndex !== -1) {
            itemData.uploadStatus = UploadStatusEnum.IN_QUEUE;
            itemData.fileUploadStatus = FileUploadStatusEnum.IN_PROGRESS;
            itemData.total = uploadQueue[uploadFolderIndex].responseData.len;
            itemData.completedTotal =
              uploadQueue[uploadFolderIndex].responseData.len -
              uploadQueue.filter((x) => x.folder_id == action.payload.folderId)
                .length;
          }
        }
        state.list[indexFolder] = itemData;
      }
      state.uploadDoneQueue = state.uploadDoneQueue.filter(
        (x) => x.id !== action.payload.id
      );
    },
    setisPrivateList: (state, action: PayloadAction<{ index: number }>) => {
      state.list[action.payload.index].isPrivate =
        !state.list[action.payload.index].isPrivate;
    },
    setFileReviewStatusByIndex: (
      state,
      action: PayloadAction<{ index: number; value: FileStatusEnum }>
    ) => {
      state.list[action.payload.index].status = action.payload.value;
    },

    setDataForEdit: (state, action: PayloadAction<FolderInterface>) => {
      state.form = action.payload;
    },

    doGetProjectDetails: (
      state,
      action: PayloadAction<{ id: string; callback: any }>
    ) => {},
    setFolderNameData: (state, action: PayloadAction<FolderNameInterface>) => {
      state.folderNameData = action.payload;
    },
    clearFolderNameData: (state) => {
      state.folderNameData.name = "";
      state.folderNameData.description = "";
      state.folderNameData.projectId = "";
      state.folderNameData.fileId = "";
      state.folderNameData.filePathData = [];
      state.folderNameData.userRole = UserProjectRoleEnum.COLLABORATOR;
      state.folderNameData.canAddUser = false;
      state.folderNameData.canDownload = false;
    },
    // file upload
    addImage: (
      state,
      action: PayloadAction<{
        fileName: any;
        projectId: string;
        fileId: string;
        fileSize: string;
        callback: (signedUrl: any, fileNameWithPrefix: any) => void;
      }>
    ) => {},
    addFolder: (
      state,
      action: PayloadAction<{
        fileName: string[];
        projectId: string;
        fileId: string;
        fileSize: string;
        callback: (responseData: any, fileNameWithPrefix: any) => void;
      }>
    ) => {},
    UploadImage: (
      state,
      action: PayloadAction<{
        fileId: string;
        data: any;
        signedUrl: string;
        result: any;
        callback: any;
      }>
    ) => {},
    // make private
    doMakePrivate: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    updateMakePrivateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `makePrivate.${action.payload.key}`, action.payload.value);
    },
    // move and copy folder structure
    doGetFolderStructure: (state, action: PayloadAction<string>) => {},

    doGetNestedFolderStructure: (
      state,
      action: PayloadAction<{ id: string; index?: number }>
    ) => {},
    doGetFolderSearch: (
      state,
      action: PayloadAction<{ id: string; search: string }>
    ) => {},
    doGetProjectSearch: (
      state,
      action: PayloadAction<{ id: string; search: string }>
    ) => {},
    doGetNestedFolderListStructure: (
      state,
      action: PayloadAction<string>
    ) => {},
    setFolderStructureList: (
      state,
      action: PayloadAction<Array<FolderStructureInterface>>
    ) => {
      state.folderStructure = action.payload;
    },
    setSearchFolderStructureList: (
      state,
      action: PayloadAction<Array<FolderStructureInterface>>
    ) => {
      state.folderStructure = [...state.folderStructure, ...action.payload];
    },
    setClearSearchFolderStructureList: (state) => {
      state.folderStructure = [];
    },

    setFolderNestedStructureList: (
      state,
      action: PayloadAction<{ items: FolderStructureInterface[]; id: string }>
    ) => {
      const folders = state.folderStructure;
      let item = updateRootTraversalPropertyById(
        action.payload.id,
        folders,
        action.payload.items
      );
      state.folderStructure = item;
    },
    setNestedFolderList: (
      state,
      action: PayloadAction<{
        id: string;
        data: FolderStructureInterface[];
      }>
    ) => {
      // state.folderStructure[action.payload.index].data = action.payload.data;
    },
    setSearchFolder: (state, action: PayloadAction<string>) => {
      state.searchFolder = action.payload;
    },
    // change file status
    doSetFileStatus: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    updateFileStatusFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `status.${action.payload.key}`, action.payload.value);
    },
    clearFileStatusFrom: (state) => {
      state.status.fileId = "";
      state.status.fileVersionId = "";
      state.status.status = FileStatusEnum.NO_STATUS;
    },
    //duplicate file and folder
    doDuplicateFile: (
      state,
      action: PayloadAction<{ id: string; callback: any }>
    ) => {},
    //rename file and folder
    doRenameFileAndFolder: (
      state,
      action: PayloadAction<{ id: string; callback: any }>
    ) => {},
    updateRenameFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `renameFile.${action.payload.key}`, action.payload.value);
    },
    clearRenameFrom: (state) => {
      state.renameFile.name = "";
    },
    //move and copy file and folder
    doMoveFileAndFolder: (
      state,
      action: PayloadAction<{ id: string; callback: any }>
    ) => {},
    doCopyFileAndFolder: (
      state,
      action: PayloadAction<{ id: string; callback: any }>
    ) => {},
    updateMoveFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `moveFile.${action.payload.key}`, action.payload.value);
    },
    doDeleteFileAndFolder: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    doCreateFile: (
      state,
      action: PayloadAction<{
        data: CreateFileInterface;
      }>
    ) => {},
    setCreateFileData: (state, action: PayloadAction<CreateFileInterface>) => {
      state.createFile = action.payload;
    },

    toggleDeleteModal: (state) => {
      state.showDelete = !state.showDelete;
    },
    toggleLeaveProject: (state) => {
      state.showLeaveProject = !state.showLeaveProject;
    },
    setUploadFolderId: (state, action: PayloadAction<string>) => {
      state.uploadFolderId = action.payload;
    },
    setUploadFileId: (state, action: PayloadAction<any>) => {
      state.uploadFileId.push(action.payload);
      log(state.uploadFileId, "text array");
    },
    setTotalFileCount: (state, action: PayloadAction<number>) => {
      state.totalFileCount = action.payload;
    },
    toggleUploadingFiles: (state, action: PayloadAction<boolean>) => {
      state.uploadingFile = action.payload;
    },
    toggleFileInProcess: (state, action: PayloadAction<boolean>) => {
      state.fileInProcess = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    clearUploadFileId: (state) => {
      state.uploadFileId = [];
    },
    removeFileId: (state, action: PayloadAction<string>) => {
      const fileIdIndex = state.uploadFileId.indexOf(action.payload);
      state.uploadFileId.splice(fileIdIndex, 1);
    },

    // share file
    doShareFile: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetSharedFileEdit: (state, action: PayloadAction<string>) => {},
    updateSharedFileFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `sharedFileForm.${action.payload.key}`, action.payload.value);
    },
    setSharedFileDataForEdit: (
      state,
      action: PayloadAction<SharedFileInterface>
    ) => {
      state.sharedFileForm = action.payload;
    },
    clearSharedFileForm: (state) => {
      state.sharedFileForm.fileId = "";
      state.sharedFileForm.name = "";
      state.sharedFileForm.search = "";
      state.sharedFileForm.linkPassword = "";
      state.sharedFileForm.linkAccess = "";
      state.sharedFileForm.accessType = accessType.ONLY_INVITED;
      state.sharedFileForm.fileMember = [];
    },
    doAddUser: (
      state,
      action: PayloadAction<{ email: string; isRemove: boolean | undefined }>
    ) => {
      let index = "";
      for (const ele of state.sharedFileForm.fileMember) {
        if (ele.email == action.payload.email) {
          index = state.sharedFileForm.fileMember.indexOf(ele).toString();
        }
      }
      if (index?.length > 0) {
        state.sharedFileForm.fileMember[Number(index)].remove = false;
      } else {
        state.sharedFileForm.fileMember.push({
          _id: state.userForm._id,
          email: state.userForm.email,
          name: state.userForm.name,
          remove: state.userForm.remove,
          isNew: state.userForm.isNew,
          profilePic: state.userForm.profilePic,
          invitationExpired: state.userForm.invitationExpired,
          isInvited: state.userForm.isInvited,
          userStatus: state.userForm.userStatus,
        });
      }
    },
    setUserDataForEdit: (state, action: PayloadAction<SharedWithInterface>) => {
      state.userForm.name = action.payload.name;
      state.userForm.email = action.payload.email;
      state.userForm.profilePic = action.payload.profilePic;
      state.userForm._id = action.payload._id;
      state.userForm.remove = action.payload.remove;
      state.userForm.isNew = action.payload.isNew;
    },
    doRemoveUser: (
      state,
      action: PayloadAction<{ index: number; id: string }>
    ) => {
      state.sharedFileForm.fileMember[action.payload.index].remove = true;
    },
    clearUserForm: (state) => {
      state.userForm._id = "";
      state.userForm.email = "";
      state.userForm.name = "";
      state.userForm.remove = false;
    },

    // folder upload loader
    setCurrentFolderUpload: (
      state,
      action: PayloadAction<CurrentFolderUploadInterface>
    ) => {
      state.currentFolderUpload.push(action.payload);
      log(state.currentFolderUpload, "text array");
    },
    setUploadFileCount: (
      state,
      action: PayloadAction<{ id: string; count: number }>
    ) => {
      let folderIdIndex = "";
      for (const ele of state.currentFolderUpload) {
        if (ele.folderId == action.payload.id) {
          folderIdIndex = state.currentFolderUpload.indexOf(ele).toString();
        }
      }
      // state.currentFolderUpload[Number(folderIdIndex)].currentCount =
      //   action.payload.count;
    },
    removeCurrentFolderUpload: (state, action: PayloadAction<string>) => {
      let folderIdIndex = "";
      for (const ele of state.currentFolderUpload) {
        if (ele.folderId == action.payload) {
          folderIdIndex = state.currentFolderUpload.indexOf(ele).toString();
        }
      }
      state.currentFolderUpload.splice(Number(folderIdIndex), 1);
    },
    removeDataList: (state, action: PayloadAction<number>) => {
      state.list.splice(action.payload, 1);
    },
    setSelectedFileId: (state, action: PayloadAction<string>) => {
      state.selectedId = action.payload;
    },
    setSelectedFileVersionId: (state, action: PayloadAction<string>) => {
      state.selectedFileVersionId = action.payload;
    },
    enqueue: (state, action: PayloadAction<any>) => {
      state.uploadQueue.push(action.payload);
    },
    deEnqueue: (state, action: PayloadAction<string>) => {
      const deleteItem = state.uploadQueue.filter(
        (x) => x.id == action.payload
      );
      if (deleteItem.length > 0) {
        state.uploadQueue = state.uploadQueue.filter(
          (x) => x.id !== action.payload
        );
        state.uploadDoneQueue.push(deleteItem[0]);
      }
    },
    setQueueBusy: (state, action: PayloadAction<boolean>) => {
      state.isQueueBusy = action.payload;
    },
    nextJob: (state) => {},
    addInProcess: (state, action: PayloadAction<string>) => {
      state.inProcessQueue.push(action.payload);
    },
    setFolderDownloadLoading: (state, action: PayloadAction<boolean>) => {
      state.folderDownloadLoading = action.payload;
    },
    setIsFolderSearch: (state, action: PayloadAction<boolean>) => {
      state.isFolderSearch = action.payload;
    },
    removeInProcess: (state, action: PayloadAction<string>) => {
      const index = state.inProcessQueue.indexOf(action.payload);
      if (index !== -1) {
        state.inProcessQueue.splice(index, 0);
      }
    },
    setQueueItemCancel: (state, action: PayloadAction<boolean>) => {
      state.isCancel = action.payload;
    },
    setCancelToken: (state, action: PayloadAction<CancelTokenSource>) => {
      state.cancelToken = action.payload;
    },
    doCancelUpload: (state) => {
      state.isCancel = true;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
