import { AxiosResponse } from "axios";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import CustomToast from "../../../Components/CustomToast";
import CatchBlockFunction from "../../../Components/hooks/CatchError";
import {
  SelectPlan,
  SelectedPlanSubscription,
  doGetOverview,
  doGetPlanList,
} from "../../../utils/request";
import { actions } from "./slice";
import { SubscriptionTypeEnum } from "./types";

export function* doGetOverviewRequest() {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(doGetOverview);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      return;
    }
    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetPlansListRequest(action: {
  payload: {
    callback: any;
  };
}) {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doGetPlanList);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      return;
    }
    yield put(actions.setPlansList(response.data.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doSelectPlanRequest(action: {
  payload: {
    planId: string;
    price: number;
    subscriptionType: SubscriptionTypeEnum;
    callback: any;
  };
}) {
  yield delay(500);

  yield put(actions.setButtonLoading(true));
  const data = {
    price: action.payload.price,
    planId: action.payload.planId,
    subscriptionType: action.payload.subscriptionType,
  };
  try {
    const response: AxiosResponse = yield call(SelectPlan, data);
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setPlanOrderData(response.data));
    yield call(action.payload.callback(response.data._id));
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doSelectedPlanSubscriptionRequest(action: {
  payload: { orderId: string; paymentMethod: any; address: any; callback: any };
}) {
  yield delay(500);

  const data = {
    orderId: action.payload.orderId,
    accountHolderName: action.payload.address.name,
    paymentMethod: action.payload.paymentMethod.paymentMethod.id,
    address: {
      city: action.payload?.address?.address?.city,
      country: action.payload?.address?.address?.country,
      line1: action.payload?.address?.address?.line1,
      line2: action.payload?.address?.address?.line2,
      postal_code: action.payload?.address?.address?.postal_code,
      state: action.payload?.address?.address?.state,
    },
  };
  try {
    yield put(actions.setStripeProcess(true));

    const response: AxiosResponse = yield call(SelectedPlanSubscription, data);
    yield put(actions.setStripeProcess(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield call(action.payload.callback(response.data.data));
  } catch (error: any) {
    yield put(actions.setStripeProcess(false));
    CatchBlockFunction(error);
  }
}

export function* overviewRepoSaga() {
  yield takeLatest(actions.doGetEdit, doGetOverviewRequest);
  yield takeLatest(actions.doGetPlansList, doGetPlansListRequest);
  yield takeLatest(actions.doSelectPlan, doSelectPlanRequest);
  yield takeLatest(
    actions.doSelectedPlanSubscription,
    doSelectedPlanSubscriptionRequest
  );
}
