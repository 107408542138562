import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { set } from "lodash";
import {
  CommentFormInterface,
  CommentListInterface,
  CommentTypeEnum,
  ProjectFormInterface,
  ReplyCommentFormInterface,
} from "./types";

export const useClinicSlice = createSlice({
  name: "reviewCommentList",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCommentUpdate: (state, action: PayloadAction<boolean>) => {
      state.commentUpdate = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setCarouselLoading: (state, action: PayloadAction<boolean>) => {
      state.carouselLoading = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setHasMore: (state, action: PayloadAction<boolean>) => {
      state.hasMore = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setFilter: (state, action: PayloadAction<string>) => {
      state.filter = action.payload;
    },
    clearListFilter: (state) => {
      state.filter = "1";
      state.sortDir = "desc";
    },

    toggleAccessLink: (state, action: PayloadAction<boolean>) => {
      state.accessLink = action.payload;
    },
    toggleFileCarousel: (state, action: PayloadAction<boolean>) => {
      state.fileCarousel = action.payload;
    },
    setVersion: (state, action: PayloadAction<string>) => {
      state.version = action.payload;
    },

    toggleDeleteComment: (state, action: PayloadAction<boolean>) => {
      state.deleteComment = action.payload;
    },

    doSendComment: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    updateCommentFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `commentForm.${action.payload.key}`, action.payload.value);
    },
    doUpdateComment: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    clearCommentForm: (state) => {
      state.commentForm.fileId = "";
      state.commentForm.fileVersionId = "";
      state.commentForm.comment = "";
      state.commentForm.rawComment = "";
      state.commentForm.commentType = CommentTypeEnum.PUBLIC;
      state.commentForm.attachedFiles = [];
      state.commentForm.attachedFigure = [];
      state.commentForm.startTime = "";
      state.commentForm.endTime = "";
      state.commentForm.mentionedMember = [];
    },
    setCommentFormData: (
      state,
      action: PayloadAction<CommentFormInterface>
    ) => {
      state.commentForm = action.payload;
    },
    doGetCommentList: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    setCommentList: (
      state,
      action: PayloadAction<Array<CommentListInterface>>
    ) => {
      state.commentList = action.payload;
    },
    doMarkAsComplete: (
      state,
      action: PayloadAction<{
        id: string;
        value: boolean;
        callback: () => void;
      }>
    ) => {},
    doDeleteComment: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    doLikeComment: (
      state,
      action: PayloadAction<{
        id: string;
        value: boolean;
        callback: () => void;
      }>
    ) => {},
    setProjectData: (state, action: PayloadAction<ProjectFormInterface>) => {
      state.projectData = action.payload;
    },

    setLikeComment: (
      state,
      action: PayloadAction<{
        index: number;
        data: boolean;
      }>
    ) => {
      state.commentList[action.payload.index].like = action.payload.data;
    },
    setMarkAsComplete: (
      state,
      action: PayloadAction<{
        index: number;
        data: boolean;
      }>
    ) => {
      state.commentList[action.payload.index].markAsComplete =
        action.payload.data;
    },

    // new work
    doGetCommentById: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
      }>
    ) => {},

    setDataInForm: (state, action: PayloadAction<CommentFormInterface>) => {
      state.commentForm = action.payload;
    },

    //---- reply comment ----///
    updateReplyCommentFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `replyCommentForm.${action.payload.key}`,
        action.payload.value
      );
    },
    doAddCommentReply: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    doGetCommentReplyById: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
      }>
    ) => {},
    doUpdateReplyComment: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    setReplyDataInForm: (
      state,
      action: PayloadAction<ReplyCommentFormInterface>
    ) => {
      state.replyCommentForm = action.payload;
    },

    clearReplyCommentForm: (state) => {
      state.replyCommentForm.commentId = "";
      state.replyCommentForm.reply = "";
      state.replyCommentForm.rawReply = "";
      state.replyCommentForm.mentionedMember = [];
    },
    setEditComment: (state, action: PayloadAction<number | null>) => {
      state.editComment = action.payload;
    },
    setEditReplyComment: (state, action: PayloadAction<number | null>) => {
      state.editReplyComment = action.payload;
    },
    setShowReply: (state, action: PayloadAction<number | null>) => {
      state.showReply = action.payload;
    },
    setCommentOnHoverSelectedId: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.commentOnHoverSelectedId = action.payload;
    },
    setCurrentReplyComment: (state, action: PayloadAction<string | null>) => {
      state.currentReplyComment = action.payload;
    },
    setCurrentComment: (state, action: PayloadAction<string | null>) => {
      state.currentComment = action.payload;
    },
    setSelectedOnClickComment: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.selectedOnClickComment = action.payload;
    },
    setClickOnCommentOptions: (state, action: PayloadAction<boolean>) => {
      state.clickOnCommentOptions = action.payload;
    },
    removeComment: (state, action: PayloadAction<number>) => {
      state.commentList.splice(action.payload, 1);
      if (state.commentList.length == 0) {
        state.hasMore = false;
        state.totalRow = 0;
      } else {
        state.totalRow = state.totalRow - 1;
        state.currentCommentSelectedId = null;
      }
    },
    removeReply: (
      state,
      action: PayloadAction<{ index: number; commentId: string }>
    ) => {
      let index;
      for (const ele of state.commentList) {
        if (ele?._id == action.payload.commentId) {
          index = state.commentList.indexOf(ele);
        }
      }
      state.commentList[Number(index)].replyData.splice(
        action.payload.index,
        1
      );
      state.currentCommentSelectedId = null;
    },
    doGetCommentListById: (
      state,
      action: PayloadAction<{
        id: string;
        fileVersionId: string;
        callback: () => void;
      }>
    ) => {},
    setCurrentCommentSelectedId: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.currentCommentSelectedId = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
