import { FileAndFolderState, UserProjectRoleEnum } from ".";
import { FileStatusEnum } from "../../../Review/types";
import { accessType } from "../../SharedProject/types";
import { ProjectStatusEnum } from "../../types";

export const initialState: FileAndFolderState = {
  pageNo: 1,
  pageSize: 15,
  totalRow: 0,
  hasMore: false,
  form: {
    projectId: "",
    fileId: "",
    name: "",
    color: "",
    isPrivate: false,
  },
  loading: false,
  buttonLoading: false,
  rename: false,
  delete: false,
  showDelete: false,
  showLeaveProject: false,
  createFolder: false,
  progress: false,
  folderColor: "",
  move: false,
  list: [],
  folderNameData: {
    name: "",
    description: "",
    projectId: "",
    fileId: "",
    filePathData: [],
    userRole: UserProjectRoleEnum.VIEWER,
    canAddUser: false,
    canDownload: false,
    isActive: ProjectStatusEnum.ACTIVE,
    projectCreated: false,
    projectOwner: false,
    canDownloadPrivateFile: false,
    canDeleteFile: false
  },
  imageUploadProgress: 0,
  //make file and folder private
  makePrivate: {
    fileId: "",
    isPrivate: false,
  },
  //change file status
  status: {
    fileId: "",
    fileVersionId: "",
    status: FileStatusEnum.NO_STATUS,
  },
  //rename file and folder
  renameFile: {
    name: "",
  },
  folderStructure: [],
  searchFolder: "",
  moveFile: {
    fileId: "",
  },
  createFile: {
    fileId: "",
    projectId: "",
    fileName: "",
    filePath: "",
    fileSize: "",
    isFolder: false,
    folderId: "",
  },
  uploadFolderId: "",
  uploadFileCount: 0,
  totalFileCount: 0,
  uploadingFile: false,
  fileInProcess: false,
  sortColumn: "createdAt",
  sortDir: "desc",
  uploadFileId: [],
  shareFile: false,
  sharedFileForm: {
    fileId: "",
    fileVersionId: "",
    linkAccess: "",
    linkPassword: "",
    accessType: accessType.ONLY_INVITED,
    search: "",
    name: "",
    fileMember: [],
  },
  userForm: {
    _id: "",
    name: "",
    email: "",
    remove: false,
    isNew: false,
  },
  currentFolderUpload: [],
  selectedId: "",
  selectedFileVersionId: "",
  uploadQueue: [],
  uploadDoneQueue: [],
  inProcessQueue: [],
  isQueueBusy: false,
  isCancel: false,
  cancelToken: null,
  folderFileList: [],
  folderDownloadLoading: false,
  isDownloadingFiles: false,
  isFolderSearch: false,
};
