import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  doCreateTeamProject,
  doGetTeamList,
  doVerifyTeamInvitation,
  doGetTeamInvitationDetails,
  doGetTeamRestoreDetails,
  doTeamRestore,
  getAPI,
  putAPI,
} from "../../../utils/request";
import { selectTeamInvitationForm, selectTeamRestoreForm } from "./selector";
import { TeamInvitationInterface, TeamRestoreInterface } from "./types";
import CatchBlockFunction from "../../../Components/hooks/CatchError";
import CustomToast from "../../../Components/CustomToast";
import { actions } from "./slice";
import { actions as projectActions } from "../slice";
import { actions as sharedProjectActions } from "../SharedProject/slice";
import { selectProjectForm } from "../selector";
import { ProjectFormInterface } from "../types";
import { t } from "i18next";
// team list
export function* doAddTeamProjectRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: ProjectFormInterface = yield select(selectProjectForm);
  if (form.name.length == 0) {
    CustomToast(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_NAME")}`, "ERROR");
    return;
  }
  if (form.description.length == 0) {
    CustomToast(
      `${t("ERROR_MESSAGE.DASHBOARD_MESSAGES.ENTER_DESCRIPTION")}`,
      "ERROR"
    );
    return;
  }

  yield put(projectActions.setProjectLoading(true));
  try {
    const response: AxiosResponse = yield call(doCreateTeamProject, form);
    yield put(projectActions.setProjectLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(projectActions.setProjectLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetTeamListRequest() {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doGetTeamList);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
//team invitation
export function* doGetTeamInvitationDetailsRequest(action: {
  payload: string;
}) {
  yield delay(500);

  yield put(sharedProjectActions?.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      doGetTeamInvitationDetails,
      `?token=${action.payload}`
    );
    yield put(sharedProjectActions?.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setTeamDetails(response.data.data));
  } catch (error: any) {
    yield put(sharedProjectActions?.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doVerifyTeamInvitationRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: TeamInvitationInterface = yield select(selectTeamInvitationForm);

  yield put(actions.setTeamLoading(true));
  try {
    const response: AxiosResponse = yield call(doVerifyTeamInvitation, form);
    yield put(actions.setTeamLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setTeamLoading(false));
    CatchBlockFunction(error);
  }
}
//team Restore
export function* doGetTeamRestoreDetailsRequest(action: { payload: string }) {
  yield delay(500);

  yield put(actions.setTeamLoading(true));
  try {
    const response: AxiosResponse = yield call(
      doGetTeamRestoreDetails,
      `?token=${action.payload}`
    );
    yield put(actions.setTeamLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setTeamRestoreDetails(response.data.data));
  } catch (error: any) {
    yield put(actions.setTeamLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doRestoreTeamRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: TeamRestoreInterface = yield select(selectTeamRestoreForm);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doTeamRestore, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
/********** PRoject Restore start*/
export function* doGetProjectRestoreDetailsRequest(action: {
  payload: string;
}) {
  yield delay(500);

  yield put(actions.setTeamLoading(true));
  try {
    const response: AxiosResponse = yield call(
      getAPI,
      `project/get-detail-by-token?token=${action.payload}`
    );
    yield put(actions.setTeamLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setProjectRestoreDetails(response.data.data));
  } catch (error: any) {
    yield put(actions.setTeamLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doRestoreProjectRequest(action: {
  payload: { token: string; callback: any };
}) {
  yield delay(500);
  const data = {
    token: action.payload.token,
  };
  yield put(actions.setLoading(true));

  try {
    const response: AxiosResponse = yield call(putAPI, `project/restore`, data);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
/********** PRoject Restore end*/
export function* TeamRepoSaga() {
  //teams
  yield takeLatest(actions.doGetList, doGetTeamListRequest);
  yield takeLatest(actions.doAddTeamProject, doAddTeamProjectRequest);
  yield takeLatest(
    actions.doGetTeamInvitationDetails,
    doGetTeamInvitationDetailsRequest
  );
  yield takeLatest(
    actions.doVerifyTeamInvitation,
    doVerifyTeamInvitationRequest
  );
  //******project restore */
  yield takeLatest(
    actions.doGetProjectRestoreDetails,
    doGetProjectRestoreDetailsRequest
  );
  yield takeLatest(actions.doRestoreProject, doRestoreProjectRequest);
}
