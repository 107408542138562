import { ForgotPasswordState } from ".";

export const initialState: ForgotPasswordState = {
  loading: false,
  forgotPasswordForm: {
    email: "",
  },
  resetPasswordForm: {
    hash: "",
    password: "",
    confirmPassword: "",
  },
};
