import { AuthState } from ".";

export const initialState: AuthState = {
  loading: false,
  token: null,
  isLogin: false,
  welcomeModal: false,
  openSideNavbar: false,
  isPathname: "",
  userData: {
    name: "",
    profilePic: "",
    email: "",
    _id: "",
    loginCount: null,
  },
  loginForm: {
    email: "",
    password: "",
  },
  registerForm: {
    name: "",
    email: "",
    password: "",
  },
  emailVerificationForm: {
    email: "",
    code: "",
    hash: "",
  },
  resendEmailForm: {
    hash: "",
  },
  isToken: "",
  userEmail: "",
  accountDeactivate: {
    token: "",
  },
  verifyEmail: {
    token: "",
  },
};
