import {
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  doCreateFolder,
  doGetFolderList,
  doGetFolderStructure,
  doMakeFilePrivate,
  uploadFilesAndFolder,
  doSetFileStatus,
  doDuplicateFileAndFolder,
  doRenameFile,
  doMoveFile,
  doCopyFile,
  doDeleteFile,
  doCreateFileRequestApi,
  folderSingedUrl,
  doShareFiles,
  doGetShareFileData,
  doGetNestedFolderStructure,
  doGetFileData,
  doGetProjectDetails,
  getFolderFileListRequest,
  doGetSearchFolderStructure,
  doGetSearchProjectStructure,
  doGetFolderListStructure,
} from "../../../utils/request";
import {
  selectForm,
  selectList,
  selectPageNo,
  selectPageSize,
  selectMakePrivateForm,
  selectStatusForm,
  selectRenameForm,
  selectMoveForm,
  selectSearchFolder,
  selectSortColumn,
  selectSortDir,
  selectShareFileForm,
  selectUploadQueue,
  selectIsQueueBusy,
  selectIsCancel,
} from "./selector";
import {
  CreateFileInterface,
  FileStatusInterface,
  FileTypeEnum,
  FolderInterface,
  MakePrivateInterface,
  MoveFileAndFolderInterface,
  RenameFileAndFolderInterface,
  SharedFileInterface,
} from "./types";
import { t } from "i18next";
import CatchBlockFunction from "../../../Components/hooks/CatchError";
import CustomToast from "../../../Components/CustomToast";
import { actions } from "./slice";
import { actions as dashboardAction } from "../slice";
import { END, eventChannel } from "redux-saga";
import { UploadStatusEnum } from "../../Review/types";
import { log } from "../../../utils/logger";
import { accessType } from "../SharedProject/types";

function createUploader(url: string, payload: any, result: any) {
  let emit: any;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  const uploadPromise = uploadFilesAndFolder(
    url,
    payload,
    result,
    (progressEvent: any) => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (percentCompleted == 100) emit(END);
      else emit(percentCompleted);
    }
  );

  return [uploadPromise, chan];
}

export function* doAddFolderRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: FolderInterface = yield select(selectForm);
  if (form.name.length === 0) {
    CustomToast(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_NAME")}`, "ERROR");
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doCreateFolder, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetFolderListRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setLoading(true));
  if (
    action.payload?.id?.length > 0 &&
    action.payload?.id != undefined &&
    action.payload?.id != null &&
    action.payload?.id != "undefined"
  ) {
    try {
      const folderList: [] = yield select(selectList);
      const pageNo: number = yield select(selectPageNo);
      const pageSize: number = yield select(selectPageSize);
      const sortColumn: string = yield select(selectSortColumn);
      const sortDir: string = yield select(selectSortDir);

      const response: AxiosResponse = yield call(
        doGetFolderList,
        action.payload.id,
        `?pageNo=${pageNo}&pageSize=${pageSize}${
          sortColumn.length > 0 ? "&sortColumn=" + sortColumn : ""
        }${sortDir.length > 0 ? "&sortDir=" + sortDir : ""}`
      );
      yield put(actions.setLoading(false));
      yield put(dashboardAction.toggleProjectSwitchLoading(false));
      if (response && !response.data) {
        CustomToast(response.data.message, "ERROR");
        return;
      }

      if (pageNo == 1) {
        yield put(actions.setList(response.data.data.results));
      } else {
        const concatData = folderList.concat(response.data.data.results);
        yield put(
          actions.setTotalRow(
            response.data.data.meta ? response.data.data.meta.total : 0
          )
        );
        yield put(actions.setList(concatData));
      }

      yield put(
        actions.setHasMore(
          response.data.data.meta.totalPages >= response.data.data.meta.page
        )
      );
      yield call(action?.payload?.callback());
    } catch (error: any) {
      yield put(actions.setLoading(false));
      yield put(dashboardAction.toggleProjectSwitchLoading(false));
      if (error?.response?.data?.statusCode == 4004) {
        yield put(dashboardAction.setIsContentExist(true));
        return;
      }
      if (error?.response?.data?.statusCode == 7002) {
        yield put(dashboardAction.setIsProjectExist(true));
        return;
      }
      CatchBlockFunction(error);
    }
  }
}
export function* doGetProjectDetailsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  if (
    action.payload?.id?.length > 0 &&
    action.payload?.id != undefined &&
    action.payload?.id != null &&
    action.payload?.id != "undefined"
  ) {
    try {
      const response: AxiosResponse = yield call(
        doGetProjectDetails,
        action.payload.id
      );
      if (response && !response.data) {
        CustomToast(response.data.message, "ERROR");
        return;
      }

      yield put(actions.setFolderNameData(response.data.data));
      yield call(action?.payload?.callback());
    } catch (error: any) {
      if (error?.response?.data?.statusCode === 7003) {
        yield put(dashboardAction.setIsDeactivated(true));
        return;
      }
      CatchBlockFunction(error);
    }
  }
}
// working
export function* FileUploadRequest(action: {
  payload: {
    fileName: string[];
    projectId: string;
    fileId: string;
    fileSize: string;
    callback: any;
  };
}) {
  try {
    const response: AxiosResponse = yield call(folderSingedUrl, action.payload);
    if (!response.status) {
      CustomToast(response.data.message, "ERROR");
    }
    yield call(
      action?.payload?.callback(
        response.data.data,
        response.data.data?.fileNameWithPrefix
      )
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* FolderUploadRequest(action: {
  payload: {
    fileName: string[];
    projectId: string;
    fileId: string;
    fileSize: string;
    callback: any;
  };
}) {
  try {
    const response: AxiosResponse = yield call(folderSingedUrl, action.payload);
    if (!response.status) {
      CustomToast(response.data.message, "ERROR");
    }
    yield call(
      action?.payload?.callback(
        response.data.data,
        response.data.data?.fileNameWithPrefix
      )
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UploadImageRequest(action: {
  payload: {
    fileId: string;
    data: any;
    signedUrl: string;
    result: any;
    callback: any;
  };
}) {
  try {
    const [uploadPromise, chan] = createUploader(
      action.payload.signedUrl,
      action.payload.data,
      action.payload.result
    );
    yield fork(watchOnProgress, chan, action.payload.fileId);
    const response: AxiosResponse = yield call(() => uploadPromise);
    // const response: AxiosResponse = yield call(
    //   uploadFilesAndFolder,
    //   action.payload.signedUrl,
    //   action.payload.data,
    //   action.payload.result
    // );
    if (!response.status) {
      CustomToast(response.data.message, "ERROR");
    }
    // CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doCreateFileRequest(action: {
  payload: { data: CreateFileInterface };
}) {
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      doCreateFileRequestApi,
      action.payload.data
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
  } catch (error: any) {
    if (action.payload.data.isFolder) {
      yield put(
        actions.uploadFolderRemoveFailedCount({
          id: action.payload.data.folderId,
        })
      );
    }

    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetFileDataRequest(action: {
  payload: { id: string; folderId: string; callback: any };
}) {
  try {
    const response: AxiosResponse = yield call(
      doGetFileData,
      action.payload.folderId.length > 0
        ? action.payload.folderId
        : action.payload.id
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield put(
      actions.updateFileStatusAfterComplete({
        id: action.payload.id,
        folderId: action.payload.folderId,
        data: response.data.data,
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    // CatchBlockFunction(error);
  }
}

// make private
export function* doMakePrivateRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: MakePrivateInterface = yield select(selectMakePrivateForm);
  yield put(actions.setLoading(false));
  try {
    const response: AxiosResponse = yield call(doMakeFilePrivate, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
// move and copy folder structure
export function* doGetNestedFolderStructureRequest(action: {
  payload: { id: string };
}) {
  yield delay(500);

  const search: string = yield select(selectSearchFolder);

  try {
    const response: AxiosResponse = yield call(
      doGetNestedFolderStructure,
      `${action.payload.id}`
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    if (search?.length > 0) {
      yield put(actions.setFolderStructureList(response.data.data));
    } else if (response?.data?.data?.length > 0) {
      yield put(
        actions.setNestedFolderList({
          id: action.payload.id,
          data: response?.data?.data,
        })
      );
    }
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
// checking
export function* doGetFolderSearchRequest(action: {
  payload: { id: string; search: string };
}) {
  yield delay(500);

  const search: string = yield select(selectSearchFolder);

  try {
    const response: AxiosResponse = yield call(
      doGetSearchFolderStructure,
      `${action.payload.id}?search=${search.length > 0 ? search : ""}`
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    if (search?.length > 0) {
      yield put(actions.setSearchFolderStructureList(response.data.data));
    } else if (response?.data?.data?.length > 0) {
      yield put(actions.setClearSearchFolderStructureList());
    }
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
//checking
export function* doGetProjectSearchRequest(action: {
  payload: { id: string; search: string };
}) {
  yield delay(500);

  const search: string = yield select(selectSearchFolder);

  try {
    const response: AxiosResponse = yield call(
      doGetSearchProjectStructure,
      `${action.payload.id}?search=${search.length > 0 ? search : ""}`
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    if (search?.length > 0) {
      yield put(actions.setSearchFolderStructureList(response.data.data));
    } else if (response?.data?.data?.length > 0) {
      yield put(actions.setClearSearchFolderStructureList());
    }
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetFolderStructureRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      doGetFolderStructure,
      action.payload
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setFolderStructureList(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* doGetFolderListStructureRequest(action: { payload: string }) {
  yield delay(500);
  try {
    yield put(actions.setIsFolderSearch(true));
    const response: AxiosResponse = yield call(
      doGetFolderListStructure,
      action.payload
    );
    yield put(actions.setIsFolderSearch(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(
      actions.setFolderNestedStructureList({
        items: response.data.data,
        id: action.payload,
      })
    );
  } catch (error: any) {
    yield put(actions.setIsFolderSearch(false));
    CatchBlockFunction(error);
  }
}
// change file status
export function* doSetFileStatusRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: FileStatusInterface = yield select(selectStatusForm);

  try {
    const response: AxiosResponse = yield call(doSetFileStatus, form);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
//duplicate file and folder
export function* doDuplicateFileAndFolderRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  let data = {};
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      doDuplicateFileAndFolder,
      action.payload.id,
      data
    );
    yield put(actions.setLoading(false));

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
//rename file and folder
export function* doRenameFileAndFolderRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  const form: RenameFileAndFolderInterface = yield select(selectRenameForm);
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      doRenameFile,
      action.payload.id,
      form
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doMoveFileAndFolderRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  const form: MoveFileAndFolderInterface = yield select(selectMoveForm);

  if (form?.fileId.length == 0) {
    CustomToast(
      `${t("ERROR_MESSAGE.DASHBOARD_MESSAGES.SELECT_FOLDER")}`,
      "ERROR"
    );
    return;
  }

  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      doMoveFile,
      action.payload.id,
      form
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doCopyFileAndFolderRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  const form: MoveFileAndFolderInterface = yield select(selectMoveForm);

  if (form?.fileId.length == 0) {
    CustomToast(
      `${t("ERROR_MESSAGE.DASHBOARD_MESSAGES.SELECT_FOLDER")}`,
      "ERROR"
    );
    return;
  }

  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      doCopyFile,
      action.payload.id,
      form
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doDeleteFileAndFolderRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(doDeleteFile, action.payload.id);
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
// share file
export function* doGetSharedFileRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      doGetShareFileData,
      action.payload
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setSharedFileDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doShareFileRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: SharedFileInterface = yield select(selectShareFileForm);
  if (form.accessType !== accessType.ANYONE_WITH_LINK) {
    if (form.fileMember?.length == 0) {
      CustomToast(
        `${t("ERROR_MESSAGE.DASHBOARD_MESSAGES.SHARE_WITH_MEMBER")}`,
        "ERROR"
      );
      return;
    }
  }

  yield put(actions.setLoading(true));
  let data = {
    fileId: form.fileId,
    accessType: form.accessType,
    linkAccess: form.linkAccess,
    linkPassword:
      form?.linkPassword?.length > 0
        ? btoa(form.linkPassword)
        : form.linkPassword,
    fileMember: form.fileMember,
  };

  try {
    const response: AxiosResponse = yield call(doShareFiles, data);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doNextRequest() {
  yield delay(500);
  yield put(actions.nextJob());
}
export function* doQueueFileRequest() {
  const queue: any[] = yield select(selectUploadQueue);
  const isQueueBusy: any[] = yield select(selectIsQueueBusy);
  if (queue.length == 0) {
    return;
  }
  if (isQueueBusy) {
    return;
  }
  const item = queue[0];
  if (item.fileType == FileTypeEnum.FILE) {
    yield put(
      actions.uploadFileStatus({
        id: item.id,
        status: UploadStatusEnum.UPLOADING,
      })
    );
  }
  yield put(actions.setQueueBusy(true));
  log("items", item);

  const [uploadPromise, chan] = createUploader(
    item.responseData.signedUrl,
    item.element,
    item.element.mimeType
  );
  yield fork(watchOnProgress, chan, item.id);

  const response: AxiosResponse = yield call(() => uploadPromise);

  if (item.fileType == FileTypeEnum.FOLDER) {
    yield put(
      actions.uploadFolderStatus({
        id: item.folder_id,
      })
    );
  }

  yield put(
    actions.uploadFileStatus({
      id: item.id,
      status: UploadStatusEnum.PROCESSING,
    })
  );

  yield put(actions.addInProcess(item.id));

  yield put(
    actions.doCreateFile({
      data: {
        fileId: item.id,
        fileName: item.responseData.fileName,
        filePath: item.responseData.fileNameWithPrefix,
        projectId: item.responseData.projectId,
        fileSize: item.element.size,
        isFolder: item.fileType == FileTypeEnum.FOLDER,
        folderId: item.folder_id,
      },
    })
  );

  yield put(actions.setQueueBusy(false));
  yield put(actions.deEnqueue(item.id));
}
export function* watchOnProgress(chan: any, fileId: string) {
  while (true) {
    let data: number = yield take(chan);
    yield put(actions.uploadFileProgressStatus({ id: fileId, progress: data }));
  }
}

export function* doGetFolderFileListRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  if (
    action.payload.id?.length > 0 &&
    action.payload.id != undefined &&
    action.payload.id != null
  ) {
    try {
      yield put(actions.setFolderDownloadLoading(true));
      const response: AxiosResponse = yield call(
        getFolderFileListRequest,
        action.payload.id
      );
      yield put(actions.setFolderDownloadLoading(false));
      if (response && !response.data) {
        CustomToast(response.data.message, "ERROR");
        return;
      }
      yield put(actions.setFolderFileList(response.data.data));
      yield call(action?.payload?.callback());
    } catch (error: any) {
      yield put(actions.setFolderDownloadLoading(false));
      CatchBlockFunction(error);
    }
  }
}
export function* FileAndFolderRepoSaga() {
  yield takeLatest(actions.doCreateFolder, doAddFolderRequest);
  yield takeLatest(actions.doGetFileAndFolderList, doGetFolderListRequest);
  yield takeLatest(actions.doGetProjectDetails, doGetProjectDetailsRequest);
  yield takeLatest(actions.doGetFileData, doGetFileDataRequest);
  yield takeLatest(actions.addImage, FileUploadRequest);
  yield takeLatest(actions.addFolder, FolderUploadRequest);
  yield takeLatest(actions.UploadImage, UploadImageRequest);
  yield takeEvery(actions.doCreateFile, doCreateFileRequest);
  yield takeLatest(actions.doMakePrivate, doMakePrivateRequest);
  yield takeLatest(actions.doGetFolderStructure, doGetFolderStructureRequest);
  yield takeLatest(
    actions.doGetNestedFolderListStructure,
    doGetFolderListStructureRequest
  );

  yield takeLatest(
    actions.doGetNestedFolderStructure,
    doGetNestedFolderStructureRequest
  );
  yield takeLatest(actions.doSetFileStatus, doSetFileStatusRequest);
  yield takeLatest(actions.doDuplicateFile, doDuplicateFileAndFolderRequest);
  yield takeLatest(actions.doRenameFileAndFolder, doRenameFileAndFolderRequest);
  yield takeLatest(actions.doMoveFileAndFolder, doMoveFileAndFolderRequest);
  yield takeLatest(actions.doCopyFileAndFolder, doCopyFileAndFolderRequest);
  yield takeLatest(actions.doDeleteFileAndFolder, doDeleteFileAndFolderRequest);
  yield takeLatest(actions.doGetSharedFileEdit, doGetSharedFileRequest);
  yield takeLatest(actions.doShareFile, doShareFileRequest);
  yield takeEvery(actions.nextJob, doQueueFileRequest);
  yield takeEvery(actions.enqueue, doNextRequest);
  yield takeEvery(actions.deEnqueue, doNextRequest);
  yield takeLatest(actions.doCancelUpload, doNextRequest);
  yield takeLatest(actions.doGetFolderFileList, doGetFolderFileListRequest);
  yield takeLatest(actions.doGetFolderSearch, doGetFolderSearchRequest);
  yield takeLatest(actions.doGetProjectSearch, doGetProjectSearchRequest);
}
