import {
  FolderNameListInterface,
  RenameFileAndFolderInterface,
  UserProjectRoleEnum,
} from "../../Dashboard/FileAndFolder/types";

export interface ReviewFileInterface {
  _id: string;
  fileVersionId: string;
  projectId: string;
  parentFileId: string;
  name: string;
  color: string;
  fileType: FileTypeEnum;
  isPrivate: boolean;
  fileVersion: number;
  status: FileStatusEnum;
  filePath: string;
  fileThumbnailPath: string;
  mimeType: string;
  width: number;
  height: number;
  videoTimeDuration: number;
  bit_rate: number;
  r_frame_rate: string;
  avg_frame_rate: string;
  time_base: string;
  possibleResolution: PossibleResolutionInterface[];
  createdAt: string;
  updatedAt: string;
  projectData: ProjectDataInterface;
}
export interface PossibleResolutionInterface {
  width: number;
  height: number;
  filePath: string;
}
export interface ProjectDataInterface {
  name: string;
  description: string;
  projectId: string;
  fileId: string;
  userRole: UserProjectRoleEnum;
  projectOwner: boolean;
  canAddUser: boolean;
  canDownload: boolean;
  isTeamMember: boolean;
}
export interface CommentFormInterface {
  fileId: string;
  fileVersionId: string;
  comment: string;
  rawComment: string;
  commentType: CommentTypeEnum;
  startTime: string;
  endTime: string;
  millisecondStartTime: number;
  millisecondEndTime: number;
  timeCodeStartTime: number;
  timeCodeEndTime: number;
  attachedFigure: [];
  attachedFiles: UploadedFilesInterface[];
  mentionedMember: string[];
  _id: string;
  userId: string;
  name: string;
  profilePic: string;
  createdAt: string;
  updatedAt: string;
  width: number;
  drawPadHeight: string;
  drawPadWidth: string;
}

export interface ReplyCommentFormInterface {
  commentId: string;
  reply: string;
  rawReply: string;
  mentionedMember: string[];
}
export interface ProjectFormInterface {
  name: string;
  description: string;
  projectId: string;
  fileId: string;
  filePathData: FolderNameListInterface[];
  userRole: UserProjectRoleEnum;
  canAddUser: boolean;
  canDownload: boolean;
  projectOwner: boolean;
  isTeamMember: boolean;
}
export interface ReplyDataInterface {
  _id: string;
  userId: string;
  name: string;
  profilePic: string;
  likesCount: number;
  like: boolean;
  markAsComplete: boolean;
  reply: string;
  rawReply: string;
  createdAt: string;
  updatedAt: string;
}
export interface CommentListInterface {
  _id: string;
  userId: string;
  name: string;
  profilePic: string;
  likesCount: number;
  like: boolean;
  markAsComplete: boolean;
  commentType: number;
  hasFigure: boolean;
  hasAttchedFile: boolean;
  attachedFile: [];
  comment: string;
  rawComment: string;
  mimeType: string;
  newComment: boolean;
  replyData: ReplyDataInterface[];
  createdAt: string;
  updatedAt: string;
}
export interface FileVersionsListInterface {
  _id: string;
  projectId: string;
  userId: string;
  userName: string;
  fileId: string;
  versionNumber: number;
  fileName: string;
  filePath: string;
  mimeType: string;
  index: number;
  fileThumbnailPath: string;
  createdAt: string;
  updatedAt: string;
  videoTimeDuration: number;
  fileVersionId?: string;
  width:number;
  height:number;
}
export interface MembersListInterface {
  _id: string;
  name: string;
  email: string;
  profilePic: string;
}
export interface UploadedFilesInterface {
  fileName: string;
  filePath: string;
  remove: boolean;
}
export interface ReplyInterface {
  comment: string;
}
export enum FileStatusEnum {
  NO_STATUS = 0,
  IN_REVIEW = 1,
  IN_PROGRESS = 2,
  APPROVED = 3,
}
export enum FileTypeEnum {
  FILE = 0,
  FOLDER = 1,
}
export enum CommentTypeEnum {
  PUBLIC = 1,
  PRIVATE = 2,
}
export enum CommentFilterEnum {
  ALL = 1,
  RESLOVED = 2,
}

export enum UploadStatusEnum {
  IN_QUEUE = 1,
  UPLOADING = 2,
  PROCESSING = 3,
  COMPLETED = 4,
}

export enum RangeDargTypeEnum {
  MAIN = 1,
  LEFT = 2,
  RIGHT = 3,
}
export interface CreateVersionInterface {
  projectId: string;
  fileId: string;
  fileName: string;
  filePath: string;
  fileSize: string;
}
export interface SelectedQualityFileInterface {
  filePath: string;
  height: number;
}
export interface progressBarCommentIndicatorInterface {
  _id: string;
  userId: string;
  name: string;
  profilePic: string;
  startTime: string;
  endTime: string;
  comment: string;
  createdAt: string;
  updatedAt: string;
}
export interface ReviewNavbarInterface {
  height: number;
  width: number;
}
export interface UserFileShareInterface {
  isPasswordProtected: boolean;
}
export interface AccessLinkInterface {
  linkPassword: string;
}
export interface AttachedFiguresDataInterface {
  attachedFigure: any;
  startTime: string;
  endTime: string;
  millisecondStartTime: number;
  millisecondEndTime: number;
  drawPadHeight: string;
  drawPadWidth: string;
}
export interface CommentIndicatorListInterface {
  comment: string;
  createdAt: string;
  endTime: string;
  name: string;
  profilePic: string;
  startTime: string;
  updatedAt: string;
  userId: string;
  _id: string;
  width: number;
  millisecondStartTime: number;
  millisecondEndTime: number;
  hasFigure: boolean;
  drawPadHeight: string;
  drawPadWidth: string;
}
export interface IVersion {
  fileData: FileVersionsListInterface;
  file_id: string;
}
export enum TimeCodeFilterEnum {
  STANDARD = 1,
  TIMECODE = 2,
}
export interface IReviewNavbar {
  height: number;
  width: number;
}
export interface ReviewState {
  loading: boolean;
  buttonLoading: boolean;
  carouselLoading: boolean;
  reviewFileForm: ReviewFileInterface;
  accessLink: boolean;
  fileCarousel: boolean;
  version: string;
  compare: boolean;
  showComment: boolean;
  folderFiles: ReviewFileInterface[];
  commentForm: CommentFormInterface;
  projectData: ProjectFormInterface;
  membersList: MembersListInterface[];
  selfMembersList: MembersListInterface[];
  fileVersions: FileVersionsListInterface[];
  uploadedFiles: UploadedFilesInterface[];
  // ---------- Canvas Draw Start ------------ //
  addLine: boolean;
  drawPencil: boolean;
  drawArrow: boolean;
  drawRect: boolean;
  redo: boolean;
  undo: boolean;
  drawData: boolean;
  clearCanvas: boolean;
  drawColor: string;
  // ---------- Canvas Draw End------------ //
  createVersionData: CreateVersionInterface;
  uploadingVersion: boolean;
  updatedVersionsList: string[];
  attachedFiguresData: AttachedFiguresDataInterface | null;
  selectedQualityFile: SelectedQualityFileInterface;
  startTime: string;
  endTime: string;
  isPressed: boolean;
  showTimeLine: boolean;
  showTimeLinePress: boolean;
  markTimeStamp: boolean;
  commentIndicatorList: CommentIndicatorListInterface[];
  renameFileVersion: RenameFileAndFolderInterface;
  renameVersion: boolean;
  userFileShareDetails: UserFileShareInterface;
  accessLinkForm: AccessLinkInterface;
  currentAuthModal: string;
  showRegister: boolean;
  fileNotForShare: boolean;
  playCurrentTime: number | null;
  selectedCompereFile: string | null;
  selectedCompereIndex: number;
  versionV1: string | null;
  versionV2: string | null;
  parentId: string | null;
  fileDataLoading: boolean;
  versions: IVersion[];
  // get height and width
  reviewNavbar: IReviewNavbar;
  reviewOptionBar: IReviewNavbar;
  reviewCompareOptionBar: IReviewNavbar;
  pinSelection: boolean;
  shiftDrag: boolean;
  otpHash: string;
  isMessageClear: boolean;
}

export type InitialReviewState = ReviewState;
