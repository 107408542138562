import { UpdatePasswordState } from ".";

export const initialState: UpdatePasswordState = {
  loading: false,
  form: {
    password: "",
    newPassword: "",
    confirmPassword: "",
  },
  googleAuthForm: {
    googleAuthentication: false,
    passwordUpdateCount: 0,
    googleLogin: false,
  },
  password: false,
  newPassword: false,
  confirmPassword: false,
};
