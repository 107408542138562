import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  doChangePasswordRequest,
  doGetGoogleAuth,
  doSendCodeForGoogleAuth,
  doUpdateGoogleAuth,
} from "../../../utils/request";
import { selectForm } from "./selector";
import { GoogleAuthInterface, PasswordInterface } from "./types";
import CatchBlockFunction from "../../../Components/hooks/CatchError";
import CustomToast from "../../../Components/CustomToast";
import { actions } from "./slice";
import { actions as loginActions } from "../../Login/slice";
import { t } from "i18next";

export function* doUpdatePasswordRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: PasswordInterface = yield select(selectForm);
  if (form.password.length === 0) {
    CustomToast(`${t("COMMON.ERROR_PASSWORD.OLD_PASSWORD_PROMPT")}`, "ERROR");
    return;
  }
  if (form.newPassword.length === 0) {
    CustomToast(`${t("COMMON.ERROR_PASSWORD.NEW_PASSWORD_PROMPT")}`, "ERROR");
    return;
  }
  if (form.confirmPassword.length === 0) {
    CustomToast(
      `${t("COMMON.ERROR_PASSWORD.CONFIRM_PASSWORD_PROMPT")}`,
      "ERROR"
    );
    return;
  }
  let data = {
    password: btoa(form.password),
    newPassword: btoa(form.newPassword),
    confirmPassword: btoa(form.confirmPassword),
  };
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doChangePasswordRequest, data);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield put(loginActions.setToken(response.data.data.accessToken));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetGoogleAuthRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetGoogleAuth);
    if (response && !response.data) {
      return;
    }
    yield put(actions.setGoogleDataForEdit(response.data.data));
    yield call(
      action?.payload?.callback(
        response.data.data.passwordUpdateCount,
        response.data.data.googleLogin
      )
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateGoogleAuthRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: GoogleAuthInterface = yield select(selectForm);

  try {
    const response: AxiosResponse = yield call(doUpdateGoogleAuth, form);
    if (response && !response.data) {
      return;
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateGooglePasswordRequest() {
  yield delay(500);

  let data = {};
  try {
    const response: AxiosResponse = yield call(doSendCodeForGoogleAuth, data);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* updatePasswordRepoSaga() {
  yield takeLatest(actions.doUpdate, doUpdatePasswordRequest);
  yield takeLatest(actions.doGetGoogleAuthDetail, doGetGoogleAuthRequest);
  yield takeLatest(actions.doUpdateGoogleAuth, doUpdateGoogleAuthRequest);
  yield takeLatest(
    actions.doUpdateGooglePassword,
    doUpdateGooglePasswordRequest
  );
}
