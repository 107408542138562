import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  DeleteProject,
  doCreateProjectRequest,
  doGetProjectEditRequest,
  doUpdateProjectRequest,
  doResendProjectInvitation,
  ManageProjectUsers,
  getAPI,
  putAPI,
  uploadProfileImage,
  postAPI,
} from "../../utils/request";
import {
  selectMyProjectList,
  selectPageNo,
  selectPageSize,
  selectProjectForm,
  selectSearch,
} from "./selector";
import { ProjectFormInterface } from "./types";
import CatchBlockFunction from "../../Components/hooks/CatchError";
import CustomToast from "../../Components/CustomToast";
import { actions } from "./slice";
import { actions as dashboardActions } from "../Dashboard/slice";
import { t } from "i18next";
export function* doAddProjectRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: ProjectFormInterface = yield select(selectProjectForm);
  if (form.name.length == 0) {
    CustomToast(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_NAME")}`, "ERROR");
    return;
  }

  yield put(actions.setProjectLoading(true));
  try {
    const response: AxiosResponse = yield call(doCreateProjectRequest, form);
    yield put(actions.setProjectLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback(response.data.data));
  } catch (error: any) {
    yield put(actions.setProjectLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  if (
    action.payload.id?.length > 0 &&
    action.payload.id != undefined &&
    action.payload.id != null
  ) {
    try {
      yield put(actions.setProjectOpenLoading(true));
      const response: AxiosResponse = yield call(
        doGetProjectEditRequest,
        action.payload.id
      );
      yield put(actions.setProjectOpenLoading(false));
      if (response && !response.data) {
        CustomToast(response.data.message, "ERROR");
        return;
      }
      yield put(actions.setProjectDataForEdit(response.data.data));
      yield call(action?.payload?.callback());
    } catch (error: any) {
      yield put(actions.setProjectOpenLoading(false));
      CatchBlockFunction(error);
    }
  }
}
export function* doUpdateMyProjectRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: ProjectFormInterface = yield select(selectProjectForm);
  if (form.name.length == 0) {
    CustomToast(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_NAME")}`, "ERROR");
    return;
  }

  yield put(actions.setProjectLoading(true));
  try {
    const response: AxiosResponse = yield call(doUpdateProjectRequest, form);
    yield put(actions.setProjectLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setProjectLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetAllProjectListRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const list: [] = yield select(selectMyProjectList);
  const pageNo: number = yield select(selectPageNo);
  const pageSize: number = yield select(selectPageSize);
  if (pageNo == 1) {
    yield put(actions.setProjectListLoading(true));
  }
  const search: string = yield select(selectSearch);

  try {
    const response: AxiosResponse = yield call(
      getAPI,
      `project/get-all?pageNo=${pageNo}&pageSize=${pageSize}${
        search.length > 0 ? "&search=" + `${search}` : ""
      }`
    );
    yield put(actions.setProjectListLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    if (pageNo == 1) {
      yield put(
        actions.setTotalRow(
          response.data.data.meta ? response.data.data.meta.total : 0
        )
      );
      yield put(actions.setProjectList(response.data.data.results));
    } else {
      const concatData = list.concat(response.data.data.results);
      yield put(
        actions.setTotalRow(
          response.data.data.meta ? response.data.data.meta.total : 0
        )
      );
      yield put(actions.setProjectList(concatData));
    }
    yield put(
      actions.setHasMore(
        response.data.data.meta.totalPages >= response.data.data.meta.page
      )
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setProjectListLoading(false));
    if (error?.response?.data?.statusCode == 7002) {
      yield put(dashboardActions.setIsProjectExist(true));
      return;
    }
    CatchBlockFunction(error);
  }
}
export function* doDeleteProjectRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setProjectDelete(true));
  try {
    const response: AxiosResponse = yield call(
      DeleteProject,
      action.payload.id
    );
    yield put(actions.setProjectDelete(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setProjectDelete(false));
    CatchBlockFunction(error);
  }
}
export function* doLeaveProjectRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setProjectDelete(true));
  try {
    const response: AxiosResponse = yield call(
      putAPI,
      `project/leave/${
        action.payload.id.length > 0 &&
        action.payload.id !== undefined &&
        action.payload.id !== null &&
        action.payload.id
      }`,
      action.payload.id
    );
    yield put(actions.setProjectDelete(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setProjectDelete(false));
    CatchBlockFunction(error);
  }
}
export function* doGetSharedWithListRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  yield put(actions.setSharedProjectListLoading(true));
  try {
    const response: AxiosResponse = yield call(
      getAPI,
      `project/get-project-member-list/${action.payload.id}`
    );
    yield put(actions.setSharedProjectListLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setSharedWithList(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setSharedProjectListLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doResendProjectInvitationRequest(action: {
  payload: { id: string; email: string; callback: any };
}) {
  yield delay(500);

  const data = {
    email: action.payload.email,
  };

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      doResendProjectInvitation,
      action.payload.id,
      data
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
/// manage user's
export function* doManageProjectUsersRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  const form: ProjectFormInterface = yield select(selectProjectForm);
  const data = {
    projectMember: form.projectMember,
  };
  yield put(actions.setProjectLoading(true));
  try {
    const response: AxiosResponse = yield call(
      ManageProjectUsers,
      action.payload.id,
      data
    );
    yield put(actions.setProjectLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setProjectLoading(false));
    CatchBlockFunction(error);
  }
}
/********* */
export function* FileUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      postAPI,
      `project/thumbnail/signed-url`,
      {
        fileName: action.payload.data,
      }
    );
    if (!response.status) {
      CustomToast(response.data.message, "ERROR");
    }
    yield call(
      action?.payload?.callback(
        response.data.data.signedUrl,
        response.data.data.fileNameWithPrefix
      )
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UploadImageRequest(action: {
  payload: { data: any; signedUrl: string; result: any; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      uploadProfileImage,
      action.payload.signedUrl,
      action.payload.data,
      action.payload.result
    );
    if (!response.status) {
      CustomToast(response.data.message, "ERROR");
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
} /******** */

export function* dashboardRepoSaga() {
  yield takeLatest(actions.doAddProject, doAddProjectRequest);
  yield takeLatest(actions.doGetProjectEdit, doGetEditRequest);
  yield takeLatest(actions.doUpdateProject, doUpdateMyProjectRequest);
  yield takeLatest(actions.doGetAllProjectList, doGetAllProjectListRequest);
  yield takeLatest(actions.doDeleteProject, doDeleteProjectRequest);
  yield takeLatest(actions.doLeaveProject, doLeaveProjectRequest);
  yield takeLatest(actions.doGetSharedWithList, doGetSharedWithListRequest);
  yield takeLatest(
    actions.doResendProjectInvitation,
    doResendProjectInvitationRequest
  );
  yield takeLatest(actions.doManageProjectUsers, doManageProjectUsersRequest);

  // upload image
  yield takeLatest(actions.addImage, FileUploadRequest);
  yield takeLatest(actions.UploadImage, UploadImageRequest);
}
