import { NotificationSettingState, NotificationTimeEnum } from ".";

export const initialState: NotificationSettingState = {
  loading: false,
  pageChangeLoading: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  form: {
    projectActivity: false,
    mention: true,
    inAppNotification: false,
    timeZone: "",
    notificationTime: NotificationTimeEnum.HOURLY,
  },
  notifications: [],
  unreadNotifications: [],
  newNotification: 0,
  timeZones: [],
  markAllRead: false,
};
