import { CommentTypeEnum, FileStatusEnum, FileTypeEnum, ReviewState } from ".";
import { UserProjectRoleEnum } from "../../Dashboard/FileAndFolder/types";

export const initialState: ReviewState = {
  loading: false,
  buttonLoading: false,
  accessLink: false,
  fileCarousel: false,
  version: "v1",
  compare: false,
  showComment: false,
  reviewFileForm: {
    _id: "",
    projectId: "",
    parentFileId: "",
    name: "",
    color: "",
    fileType: FileTypeEnum.FILE,
    isPrivate: false,
    filePath: "",
    status: FileStatusEnum.NO_STATUS,
    mimeType: "",
    createdAt: "",
    updatedAt: "",
    fileVersionId: "",
    fileVersion: 1,
    fileThumbnailPath: "",
    width: 0,
    height: 0,
    videoTimeDuration: 0,
    bit_rate: 0,
    r_frame_rate: "",
    avg_frame_rate: "",
    time_base: "",
    possibleResolution: [],
    projectData: {
      name: "",
      description: "",
      projectId: "",
      fileId: "",
      userRole: UserProjectRoleEnum.VIEWER,
      projectOwner: false,
      canAddUser: false,
      canDownload: false,
      isTeamMember: false,
    },
  },
  folderFiles: [],
  carouselLoading: false,
  commentForm: {
    fileId: "",
    width: 0,
    rawComment: "",
    fileVersionId: "",
    comment: "",
    commentType: CommentTypeEnum.PUBLIC,
    startTime: "",
    endTime: "",
    attachedFigure: [],
    attachedFiles: [],
    mentionedMember: [],
    millisecondStartTime: 0,
    millisecondEndTime: 0,
    _id: "",
    userId: "",
    name: "",
    profilePic: "",
    createdAt: "",
    updatedAt: "",
    timeCodeEndTime: 0,
    timeCodeStartTime: 0,
    drawPadHeight: "",
    drawPadWidth: "",
  },
  projectData: {
    name: "",
    description: "",
    projectId: "",
    fileId: "",
    filePathData: [],
    userRole: UserProjectRoleEnum.VIEWER,
    canAddUser: false,
    canDownload: false,
    projectOwner: false,
    isTeamMember: false,
  },
  membersList: [],
  fileVersions: [],
  uploadedFiles: [],
  // ---------- Canvas Draw Start ------------ //
  addLine: false,
  drawPencil: false,
  drawArrow: false,
  drawRect: false,
  redo: false,
  undo: false,
  drawData: false,
  clearCanvas: false,
  drawColor: "#FF3B2E",
  isPressed: false,
  // ---------- Canvas Draw End------------ //
  createVersionData: {
    projectId: "",
    fileId: "",
    fileName: "",
    filePath: "",
    fileSize: "",
  },
  uploadingVersion: false,
  updatedVersionsList: [],
  selectedQualityFile: { filePath: "", height: 0 },
  startTime: "",
  endTime: "",
  markTimeStamp: true,
  showTimeLine: false,
  commentIndicatorList: [],
  attachedFiguresData: null,
  renameFileVersion: {
    name: "",
  },
  renameVersion: false,
  userFileShareDetails: {
    isPasswordProtected: false,
  },
  accessLinkForm: {
    linkPassword: "",
  },
  currentAuthModal: "register",
  showRegister: false,
  fileNotForShare: false,
  playCurrentTime: 1,
  selectedCompereFile: null,
  versionV1: null,
  versionV2: null,
  parentId: null,
  fileDataLoading: false,
  versions: [],
  selectedCompereIndex: 0,
  reviewNavbar: { height: 0, width: 0 },
  reviewOptionBar: { height: 0, width: 0 },
  reviewCompareOptionBar: { height: 0, width: 0 },
  selfMembersList: [],
  pinSelection: false,
  shiftDrag: false,
  showTimeLinePress: false,
  otpHash: "",
  isMessageClear: false,
};
