import { log } from "../../utils/logger";
import ItemProvider from "./ItemProvider";
// import { useRef } from "react";

// I've modified your getNonSequentialResponse function as a helper
// function to return a fake async job function that resolves to our item
const getFakeAsyncJob = (item: any) => {
  const timeout =500;
  return () =>
    new Promise((resolve) => {
      setTimeout(() => {
        log(item + "-response");
      
        resolve(item + "-response");
      }, timeout);
    });
};

export default function App() {
  const itemProvider = new ItemProvider();

  function keyDownEventHandler(ev: any) {
    // if (ev.keyCode === 13) {
      const textFieldValue = (document.getElementById("textfieldid") as any)
        .value;

      // not sequential
      // itemProvider.getNonSequentialResponse(textFieldValue).then((response) => {
      
      // });

      // we make a fake async function tht resolves to our textFieldValue
      const myFakeAsyncJob = getFakeAsyncJob(textFieldValue);
      // and enqueue it
      itemProvider.enqueue(myFakeAsyncJob);
    // }
  }

  return (
    <div style={{ backgroundColor: "white" }}>
      <input
        id="textfieldid"
        placeholder={"Type and hit Enter"}
        onKeyDown={keyDownEventHandler}
        type="text"
      />

      <div className="displaylabelandbox">
        <label>Display box below</label>
        <div className="displaybox">hello</div>
      </div>
    </div>
  );
}
