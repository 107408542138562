import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../../types";

const selectDomain = (state: RootState) => {
  if (state && state.notificationSettingState) {
    return state.notificationSettingState;
  } else {
    return initialState;
  }
};

export const selectForm = createSelector([selectDomain], (state) => state.form);
export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectPageChangeLoading = createSelector(
  [selectDomain],
  (state) => state.pageChangeLoading
);
export const selectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const selectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const selectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const selectNewNotification = createSelector(
  [selectDomain],
  (state) => state.newNotification
);
export const selectNotifications = createSelector(
  [selectDomain],
  (state) => state.notifications
);
export const selectUnreadNotifications = createSelector(
  [selectDomain],
  (state) => state.unreadNotifications
);
export const selectTimeZonesList = createSelector(
  [selectDomain],
  (state) => state.timeZones
);
export const selectMarkAllRead = createSelector(
  [selectDomain],
  (state) => state.markAllRead
);
