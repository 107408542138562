import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  doProfileUpdateRequest,
  GetUserProfileDetails,
  ProfileImageSingedUrl,
  uploadProfileImage,
  doDeleteAccount,
} from "../../../utils/request";
import { selectUserDetailsForm, selectAccountPassword } from "./selector";
import { ProfileInterface } from "./types";
import CatchBlockFunction from "../../../Components/hooks/CatchError";
import CustomToast from "../../../Components/CustomToast";
import { actions } from "./slice";
import { actions as loginActions } from "../../Login/slice";
import { t } from "i18next";
export function* doGetProfileDetailRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  yield put(actions.setUpdateLoading(true));
  try {
    const response: AxiosResponse = yield call(GetUserProfileDetails);
    yield put(actions.setUpdateLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setDataForEdit(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setUpdateLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateProfileRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: ProfileInterface = yield select(selectUserDetailsForm);
  if (form.name.length === 0) {
    CustomToast(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_NAME")}`, "ERROR");
    return;
  }
  if (form.email.length === 0) {
    CustomToast(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_EMAIL")}`, "ERROR");
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doProfileUpdateRequest, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield put(loginActions.setToken(response.data.data.accessToken));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* FileUploadRequest(action: {
  payload: { data: any; type: number; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      ProfileImageSingedUrl,
      action.payload.data
    );
    if (!response.status) {
      CustomToast(response.data.message, "ERROR");
    }
    yield call(
      action?.payload?.callback(
        response.data.signedUrl,
        response.data.fileNameWithPrefix
      )
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UploadImageRequest(action: {
  payload: { data: any; signedUrl: string; result: any; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      uploadProfileImage,
      action.payload.signedUrl,
      action.payload.data,
      action.payload.result
    );
    if (!response.status) {
      CustomToast(response.data.message, "ERROR");
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doDeleteAccountRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const password: string = yield select(selectAccountPassword);

  yield put(actions.setUpdateLoading(true));
  try {
    const response: AxiosResponse = yield call(
      doDeleteAccount,
      `?password=${btoa(password)}`
    );
    yield put(actions.setUpdateLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setUpdateLoading(false));
    CatchBlockFunction(error);
  }
}

export function* profileRepoSaga() {
  yield takeLatest(actions.doUpdate, doUpdateProfileRequest);
  yield takeLatest(actions.doGetUserProfileDetails, doGetProfileDetailRequest);
  yield takeLatest(actions.addImage, FileUploadRequest);
  yield takeLatest(actions.UploadImage, UploadImageRequest);
  yield takeLatest(actions.doDeleteAccount, doDeleteAccountRequest);
}
