import { OverviewState, PlanEnumInterface, SubscriptionTypeEnum } from ".";

export const initialState: OverviewState = {
  loading: false,
  buttonLoading: false,
  plan: "Free",
  billed: SubscriptionTypeEnum.MONTHLY,
  form: {
    projects: 0,
    users: 0,
    storage: 0,
    teams: 0,
    maxProjects: 0,
    maxUsers: 0,
    maxStorage: 0,
    maxTeams: 0,
    renewDate: "",
    plan: PlanEnumInterface.FREE,
  },
  planList: [],
  planOrderData: { _id: "", publicKey: "" },
  planSubscription: {
    accountHolderName: "",
    orderId: "",
    address: {
      city: "",
      country: "",
      line1: "",
      line2: "",
      postal_code: "",
      state: "",
    },
  },
  stripProcess: false,
};
