import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  doAddTeam,
  doGetTeamsList,
  doGetTeamEdit,
  doUpdateTeam,
  doDeleteTeam,
  doGetAllUser,
  doResendTeamInvitation,
  getAPI,
} from "../../../utils/request";
import {
  selectPageNo,
  selectForm,
  selectPageSize,
  selectResendInvitationForm,
  selectSortDir,
  selectSortColumn,
} from "./selector";
import { TeamInterface } from "./types";
import CatchBlockFunction from "../../../Components/hooks/CatchError";
import CustomToast from "../../../Components/CustomToast";
import { actions } from "./slice";
import { t } from "i18next";
export function* doAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: TeamInterface = yield select(selectForm);

  if (form.name.length === 0) {
    CustomToast(
      `${t("ERROR_MESSAGE.ACCOUNT_SETTINGS.MANAGE_TEAM.ENTER_TEAM_NAME")}`,
      "ERROR"
    );
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doAddTeam, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const sortColumn: string = yield select(selectSortColumn);
    const sortDir: string = yield select(selectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      getAPI,
      `project/get-list?pageNo=${pageNo}&pageSize=${pageSize}${
        sortColumn.length > 0 ? "&sortColumn=" + sortColumn : ""
      }${sortDir.length > 0 ? "&sortDir=" + sortDir : ""}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta.totalPages));
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetTeamEdit, action.payload);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: TeamInterface = yield select(selectForm);

  if (form.name.length == 0) {
    CustomToast(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_NAME")}`, "ERROR");
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doUpdateTeam, form);
    yield put(actions.setLoading(false));

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doDeleteTeamRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doDeleteTeam, action.payload.id);
    yield put(actions.setLoading(false));

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetAllUserRequest(action: {
  payload: { search: string; callback: any; id: string };
}) {
  yield delay(500);
  try {
    yield put(actions.setUserSearch(true));
    const response: AxiosResponse = yield call(
      doGetAllUser,
      `?search=${action.payload.search}${
        action.payload.id && action.payload.id.length > 0
          ? `&projectId=${action.payload.id}`
          : ""
      }`
    );
    yield put(actions.setUserSearch(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield put(
      actions.setAllUserList({
        result: response.data.data,
        search: action.payload.search,
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setUserSearch(false));
    CatchBlockFunction(error);
  }
}
export function* doAddUserRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  try {
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doResendTeamInvitationRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  const form: TeamInterface = yield select(selectResendInvitationForm);

  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      doResendTeamInvitation,
      action.payload.id,
      form
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}

export function* manageTeamRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetEdit, doGetEditRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.doDeleteTeam, doDeleteTeamRequest);
  yield takeLatest(actions.doGetAllUserList, doGetAllUserRequest);
  yield takeLatest(actions.doResendInvitation, doResendTeamInvitationRequest);
}
