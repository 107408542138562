import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../../types";

const selectDomain = (state: RootState) => {
  if (state && state.profileState) {
    return state.profileState;
  } else {
    return initialState;
  }
};

export const selectUserDetailsForm = createSelector([selectDomain], (state) => state.form);
export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectUpdateLoading = createSelector(
  [selectDomain],
  (state) => state.updateLoading
);
export const selectConfirmProfile = createSelector(
  [selectDomain],
  (state) => state.confirmProfile
);
export const selectAccountPassword = createSelector(
  [selectDomain],
  (state) => state.password
);
