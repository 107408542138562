import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../../types";

const selectDomain = (state: RootState) => {
  if (state && state.fileAndFolderState) {
    return state.fileAndFolderState;
  } else {
    return initialState;
  }
};

export const selectForm = createSelector([selectDomain], (state) => state.form);
export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectButtonLoading = createSelector(
  [selectDomain],
  (state) => state.buttonLoading
);
export const selectRename = createSelector(
  [selectDomain],
  (state) => state.rename
);
export const selectDelete = createSelector(
  [selectDomain],
  (state) => state.delete
);
export const selectCreateFolder = createSelector(
  [selectDomain],
  (state) => state.createFolder
);
export const selectMove = createSelector([selectDomain], (state) => state.move);
export const selectFolderColor = createSelector(
  [selectDomain],
  (state) => state.folderColor
);
export const selectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const selectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const selectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const selectHasMore = createSelector(
  [selectDomain],
  (state) => state.hasMore
);
export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectFolderNameData = createSelector(
  [selectDomain],
  (state) => state.folderNameData
);
export const selectImageUploadProgress = createSelector(
  [selectDomain],
  (state) => state.imageUploadProgress
);
export const selectMakePrivateForm = createSelector(
  [selectDomain],
  (state) => state.makePrivate
);
export const selectFolderStructure = createSelector(
  [selectDomain],
  (state) => state.folderStructure
);
export const selectSearchFolder = createSelector(
  [selectDomain],
  (state) => state.searchFolder
);
export const selectStatusForm = createSelector(
  [selectDomain],
  (state) => state.status
);
export const selectRenameForm = createSelector(
  [selectDomain],
  (state) => state.renameFile
);
export const selectMoveForm = createSelector(
  [selectDomain],
  (state) => state.moveFile
);
export const selectCreateFileForm = createSelector(
  [selectDomain],
  (state) => state.createFile
);
export const selectShowDelete = createSelector(
  [selectDomain],
  (state) => state.showDelete
);
export const selectShowLeaveProject= createSelector(
  [selectDomain],
  (state) => state.showLeaveProject
);
export const selectUploadFolderId = createSelector(
  [selectDomain],
  (state) => state.uploadFolderId
);
export const selectUploadFileCount = createSelector(
  [selectDomain],
  (state) => state.uploadFileCount
);
export const selectTotalFileCount = createSelector(
  [selectDomain],
  (state) => state.totalFileCount
);
export const selectUploadingFile = createSelector(
  [selectDomain],
  (state) => state.uploadingFile
);
export const selectFileInProcess = createSelector(
  [selectDomain],
  (state) => state.fileInProcess
);
export const selectSortColumn = createSelector(
  [selectDomain],
  (state) => state.sortColumn
);
export const selectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir
);
export const selectUploadFileId = createSelector(
  [selectDomain],
  (state) => state.uploadFileId
);
export const selectShareFile = createSelector(
  [selectDomain],
  (state) => state.shareFile
);
export const selectShareFileForm = createSelector(
  [selectDomain],
  (state) => state.sharedFileForm
);
export const selectCurrentFolderUpload = createSelector(
  [selectDomain],
  (state) => state.currentFolderUpload
);
export const selectSelectedFileId = createSelector(
  [selectDomain],
  (state) => state.selectedId
);
export const selectSelectedFileVersionId = createSelector(
  [selectDomain],
  (state) => state.selectedFileVersionId
);

export const selectUploadQueue = createSelector(
  [selectDomain],
  (state) => state.uploadQueue
);

export const selectIsQueueBusy = createSelector(
  [selectDomain],
  (state) => state.isQueueBusy
);
export const selectIsCancel = createSelector(
  [selectDomain],
  (state) => state.isCancel
);
export const selectCancelToken = createSelector(
  [selectDomain],
  (state) => state.cancelToken
);
export const selectFolderFileList = createSelector(
  [selectDomain],
  (state) => state.folderFileList
);
export const selectFolderDownloadLoading = createSelector(
  [selectDomain],
  (state) => state.folderDownloadLoading
);

export const selectIsDownloadingFiles = createSelector(
  [selectDomain],
  (state) => state.isDownloadingFiles
);

export const selectUploadDoneQueue = createSelector(
  [selectDomain],
  (state) => state.uploadDoneQueue
);
export const selectIsFolderSearch = createSelector(
  [selectDomain],
  (state) => state.isFolderSearch
);
