import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../types";

const selectDomain = (state: RootState) => {
  if (state && state.authState) {
    return state.authState;
  } else {
    return initialState;
  }
};

export const selectUserDataForm = createSelector(
  [selectDomain],
  (state) => state.userData
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectIsLogin = createSelector(
  [selectDomain],
  (state) => state.isLogin
);
export const SelectToken = createSelector(
  [selectDomain],
  (state) => state.token
);
export const selectLoginForm = createSelector(
  [selectDomain],
  (state) => state.loginForm
);
export const selectRegisterForm = createSelector(
  [selectDomain],
  (state) => state.registerForm
);
export const selectEmailVerificationCodeForm = createSelector(
  [selectDomain],
  (state) => state.emailVerificationForm
);
export const SelectWelcomeModal = createSelector(
  [selectDomain],
  (state) => state.welcomeModal
);
export const selectResendEmailForm = createSelector(
  [selectDomain],
  (state) => state.resendEmailForm
);
export const selectIsToken = createSelector(
  [selectDomain],
  (state) => state.isToken
);
export const selectOpenSideNavbar = createSelector(
  [selectDomain],
  (state) => state.openSideNavbar
);
export const selectIsPathname = createSelector(
  [selectDomain],
  (state) => state.isPathname
);
export const selectUserEmail = createSelector(
  [selectDomain],
  (state) => state.userEmail
);
export const selectAccountDeactivateForm = createSelector(
  [selectDomain],
  (state) => state.accountDeactivate
);
export const selectVerifyEmailForm = createSelector(
  [selectDomain],
  (state) => state.verifyEmail
);
