import {
  ManageTeamState,
  TeamMemberActiveStatusEnum,
  TeamMemberRoleEnum,
} from ".";

export const initialState: ManageTeamState = {
  loading: false,
  buttonLoading: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  form: {
    _id: "",
    name: "",
    search: "",
    teamMemberCount: 0,
    teamMember: [],
  },
  list: [],
  userList: [],
  userForm: {
    name: "",
    email: "",
    teamRole: TeamMemberRoleEnum.VIEWER,
    _id: "",
    profilePic: "",
    isNew: true,
    invitationExpired: false,
    isInvited: false,
    userStatus: TeamMemberActiveStatusEnum.ACTIVE,
  },
  addTeam: {
    team: false,
    fromDashboard: false,
  },
  deleteTeam: false,
  resendInvitationForm: {
    email: "",
    teamRole: TeamMemberRoleEnum.VIEWER,
  },
  clearAutoComplete: false,
  userSearch: false,
  sortColumn: "",
  sortDir: ""
};
