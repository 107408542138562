import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { EmailVerificationInterface, IUserDataInterface } from "./types";
import { set } from "lodash";

export const useClinicSlice = createSlice({
  name: "authState",
  initialState,
  reducers: {
    toggleOpenWelcome: (state, action: PayloadAction<boolean>) => {
      state.welcomeModal = action.payload;
      if (action.payload == false) {
      }
    },
    setOpenSideNavbar: (state, action: PayloadAction<boolean>) => {
      state.openSideNavbar = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setIsToken: (state, action: PayloadAction<string | null>) => {
      state.isToken = action.payload;
    },
    updateLoginFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `loginForm.${action.payload.key}`, action.payload.value);
    },
    clearLoginFrom: (state) => {
      state.loginForm.email = "";
      state.loginForm.password = "";
    },
    doLogin: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doLogOut: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("welcomeModal");
      sessionStorage.clear();
    },
    updateRegisterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `registerForm.${action.payload.key}`, action.payload.value);
    },
    clearRegisterFrom: (state) => {
      state.registerForm.name = "";
      state.registerForm.email = "";
      state.registerForm.password = "";
    },
    doSendVerificationCode: (
      state,
      action: PayloadAction<{ callback: (hash: string) => void }>
    ) => {},
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem("token", action.payload);
      sessionStorage.setItem("token", action.payload);
    },
    updateEmailVerificationForm: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `emailVerificationForm.${action.payload.key}`,
        action.payload.value
      );
    },
    setVerificationCode: (
      state,
      action: PayloadAction<EmailVerificationInterface>
    ) => {
      state.emailVerificationForm = action.payload;
    },
    clearEmailVerificationFrom: (state) => {
      state.emailVerificationForm.code = "";
      state.emailVerificationForm.hash = "";
      state.emailVerificationForm.email = "";
    },
    doVerifyCode: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    // google login
    doGoogleLogin: (
      state,
      action: PayloadAction<{ data: any; callback: (data: any) => void }>
    ) => {},
    doGetProfile: (
      state,
      action: PayloadAction<{
        token: string;
        callback: (data: {
          role: number;
          id: string;
          loginCount: number;
        }) => void;
      }>
    ) => {},
    doProfileUpdate: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {},
    setProfileData: (state, action: PayloadAction<IUserDataInterface>) => {
      state.userData = action.payload;
    },
    updateResendEmailFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `resendEmailForm.${action.payload.key}`, action.payload.value);
    },
    setResendEmailForm: (
      state,
      action: PayloadAction<EmailVerificationInterface>
    ) => {
      state.resendEmailForm = action.payload;
    },
    doResendEmail: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    setIsPathname: (state, action: PayloadAction<string>) => {
      state.isPathname = action.payload;
    },
    doGetDetails: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.userEmail = action.payload;
    },
    doDeactivateAccount: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    updateDeactivateAccountFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `accountDeactivate.${action.payload.key}`,
        action.payload.value
      );
    },
    // verify updated email
    doVerifyUpdatedEmail: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    updateVerifyEmailFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `verifyEmail.${action.payload.key}`, action.payload.value);
    },
    clearLocalStorage: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("welcomeModal");
      sessionStorage.clear();
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
