import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { ResetPasswordInterface } from "./types";
import { set } from "lodash";

export const useClinicSlice = createSlice({
  name: "forgotPasswordState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateForgotPasswordFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `forgotPasswordForm.${action.payload.key}`,
        action.payload.value
      );
    },
    clearForgotPasswordFrom: (state) => {
      state.forgotPasswordForm.email = "";
    },
    doSendForgotPasswordCode: (
      state,
      action: PayloadAction<{ callback: (hash: string) => void }>
    ) => {},
    updateResetPasswordFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `resetPasswordForm.${action.payload.key}`,
        action.payload.value
      );
    },

    clearResetPasswordFrom: (state) => {
      state.resetPasswordForm.password = "";
      state.resetPasswordForm.confirmPassword = "";
    },
    doUpdatePassword: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    setResetPasswordCode: (
      state,
      action: PayloadAction<ResetPasswordInterface>
    ) => {
      state.resetPasswordForm = action.payload;
    },
    doVerifyForgotPasswordCode: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
