export interface TeamListInterface {
  _id: string;
  name: string;
  createdAt: string;
  teamMemberCount: number;
}
export interface IProjectList {
  _id: string;
  name: string;
  projectMemberCount: number;
  createdAt: string;
}
export interface TeamInterface {
  _id: string;
  name: string;
  search: string;
  teamMemberCount: number;
  teamMember: TeamMemberInterface[];
}
export interface TeamMemberInterface {
  _id: string;
  name: string;
  email: string;
  teamRole: TeamMemberRoleEnum;
  profilePic: string;
  invitationExpired?: boolean;
  isInvited?: boolean;
  userStatus?: TeamMemberActiveStatusEnum;
  isNew: boolean;
}

export enum TeamMemberRoleEnum {
  VIEWER = 1,
  EDITOR = 2,
  REMOVE = 3,
}
export enum TeamMemberActiveStatusEnum {
  ACTIVE = 1,
  INACTIVE = 2,
}
export interface AddTeamInterface {
  team: boolean;
  fromDashboard: boolean;
}
export interface ResendInvitationInterface {
  email: string;
  teamRole: TeamMemberRoleEnum;
}

export interface ManageTeamState {
  form: TeamInterface;
  list: IProjectList[];
  userList: TeamMemberInterface[];
  userForm: TeamMemberInterface;
  loading: boolean;
  buttonLoading: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  addTeam: AddTeamInterface;
  deleteTeam: boolean;
  resendInvitationForm: ResendInvitationInterface;
  clearAutoComplete: boolean;
  userSearch: boolean;
  sortColumn: string;
  sortDir: string;
}

export type InitialManageTeamState = ManageTeamState;
