import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../../types";

const selectDomain = (state: RootState) => {
  if (state && state.manageTeamState) {
    return state.manageTeamState;
  } else {
    return initialState;
  }
};

export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectForm = createSelector([selectDomain], (state) => state.form);
export const selectUserForm = createSelector(
  [selectDomain],
  (state) => state.userForm
);
export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectButtonLoading = createSelector(
  [selectDomain],
  (state) => state.buttonLoading
);
export const selectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const selectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const selectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const selectUserList = createSelector(
  [selectDomain],
  (state) => state.userList
);
export const selectAddTeam = createSelector(
  [selectDomain],
  (state) => state.addTeam
);
export const selectDeleteTeam = createSelector(
  [selectDomain],
  (state) => state.deleteTeam
);
export const selectResendInvitationForm = createSelector(
  [selectDomain],
  (state) => state.resendInvitationForm
);
export const selectClearAutoComplete = createSelector(
  [selectDomain],
  (state) => state.clearAutoComplete
);
export const selectUserSearch = createSelector(
  [selectDomain],
  (state) => state.userSearch
);
export const selectSortColumn = createSelector(
  [selectDomain],
  (state) => state.sortColumn
);
export const selectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir
);
