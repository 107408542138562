import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../../types";

const selectDomain = (state: RootState) => {
  if (state && state.paymentState) {
    return state.paymentState;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectPaymentSuccessful = createSelector(
  [selectDomain],
  (state) => state.paymentSuccessful
);
export const selectForm = createSelector([selectDomain], (state) => state.form);
export const selectCancelSubscription = createSelector(
  [selectDomain],
  (state) => state.cancelSubscription
);
export const selectBillingList = createSelector(
  [selectDomain],
  (state) => state.billingList
);
export const selectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const selectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const selectSortColumn = createSelector(
  [selectDomain],
  (state) => state.sortColumn
);
export const selectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir
);
export const selectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
