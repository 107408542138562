/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { authRepoSaga } from "../../../Redux/Login/saga";
import {
  sliceKey as authSliceKey,
  reducer as authReducer,
} from "../../../Redux/Login/slice";
import { profileRepoSaga } from "../../../Redux/AccountSettings/Profile/saga";
import {
  sliceKey as profileSliceKey,
  reducer as profileReducer,
} from "../../../Redux/AccountSettings/Profile/slice";
import { manageTeamRepoSaga } from "../../../Redux/AccountSettings/ManageTeam/saga";
import {
  sliceKey as manageTeamSliceKey,
  reducer as manageTeamReducer,
} from "../../../Redux/AccountSettings/ManageTeam/slice";
import { updatePasswordRepoSaga } from "../../../Redux/AccountSettings/Password/saga";
import {
  sliceKey as updatePasswordSliceKey,
  reducer as updatePasswordReducer,
} from "../../../Redux/AccountSettings/Password/slice";
import { forgotPasswordRepoSaga } from "../../../Redux/Login/ForgotPassword/saga";
import {
  sliceKey as forgotPasswordSliceKey,
  reducer as forgotPasswordReducer,
} from "../../../Redux/Login/ForgotPassword/slice";
import { overviewRepoSaga } from "../../../Redux/Subscription/Overview/saga";
import {
  sliceKey as overviewSliceKey,
  reducer as overviewReducer,
} from "../../../Redux/Subscription/Overview/slice";
import { dashboardRepoSaga } from "../../../Redux/Dashboard/saga";
import {
  sliceKey as dashboardSliceKey,
  reducer as dashboardReducer,
} from "../../../Redux/Dashboard/slice";
import { reviewRepoSaga } from "../../../Redux/Review/saga";
import {
  sliceKey as reviewSliceKey,
  reducer as reviewReducer,
} from "../../../Redux/Review/slice";
import { NotificationSettingRepoSaga } from "../../../Redux/AccountSettings/Notification/saga";
import {
  sliceKey as NotificationSettingSliceKey,
  reducer as NotificationSettingReducer,
} from "../../../Redux/AccountSettings/Notification/slice";
import { PaymentRepoSaga } from "../../../Redux/Subscription/Payment/saga";
import {
  sliceKey as PaymentSliceKey,
  reducer as PaymentReducer,
} from "../../../Redux/Subscription/Payment/slice";
import { FileAndFolderRepoSaga } from "../../../Redux/Dashboard/FileAndFolder/saga";
import {
  sliceKey as FileAndFolderSliceKey,
  reducer as FileAndFolderReducer,
} from "../../../Redux/Dashboard/FileAndFolder/slice";
import { SharedProjectRepoSaga } from "../../../Redux/Dashboard/SharedProject/saga";
import {
  sliceKey as SharedProjectSliceKey,
  reducer as SharedProjectReducer,
} from "../../../Redux/Dashboard/SharedProject/slice";
import { TeamRepoSaga } from "../../../Redux/Dashboard/Team/saga";
import {
  sliceKey as TeamSliceKey,
  reducer as TeamReducer,
} from "../../../Redux/Dashboard/Team/slice";
import {
  sliceKey as ReviewCommentSliceKey,
  reducer as ReviewCommentReducer,
} from "../../../Redux/Review/CommentList/slice";
import { ReviewCommentRepoSaga } from "../../../Redux/Review/CommentList/saga";

interface Props {
  children: any;
}
const Index: React.FC<Props> = ({ children }) => {
  useInjectReducer({ key: authSliceKey, reducer: authReducer });
  useInjectSaga({ key: authSliceKey, saga: authRepoSaga });
  useInjectReducer({ key: profileSliceKey, reducer: profileReducer });
  useInjectSaga({ key: profileSliceKey, saga: profileRepoSaga });
  useInjectReducer({ key: manageTeamSliceKey, reducer: manageTeamReducer });
  useInjectSaga({ key: manageTeamSliceKey, saga: manageTeamRepoSaga });
  useInjectReducer({
    key: updatePasswordSliceKey,
    reducer: updatePasswordReducer,
  });
  useInjectSaga({ key: updatePasswordSliceKey, saga: updatePasswordRepoSaga });
  useInjectReducer({
    key: forgotPasswordSliceKey,
    reducer: forgotPasswordReducer,
  });
  useInjectSaga({ key: forgotPasswordSliceKey, saga: forgotPasswordRepoSaga });
  useInjectReducer({ key: overviewSliceKey, reducer: overviewReducer });
  useInjectSaga({ key: overviewSliceKey, saga: overviewRepoSaga });
  useInjectReducer({ key: PaymentSliceKey, reducer: PaymentReducer });
  useInjectSaga({ key: PaymentSliceKey, saga: PaymentRepoSaga });
  useInjectReducer({ key: dashboardSliceKey, reducer: dashboardReducer });
  useInjectSaga({ key: dashboardSliceKey, saga: dashboardRepoSaga });
  useInjectReducer({ key: reviewSliceKey, reducer: reviewReducer });
  useInjectSaga({ key: reviewSliceKey, saga: reviewRepoSaga });
  useInjectReducer({
    key: NotificationSettingSliceKey,
    reducer: NotificationSettingReducer,
  });
  useInjectSaga({
    key: NotificationSettingSliceKey,
    saga: NotificationSettingRepoSaga,
  });
  useInjectReducer({
    key: FileAndFolderSliceKey,
    reducer: FileAndFolderReducer,
  });
  useInjectSaga({ key: FileAndFolderSliceKey, saga: FileAndFolderRepoSaga });
  useInjectReducer({
    key: SharedProjectSliceKey,
    reducer: SharedProjectReducer,
  });
  useInjectSaga({ key: SharedProjectSliceKey, saga: SharedProjectRepoSaga });
  useInjectReducer({ key: TeamSliceKey, reducer: TeamReducer });
  useInjectSaga({ key: TeamSliceKey, saga: TeamRepoSaga });
  useInjectReducer({
    key: ReviewCommentSliceKey,
    reducer: ReviewCommentReducer,
  });
  useInjectSaga({ key: ReviewCommentSliceKey, saga: ReviewCommentRepoSaga });

  return children;
};

export default Index;
