import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../../types";

const selectDomain = (state: RootState) => {
  if (state && state.reviewCommentList) {
    return state.reviewCommentList;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectButtonLoading = createSelector(
  [selectDomain],
  (state) => state.buttonLoading
);
export const selectCarouselLoading = createSelector(
  [selectDomain],
  (state) => state.carouselLoading
);
export const selectAccessLink = createSelector(
  [selectDomain],
  (state) => state.accessLink
);
export const selectFileCarousel = createSelector(
  [selectDomain],
  (state) => state.fileCarousel
);
export const selectVersion = createSelector(
  [selectDomain],
  (state) => state.version
);

export const selectCommentForm = createSelector(
  [selectDomain],
  (state) => state.commentForm
);
export const selectReplyCommentForm = createSelector(
  [selectDomain],
  (state) => state.replyCommentForm
);
export const selectCommentList = createSelector(
  [selectDomain],
  (state) => state.commentList
);
export const selectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const selectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const selectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const selectHasMore = createSelector(
  [selectDomain],
  (state) => state.hasMore
);
export const selectFilter = createSelector(
  [selectDomain],
  (state) => state.filter
);
export const selectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir
);
export const selectDeleteComment = createSelector(
  [selectDomain],
  (state) => state.deleteComment
);
export const selectProjectData = createSelector(
  [selectDomain],
  (state) => state.projectData
);

export const selectUploadedFiles = createSelector(
  [selectDomain],
  (state) => state.uploadedFiles
);
export const selectEditComment = createSelector(
  [selectDomain],
  (state) => state.editComment
);
export const selectEditReplyComment = createSelector(
  [selectDomain],
  (state) => state.editReplyComment
);
export const selectShowReply = createSelector(
  [selectDomain],
  (state) => state.showReply
);
export const selectCommentOnHoverSelectedId = createSelector(
  [selectDomain],
  (state) => state.commentOnHoverSelectedId
);
export const selectCurrentReplyComment = createSelector(
  [selectDomain],
  (state) => state.currentReplyComment
);
export const selectCurrentComment = createSelector(
  [selectDomain],
  (state) => state.currentComment
);

export const selectSelectedOnClickComment = createSelector(
  [selectDomain],
  (state) => state.selectedOnClickComment
);
export const selectClickOnCommentOptions = createSelector(
  [selectDomain],
  (state) => state.clickOnCommentOptions
);
export const selectCommentUpdate = createSelector(
  [selectDomain],
  (state) => state.commentUpdate
);
export const selectCurrentCommentSelectedId = createSelector(
  [selectDomain],
  (state) => state.currentCommentSelectedId
);
