import { BrowserRouter, Route, Routes } from "react-router-dom";
import loadable from "@loadable/component";
import Queue from "../Pages/Test";
import React, { Suspense } from "react";

const Dashboard = React.lazy(() => import("../Pages/Dashboard/Index"));
const Login = React.lazy(() => import("../Components/Login"));
const AccountSetting = React.lazy(
  () => import("../Pages/Profile/AccountSetting/Index")
);
const Subscription = React.lazy(
  () => import("../Pages/Profile/Subscription/Index")
);
const Review = React.lazy(() => import("../Pages/ReviewSection"));
const EmailVerification = React.lazy(
  () => import("../Components/Dashboard/EmailVerification")
);

const AccountDisabled = React.lazy(
  () => import("../Components/Dashboard/EmailVerification/AccountDisabled")
);
const UnsubscribeEmail = React.lazy(
  () => import("../Components/Dashboard/EmailVerification/UnsubscribeEmail")
);
const Notifications = React.lazy(() => import("../Pages/Notification"));
const Payment = React.lazy(() => import("../Pages/Profile/Payment"));
const Page404 = React.lazy(() => import("../Pages/404"));

function MainRoutes() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Login page={"/"} />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/:id" element={<Dashboard />} />
            <Route path="/dashboard/:id/:fid" element={<Dashboard />} />
            <Route path="/review" element={<Review />} />
            <Route path="/f/:fcode" element={<Review />} />
            <Route path="/review/:id/:fileVersionId" element={<Review />} />
            <Route
              path="/review/:id/:fileVersionId/:fileVersionV2Id"
              element={<Review />}
            />

            <Route path="/register" element={<Login page={"register"} />} />
            <Route path="/sign-in" element={<Login page={"sign-in"} />} />
            <Route
              path="/forgot-password"
              element={<Login page={"forgot-password"} />}
            />
            <Route
              path="/reset-password"
              element={<Login page={"reset-password"} />}
            />
            <Route
              path="/confirm-email"
              element={<Login page={"confirm-email"} />}
            />
            <Route
              path="/confirm-email/:id"
              element={<Login page={"confirm-email"} />}
            />
            <Route
              path="/confirm-forgot-email"
              element={<Login page={"confirm-forgot-email"} />}
            />
            <Route
              path="/confirm-forgot-email/:id"
              element={<Login page={"confirm-forgot-email"} />}
            />
            <Route path="/account-setting" element={<AccountSetting />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route
              path="/payment"
              element={<Payment currentPage="payment" />}
            />
            <Route
              path="/payment-confirmation"
              element={<Payment currentPage="payment confirmation" />}
            />
            <Route
              path="/cancel-subscription"
              element={<Payment currentPage="cancel subscription" />}
            />
            <Route
              path="/payment-failed"
              element={<Payment currentPage="payment failed" />}
            />

            <Route
              path="/project-invitation/:id"
              element={<Login page={"project-invitation"} />}
            />
            <Route path="/p/:code" element={<Login page={"p"} />} />
            <Route
              path="/project-notification-invitation/:id"
              element={<Login page={"project-notification-invitation"} />}
            />
            <Route
              path="/email-verification/:id"
              element={<EmailVerification />}
            />
            <Route
              path="/account-deactivated/:id"
              element={<AccountDisabled />}
            />
            <Route
              path="/restore-project/:id"
              element={<Login page={"restore-project"} />}
            />
            <Route path="/queue" element={<Queue />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="*" element={<Page404 />} />
            <Route path="/unsubscribe-email" element={<UnsubscribeEmail />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default MainRoutes;
