import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import {
  OverviewInterface,
  PlanEnumInterface,
  PlanListInterface,
  PlanOrderInterface,
  SubscriptionTypeEnum,
} from "./types";
import { set } from "lodash";

export const useClinicSlice = createSlice({
  name: "overviewState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setPlan: (state, action: PayloadAction<string>) => {
      state.plan = action.payload;
    },
    setBilled: (state, action: PayloadAction<SubscriptionTypeEnum>) => {
      state.billed = action.payload;
    },
    clearFrom: (state) => {
      state.form.projects = 0;
      state.form.users = 0;
      state.form.teams = 0;
      state.form.storage = 0;
      state.form.maxProjects = 0;
      state.form.maxUsers = 0;
      state.form.maxTeams = 0;
      state.form.maxStorage = 0;
      state.form.plan = PlanEnumInterface.FREE;
    },
    doGetEdit: (state) => {
      state.loading = true;
    },
    setDataForEdit: (state, action: PayloadAction<OverviewInterface>) => {
      state.form = action.payload;
    },
    doGetPlansList: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    setPlansList: (state, action: PayloadAction<Array<PlanListInterface>>) => {
      state.planList = action.payload;
    },

    doSelectPlan: (
      state,
      action: PayloadAction<{
        planId: string;
        subscriptionType: SubscriptionTypeEnum;
        price: number;
        callback: (id: string) => void;
      }>
    ) => {},
    setPlanOrderData: (state, action: PayloadAction<PlanOrderInterface>) => {
      state.planOrderData = action.payload;
    },
    doSelectedPlanSubscription: (
      state,
      action: PayloadAction<{
        orderId: string;
        paymentMethod: any;
        address: any;
        callback: (data: any) => void;
      }>
    ) => {},
    updatePlanSubscriptionFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `planSubscription.${action.payload.key}`,
        action.payload.value
      );
    },
    clearPlanSubscriptionFrom: (state) => {
      state.planSubscription.accountHolderName = "";
      state.planSubscription.orderId = "";
      state.planSubscription.address.city = "";
      state.planSubscription.address.country = "";
      state.planSubscription.address.line1 = "";
      state.planSubscription.address.line2 = "";
      state.planSubscription.address.postal_code = "";
      state.planSubscription.address.state = "";
    },
    setStripeProcess: (state, action: PayloadAction<boolean>) => {
      state.stripProcess = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
