import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CatchBlockFunction from "../../../Components/hooks/CatchError";
import CustomToast from "../../../Components/CustomToast";
import {
  doGetCommentList,
  setMarkAsComplete,
  doDeleteComment,
  doLikeComments,
  doUpdateComments,
  doSendReplyComments,
  doUpdateReplyComments,
  doGetReviewCommentById,
  doGetCommentReplyById,
  GetCommentById,
} from "../../../utils/request";
import { t } from "i18next";
import { CommentFormInterface, ReplyCommentFormInterface } from "./types";
import {
  selectCommentForm,
  selectCommentList,
  selectFilter,
  selectPageNo,
  selectPageSize,
  selectReplyCommentForm,
  selectSortDir,
} from "./selector";

export function* doGetCommentsListRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  if (
    action.payload.id.length > 0 &&
    action.payload.id != undefined &&
    action.payload.id != null
  ) {
    try {
      yield put(actions.setLoading(true));
      const commentList: [] = yield select(selectCommentList);
      const pageNo: number = yield select(selectPageNo);
      const pageSize: number = yield select(selectPageSize);
      const filter: string = yield select(selectFilter);
      const sortDir: string = yield select(selectSortDir);

      const response: AxiosResponse = yield call(
        doGetCommentList,
        action.payload.id,
        `?pageNo=${pageNo}&pageSize=${pageSize}${
          filter.length > 0 ? "&filter=" + `${filter}` : ""
        }${sortDir.length > 0 ? "&sortDir=" + sortDir : ""}`
      );
      yield put(actions.setLoading(false));
      if (response && !response.data) {
        CustomToast(response.data.message, "ERROR");
        return;
      }

      if (pageNo == 1) {
        yield put(
          actions.setTotalRow(
            response.data.data.meta ? response.data.data.meta.total : 0
          )
        );
        yield put(actions.setCommentList(response.data.data.results));
      } else {
        const concatData = commentList.concat(response.data.data.results);
        yield put(
          actions.setTotalRow(
            response.data.data.meta ? response.data.data.meta.total : 0
          )
        );
        yield put(actions.setCommentList(concatData));
      }
      yield put(
        actions.setHasMore(
          response.data.data.meta.totalPages >= response.data.data.meta.page
        )
      );
      yield call(action?.payload?.callback());
    } catch (error: any) {
      if (
        error?.response?.data?.statusCode === 4005 ||
        error?.response?.data?.statusCode === 4006
      ) {
        return;
      }
      yield put(actions.setLoading(false));
      CatchBlockFunction(error);
    }
  }
}

export function* doMarkAsCompleteRequest(action: {
  payload: { id: string; value: boolean; callback: any };
}) {
  yield delay(500);

  try {
    let data = {
      markAsComplete: action?.payload?.value,
    };
    const response: AxiosResponse = yield call(
      setMarkAsComplete,
      action.payload.id,
      data
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doDeleteCommentRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      doDeleteComment,
      action.payload.id
    );

    yield put(actions.setButtonLoading(false));
    yield put(actions.setCurrentCommentSelectedId(null));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doLikeCommentRequest(action: {
  payload: { id: string; value: boolean; callback: any };
}) {
  yield delay(500);

  try {
    let data = {
      like: action?.payload?.value,
    };
    const response: AxiosResponse = yield call(
      doLikeComments,
      action.payload.id,
      data
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetCommentByIdRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setCommentUpdate(true));
  try {
    const response: AxiosResponse = yield call(
      doGetReviewCommentById,
      action.payload.id
    );
    yield put(actions.setCommentUpdate(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setDataInForm(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setCommentUpdate(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateCommentRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  const form: CommentFormInterface = yield select(selectCommentForm);
  yield put(actions.setButtonLoading(true));
  try {
    let data = {
      comment: form.comment,
      rawComment: form.rawComment,
      mentionedMember: form.mentionedMember,
    };
    const response: AxiosResponse = yield call(
      doUpdateComments,
      action.payload.id,
      data
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetCommentReplyByIdRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      doGetCommentReplyById,
      action.payload.id
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setReplyDataInForm(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
// Reply
export function* doAddReplyCommentRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: ReplyCommentFormInterface = yield select(selectReplyCommentForm);
  if (form.reply.length == 0) {
    CustomToast(`${t("ERROR_MESSAGE.REVIEW_MESSAGES.TYPE_COMMENT")}`, "ERROR");
    return;
  }
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(doSendReplyComments, form);
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateReplyCommentRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  const form: ReplyCommentFormInterface = yield select(selectReplyCommentForm);
  yield put(actions.setButtonLoading(true));
  try {
    let data = {
      commentId: form?.commentId,
      reply: form?.reply,
      rawReply: form?.rawReply,
      mentionedMember: form?.mentionedMember,
    };

    const response: AxiosResponse = yield call(
      doUpdateReplyComments,
      action.payload.id,
      data
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    // CustomToast(response.data.message, "SUCCESS");
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetCommentListByIdRequest(action: {
  payload: { id: string; fileVersionId: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setLoading(true));
  const commentList: [] = yield select(selectCommentList);
  const pageNo: number = yield select(selectPageNo);
  try {
    const response: AxiosResponse = yield call(
      GetCommentById,
      action.payload.id,
      `?fileVersionId=${action.payload.fileVersionId}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    if (pageNo == 1) {
      yield put(
        actions.setTotalRow(
          response.data.data.meta ? response.data.data.meta.total : 0
        )
      );
      yield put(actions.setCommentList(response.data.data.results));
    } else {
      const concatData = commentList.concat(response.data.data.results);
      yield put(
        actions.setTotalRow(
          response.data.data.meta ? response.data.data.meta.total : 0
        )
      );
      yield put(actions.setCommentList(concatData));
    }
    yield put(actions.setHasMore(response.data.data.results.length > 0));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* ReviewCommentRepoSaga() {
  yield takeLatest(actions.doGetCommentList, doGetCommentsListRequest); //
  yield takeLatest(actions.doGetCommentListById, doGetCommentListByIdRequest); //
  yield takeLatest(actions.doMarkAsComplete, doMarkAsCompleteRequest);
  yield takeLatest(actions.doDeleteComment, doDeleteCommentRequest);
  yield takeLatest(actions.doLikeComment, doLikeCommentRequest);

  // new Work
  yield takeLatest(actions.doGetCommentById, doGetCommentByIdRequest);
  yield takeLatest(actions.doUpdateComment, doUpdateCommentRequest);

  //reply comment
  yield takeLatest(actions.doAddCommentReply, doAddReplyCommentRequest);
  yield takeLatest(actions.doGetCommentReplyById, doGetCommentReplyByIdRequest);
  yield takeLatest(actions.doUpdateReplyComment, doUpdateReplyCommentRequest);
}
