import CustomToast from "../../CustomToast";

export default function ErrorRender(error: any) {
  if (error.response.data.errors) {
    for (let index = 0; index < error.response.data.errors.length; index++) {
      const element = error.response.data.errors[index];
      CustomToast(element.message, "ERROR");
    }
    return false;
  }
  return true;
}
