import axios from "axios";
import Api from "./Api";
const header = () => {
  return {
    Authorization: "Bearer " + localStorage.getItem("token"),
    "x-custom-lang": localStorage.getItem("i18nextLng") || "en",
  };
}; /*** API Type  */
const getAPI = async (url: string) => {
  return Api.get(url, {
    headers: header(),
  });
};
const putAPI = async (url: string, data: any) => {
  return Api.put(url, data, {
    headers: header(),
  });
};
const postAPI = async (url: string, data: any) => {
  return Api.post(url, data, {
    headers: header(),
  });
};
const loginRequest = async (data: any) => {
  return Api.post(`auth/user/user-login`, data, {
    headers: header(),
  });
};
//googleLogin

const googleLoginRequest = async (data: any) => {
  return Api.post(`/auth/user/google-signup`, data, {
    headers: header(),
  });
};

const sendVerificationCodeRequest = async (data: any) => {
  return Api.post(`auth/user/send-verification-code`, data, {
    headers: header(),
  });
};
const verifyCodeRequest = async (data: any) => {
  return Api.post(`auth/user/verify-code`, data, {
    headers: header(),
  });
};
const verifyForgotPasswordCodeRequest = async (data: any) => {
  return Api.post(`public/user/forgot-password-verify-code`, data, {
    headers: header(),
  });
};
const sendForgetPasswordCodeRequest = async (data: any) => {
  return Api.post(`public/user/send-forgot-password-code`, data, {
    headers: header(),
  });
};
const resendEmailRequest = async (data: any) => {
  return Api.post(`auth/user/resend-email`, data, {
    headers: header(),
  });
};
const updatePasswordRequest = async (data: any) => {
  return Api.post(`public/user/forgot-password`, data, {
    headers: header(),
  });
};
const meProfileRequest = async () => {
  return Api.get(`auth/user/me`, {
    headers: header(),
  });
};
const doGetUserDetails = async (filters: string) => {
  return Api.get(`auth/user/get-detail${filters}`, {
    headers: header(),
  });
};
const doDeactivateAccount = async (data: any) => {
  return Api.post(`auth/user/deactivate-account`, data, {
    headers: header(),
  });
};
const doVerifyEmailUpdated = async (data: any) => {
  return Api.post(`auth/user/verify-email-by-token`, data, {
    headers: header(),
  });
};
const GetUserProfileDetails = async () => {
  return Api.get(`public/user/my-profile`, {
    headers: header(),
  });
};
const doDeleteAccount = async (filter: string) => {
  return Api.delete(`public/user/delete-account${filter}`, {
    headers: header(),
  });
};
//Manage Teams section start//
const doAddTeam = async (data: any) => {
  return Api.post(`/team/create`, data, {
    headers: header(),
  });
};
const doGetTeamsList = async (filter: string) => {
  return Api.get(`/team/list${filter}`, {
    headers: header(),
  });
};
const doGetTeamEdit = async (id: string) => {
  return Api.get(`/team/get/${id}`, {
    headers: header(),
  });
};
const doUpdateTeam = async (data: any) => {
  return Api.put(`/team/update/${data._id}`, data, {
    headers: header(),
  });
};
const doDeleteTeam = async (id: string) => {
  return Api.delete(`/team/delete/${id}`, {
    headers: header(),
  });
};
const doGetAllUser = async (data: any) => {
  return Api.get(`/team/get-user${data}`, {
    headers: header(),
  });
};
const doResendTeamInvitation = async (id: any, data: any) => {
  return Api.put(`team/resend-invitation/${id}`, data, {
    headers: header(),
  });
};
//team invitation
const doGetTeamInvitationDetails = async (filter: string) => {
  return Api.get(`/team/get-detail${filter}`, {
    headers: header(),
  });
};
const doVerifyTeamInvitation = async (data: any) => {
  return Api.put(`/team/verify-invitation`, data, {
    headers: header(),
  });
};
const doGetTeamRestoreDetails = async (filter: string) => {
  return Api.get(`/team/get-detail-by-token${filter}`, {
    headers: header(),
  });
};

const doTeamRestore = async (data: any) => {
  return Api.put(`/team/restore`, data, {
    headers: header(),
  });
};
//Manage Teams section end//
//Update password section start//
const doChangePasswordRequest = async (data: any) => {
  return Api.put(`/public/user/update-password`, data, {
    headers: header(),
  });
};
const doGetGoogleAuth = async () => {
  return Api.get(`/public/user/get-google-auth`, {
    headers: header(),
  });
};
const doUpdateGoogleAuth = async (data: any) => {
  return Api.put(`/public/user/update-google-auth`, data, {
    headers: header(),
  });
};
const doSendCodeForGoogleAuth = async (data: any) => {
  return Api.post(`public/user/send-reset-password-code`, data, {
    headers: header(),
  });
};
//Update password section end//
//Profile update section start//
const doProfileUpdateRequest = async (data: any) => {
  return Api.patch(`/public/user/profile-update`, data, {
    headers: header(),
  });
};
const doGetProfileDetailsRequest = async () => {
  return Api.get(`/public/user/get-detail`, {
    headers: header(),
  });
};
//Profile update section end//
//Notification settings section//
const doUpdateNotificationSettings = async (data: any) => {
  return Api.post(`/notification-setting/create`, data, {
    headers: header(),
  });
};
const doGetNotificationSettings = async () => {
  return Api.get(`/notification-setting/get`, {
    headers: header(),
  });
};
const doGetTimezones = async () => {
  return Api.get(`timezone/get-list`, {
    headers: header(),
  });
};
//Notification settings section//

//Payment section// doGetPaymentDetails,doUpdatePaymentDetails
const doGetPaymentDetails = async () => {
  return Api.get(`/invoice-setting/get`, {
    headers: header(),
  });
};
const doUpdatePaymentDetails = async (data: any) => {
  return Api.post(`/invoice-setting/update`, data, {
    headers: header(),
  });
};
const CancelSubscription = async () => {
  return Api.get(`/order/cancel`, {
    headers: header(),
  });
};
const BillingHistory = async (filter: any) => {
  return Api.get(`/order/billing-history${filter}`, {
    headers: header(),
  });
};
//Payment section//
//file section//
const doCreateFolder = async (data: any) => {
  return Api.post(`/file/add-folder`, data, {
    headers: header(),
  });
};
const doGetFolderList = async (id: string, filters: any) => {
  return Api.get(`/file/folder-list/${id}${filters}`, {
    headers: header(),
  });
};
const doGetProjectDetails = async (id: string) => {
  return Api.get(`file/get-file-detail/${id}`, {
    headers: header(),
  });
};
const doGetFileData = async (id: string) => {
  return Api.get(`/file/get-file-full-detail/${id}`, {
    headers: header(),
  });
};
const doMakeFilePrivate = async (data: any) => {
  return Api.put(`/file/make-private`, data, {
    headers: header(),
  });
};
const doGetFolderStructure = async (id: string) => {
  return Api.get(`file/get-folder-structure/${id}`, {
    headers: header(),
  });
};
const doGetFolderListStructure = async (id: string) => {
  return Api.get(`file/get-structure/${id}`, {
    headers: header(),
  });
};
const doGetNestedFolderStructure = async (filter: string) => {
  return Api.get(`file/get-structure/${filter}`, {
    headers: header(),
  });
};
const doGetSearchFolderStructure = async (filter: string) => {
  return Api.get(`file/get-folders/${filter}`, {
    headers: header(),
  });
};
const doGetSearchProjectStructure = async (filter: string) => {
  return Api.get(`file/get-projects/${filter}`, {
    headers: header(),
  });
};
const doSetFileStatus = async (data: any) => {
  return Api.put(`/file/update-version-status`, data, {
    headers: header(),
  });
};
const doDuplicateFileAndFolder = async (id: string, data: any) => {
  return Api.put(`file/duplicate/${id}`, data, {
    headers: header(),
  });
};
const doRenameFile = async (id: string, data: any) => {
  return Api.put(`file/rename/${id}`, data, {
    headers: header(),
  });
};
const doMoveFile = async (id: string, data: any) => {
  return Api.put(`file/move/${id}`, data, {
    headers: header(),
  });
};
const doCopyFile = async (id: string, data: any) => {
  return Api.put(`file/copy/${id}`, data, {
    headers: header(),
  });
};
const doDeleteFile = async (id: string) => {
  return Api.delete(`file/delete/${id}`, {
    headers: header(),
  });
};
//file section//
//project section //
const doCreateProjectRequest = async (data: any) => {
  return Api.post(`/project/create`, data, {
    headers: header(),
  });
};
const doGetProjectListRequest = async () => {
  return Api.get(`/project/list`, {
    headers: header(),
  });
};
const doGetProjectEditRequest = async (id: string) => {
  return Api.get(`/project/get/${id}`, {
    headers: header(),
  });
};
const doUpdateProjectRequest = async (data: any) => {
  return Api.put(`/project/update/${data._id}`, data, {
    headers: header(),
  });
};
const doGetSharedProjectsRequest = async () => {
  return Api.get(`/project/shared-project-list`, {
    headers: header(),
  });
};
const doGetProjectSharedMember = async (id: any) => {
  return Api.get(`/project/get-member/${id}`, {
    headers: header(),
  });
};
const doGetSharedProjectMembers = async (id: any) => {
  return Api.get(`/project/get-project-share/${id}`, {
    headers: header(),
  });
};
const doProjectShare = async (data: any) => {
  return Api.post(`/project/share`, data, {
    headers: header(),
  });
};

const doAddProjectMember = async (data: any) => {
  return Api.post(`/project/add-user`, data, {
    headers: header(),
  });
};
const doGetProjectMember = async (id: string) => {
  return Api.get(`/project/get-project-member/${id}`, {
    headers: header(),
  });
};

const DeleteProject = async (id: string) => {
  return Api.delete(`project/delete/${id}`, {
    headers: header(),
  });
};
const doResendProjectInvitation = async (id: string, data: any) => {
  return Api.put(`project/resend-invitation/${id}`, data, {
    headers: header(),
  });
};
const ManageProjectUsers = async (id: string, data: any) => {
  return Api.put(`project/manage-members/${id}`, data, {
    headers: header(),
  });
};
// profile pic upload //
const ProfileImageSingedUrl = async (data: any) => {
  return Api.post(
    `auth/user/signed-url`,
    { fileName: data },
    {
      headers: header(),
    }
  );
};
const uploadProfileImage = async (
  signedUrl: any,
  data: any,
  contentType: any
) => {
  return axios.put(`${signedUrl}`, data, {
    headers: {
      "Content-Type": contentType,
    },
  });
};
// profile pic upload //
// file upload //
const fileSingedUrl = async (data: any) => {
  return Api.post(`file/single-signed-url`, data, {
    headers: header(),
  });
};
const folderSingedUrl = async (data: any) => {
  return Api.post(`file/signed-url`, data, {
    headers: header(),
  });
};
const uploadFilesAndFolder = async (
  signedUrl: any,
  data: any,
  contentType: any,
  onProgress: any
) => {
  return axios.put(`${signedUrl}`, data, {
    headers: {
      "Content-Type": contentType,
    },
    onUploadProgress: onProgress,
  });
};
const doCreateFileRequestApi = async (data: any) => {
  return Api.post(`file/create-file`, data, {
    headers: header(),
  });
};
const doShareFiles = async (data: any) => {
  return Api.post(`file/share`, data, {
    headers: header(),
  });
};
const doGetShareFileData = async (id: any) => {
  return Api.get(`file/get-file-share/${id}`, {
    headers: header(),
  });
};
// file upload //
// team dashboard section //
const doGetTeamList = async () => {
  return Api.get(`/team/get-list`, {
    headers: header(),
  });
};
const doCreateTeamProject = async (data: any) => {
  return Api.post(`/project/team-create`, data, {
    headers: header(),
  });
};
//project invitation
const doGetProjectInvitationDetail = async (filter: string) => {
  return Api.get(`/project/get-detail${filter}`, {
    headers: header(),
  });
};
const doGetProjectNotificationInvitationDetail = async (id: string) => {
  return Api.get(`/project/invitation-project-detail/${id}`, {
    headers: header(),
  });
};
const GetProjectViaLinkInvitation = async (code: string) => {
  return Api.get(`/project/get-link-detail?link=${code}`, {
    headers: header(),
  });
};
const doVerifyProjectInvitation = async (data: any) => {
  return Api.put(`/project/verify-invitation`, data, {
    headers: header(),
  });
};
const doVerifyProjectNotificationInvitation = async (data: any) => {
  return Api.put(`/project/accept-invitation`, data, {
    headers: header(),
  });
};
const VerifyProjectViaLink = async (code: string) => {
  return Api.put(
    `/project/verify-invitation-via-link?link=${code}`,
    {},
    {
      headers: header(),
    }
  );
};
// team dashboard section //
// Review section //
const doGetReviewFile = async (id: any) => {
  return Api.get(`file/get/${id}`, {
    headers: header(),
  });
};
const doGetFolderFiles = async (id: any) => {
  return Api.get(`file/get-list/${id}`, {
    headers: header(),
  });
};
const doGetIndicatorList = async (id: any) => {
  return Api.get(`comment/get-by-time-stamp/${id}`, {
    headers: header(),
  });
};
const doGetCommentList = async (id: any, filter: string) => {
  return Api.get(`comment/get-list/${id}${filter}`, {
    headers: header(),
  });
};
const GetCommentById = async (id: string, fileVersionId: any) => {
  return Api.get(`comment/get-list-by-comment/${id}${fileVersionId}`, {
    headers: header(),
  });
};
const doSendComments = async (data: any) => {
  return Api.post(`comment/add`, data, {
    headers: header(),
  });
};
const doUpdateComments = async (id: any, data: any) => {
  return Api.put(`comment/update/${id}`, data, {
    headers: header(),
  });
};
const setMarkAsComplete = async (id: any, data: any) => {
  return Api.put(`comment/mark-complete/${id}`, data, {
    headers: header(),
  });
};
const doDeleteComment = async (id: any) => {
  return Api.delete(`comment/delete/${id}`, {
    headers: header(),
  });
};
const doLikeComments = async (id: any, data: any) => {
  return Api.put(`comment/like/${id}`, data, {
    headers: header(),
  });
};
const doGetMentionMemberList = async (id: any) => {
  return Api.get(`file/get-member-list/${id}`, {
    headers: header(),
  });
};
const GetSelfMentionMemberList = async (id: any) => {
  return Api.get(`file/get-all-member-list/${id}`, {
    headers: header(),
  });
};
const doGetFileVersions = async (id: any) => {
  return Api.get(`file/get-versions-list/${id}`, {
    headers: header(),
  });
};
const commentFileSignedUrl = async (data: any) => {
  return Api.post(`comment/signed-url`, data, {
    headers: header(),
  });
};
const doRenameFileVersion = async (id: string, data: any) => {
  return Api.put(`file/rename-version/${id}`, data, {
    headers: header(),
  });
};
const uploadCommentFiles = async (
  signedUrl: any,
  data: any,
  contentType: any
) => {
  return axios.put(`${signedUrl}`, data, {
    headers: {
      "Content-Type": contentType,
    },
  });
};
const doSendReplyComments = async (data: any) => {
  return Api.post(`comment/add-reply`, data, {
    headers: header(),
  });
};
const doUpdateReplyComments = async (id: any, data: any) => {
  return Api.put(`comment/update-reply/${id}`, data, {
    headers: header(),
  });
};
const doGetReviewCommentById = async (id: any) => {
  return Api.get(`comment/get/${id}`, {
    headers: header(),
  });
};
const doGetCommentReplyById = async (id: any) => {
  return Api.get(`comment/get-reply/${id}`, {
    headers: header(),
  });
};
const fileVersionSignedUrl = async (data: any) => {
  return Api.post(`file/version-signed-url`, data, {
    headers: header(),
  });
};
const uploadFilesVersion = async (
  signedUrl: any,
  data: any,
  contentType: any
) => {
  return axios.put(`${signedUrl}`, data, {
    headers: {
      "Content-Type": contentType,
    },
  });
};
const createFileVersions = async (data: any) => {
  return Api.post(`file/create-version`, data, {
    headers: header(),
  });
};
const doAddProgressBarIndicator = async (data: any) => {
  return Api.post(`file/create-version`, data, {
    headers: header(),
  });
};
const doUpdateVersionsOrder = async (id: any, data: any) => {
  return Api.put(`file/update-version-index/${id}`, data, {
    headers: header(),
  });
};
const doGetAttachedFigures = async (id: string) => {
  return Api.get(`comment/get-figure/${id}`, {
    headers: header(),
  });
};
const GetUserFileShareDetail = async (filter: string) => {
  return Api.get(`file/get-user-file-share-detail${filter}`, {
    headers: header(),
  });
};
const GetFileViaLink = async (code: string) => {
  return Api.get(`file/get-link-detail/${code}`, {
    headers: header(),
  });
};
const doVerifyAccessLinkPassword = async (
  id: string,
  data: { linkPassword: string }
) => {
  return Api.put(`file/validate-file-share-password/${id}`, data, {
    headers: header(),
  });
};
// Review section //
// notification //
const doGetAllNotification = async (filter: any) => {
  return Api.get(`notification/get-all${filter}`, {
    headers: header(),
  });
};
const doGetUnreadNotifications = async () => {
  return Api.get(`notification/get-list`, {
    headers: header(),
  });
};
const doReadAllNotification = async () => {
  return Api.put(
    `notification/read-all`,
    {},
    {
      headers: header(),
    }
  );
};
const NotificationRead = async (id: string) => {
  return Api.put(
    `notification/read/${id}`,
    {},
    {
      headers: header(),
    }
  );
};
//Overview section//
const doGetOverview = async () => {
  return Api.get(`/public/user/account-overview`, {
    headers: header(),
  });
};
const doGetPlanList = async () => {
  return Api.get(`plan/user`, {
    headers: header(),
  });
};
const SelectPlan = async (data: any) => {
  return Api.post(`order/add`, data, {
    headers: header(),
  });
};
const SelectedPlanSubscription = async (data: any) => {
  return Api.post(`/order/update`, data, {
    headers: header(),
  });
};
//Overview section//

const getFolderFileListRequest = async (id: string) => {
  return Api.get(`/file/folder-download/${id}`, {
    headers: header(),
  });
};
export {
  getAPI,
  putAPI,
  postAPI,
  getFolderFileListRequest,
  doAddProgressBarIndicator,
  sendForgetPasswordCodeRequest,
  sendVerificationCodeRequest,
  updatePasswordRequest,
  verifyCodeRequest,
  verifyForgotPasswordCodeRequest,
  loginRequest,
  googleLoginRequest,
  meProfileRequest,
  doGetUserDetails,
  doDeactivateAccount,
  doVerifyEmailUpdated,
  doDeleteAccount,
  resendEmailRequest,
  doAddTeam,
  doGetTeamsList,
  doGetTeamEdit,
  doUpdateTeam,
  doDeleteTeam,
  doGetAllUser,
  doResendTeamInvitation,
  doChangePasswordRequest,
  doGetGoogleAuth,
  doUpdateGoogleAuth,
  doSendCodeForGoogleAuth,
  doProfileUpdateRequest,
  doGetProfileDetailsRequest,
  doCreateProjectRequest,
  doGetProjectListRequest,
  doGetProjectEditRequest,
  doUpdateProjectRequest,
  doGetSharedProjectsRequest,
  doGetProjectSharedMember,
  doGetProjectMember,
  doGetSharedProjectMembers,
  doProjectShare,
  doAddProjectMember,
  doUpdateNotificationSettings,
  doGetNotificationSettings,
  doGetTimezones,
  doGetOverview,
  doGetPlanList,
  doCreateFolder,
  doGetFolderList,
  doGetProjectDetails,
  doMakeFilePrivate,
  doGetFolderStructure,
  doGetNestedFolderStructure,
  doSetFileStatus,
  doDuplicateFileAndFolder,
  doRenameFile,
  doMoveFile,
  doCopyFile,
  doDeleteFile,
  ProfileImageSingedUrl,
  uploadProfileImage,
  fileSingedUrl,
  folderSingedUrl,
  uploadFilesAndFolder,
  doCreateFileRequestApi,
  doShareFiles,
  doGetShareFileData,
  doGetTeamList,
  doCreateTeamProject,
  doVerifyTeamInvitation,
  doGetTeamRestoreDetails,
  doTeamRestore,
  doGetProjectInvitationDetail,
  doVerifyProjectInvitation,
  doGetTeamInvitationDetails,
  doGetPaymentDetails,
  doUpdatePaymentDetails,
  doGetReviewFile,
  doGetFolderFiles,
  doGetCommentList,
  doSendComments,
  doUpdateComments,
  setMarkAsComplete,
  doDeleteComment,
  doLikeComments,
  doGetMentionMemberList,
  DeleteProject,
  doResendProjectInvitation,
  doGetFileVersions,
  doRenameFileVersion,
  commentFileSignedUrl,
  uploadCommentFiles,
  doSendReplyComments,
  doUpdateReplyComments,
  doGetReviewCommentById,
  doGetCommentReplyById,
  fileVersionSignedUrl,
  uploadFilesVersion,
  createFileVersions,
  doUpdateVersionsOrder,
  doGetAttachedFigures,
  GetUserFileShareDetail,
  doVerifyAccessLinkPassword,
  doGetAllNotification,
  doGetUnreadNotifications,
  doReadAllNotification,
  ManageProjectUsers,
  doGetIndicatorList,
  doGetFileData,
  SelectPlan,
  SelectedPlanSubscription,
  CancelSubscription,
  BillingHistory,
  GetUserProfileDetails,
  GetCommentById,
  NotificationRead,
  GetSelfMentionMemberList,
  doGetProjectNotificationInvitationDetail,
  doVerifyProjectNotificationInvitation,
  doGetSearchFolderStructure,
  doGetSearchProjectStructure,
  doGetFolderListStructure,
  GetProjectViaLinkInvitation,
  VerifyProjectViaLink,
  GetFileViaLink,
};
