import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import {
  sendVerificationCodeRequest,
  verifyCodeRequest,
  loginRequest,
  meProfileRequest,
  googleLoginRequest,
  resendEmailRequest,
  doGetUserDetails,
  doDeactivateAccount,
  doVerifyEmailUpdated,
} from "../../utils/request";
import {
  selectAccountDeactivateForm,
  selectEmailVerificationCodeForm,
  selectLoginForm,
  selectRegisterForm,
  selectVerifyEmailForm,
} from "./selector";
import {
  AccountDeactivateInterface,
  EmailVerificationInterface,
  LoginInterface,
  RegisterInterface,
} from "./types";
import CatchBlockFunction from "../../Components/hooks/CatchError";
import CustomToast from "../../Components/CustomToast";
import { t } from "i18next";
// google login
export function* doGoogleLoginRequest(action: {
  payload: { data: any; callback: any };
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      googleLoginRequest,
      action.payload.data
    );

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.setToken(response.data.data.accessToken));
    yield put(actions.setLoading(false));
    yield put(actions.setIsLogin(true));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doRegisterRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: RegisterInterface = yield select(selectRegisterForm);
  if (form.name.length === 0) {
    CustomToast(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_NAME")}`, "ERROR");
    return;
  }
  if (form.email.length === 0) {
    CustomToast(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_EMAIL")}`, "ERROR");
    return;
  }
  if (form.password.length === 0) {
    CustomToast(
      `${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_PASSWORD")}`,
      "ERROR"
    );
    return;
  }

  const data = {
    email: form.email,
    password: btoa(form.password),
    name: form.name,
  };
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      sendVerificationCodeRequest,
      data
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.setVerificationCode(response.data.data));
    yield call(action.payload.callback(response.data.data.hash));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doLoginRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: LoginInterface = yield select(selectLoginForm);

  if (form.email.length == 0) {
    CustomToast(`${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_EMAIL")}`, "ERROR");
    return;
  }
  if (form.password.length == 0) {
    CustomToast(
      `${t("ERROR_MESSAGE.COMMON_MESSAGES.ENTER_PASSWORD")}`,
      "ERROR"
    );
    return;
  }
  let data = {
    email: form.email,
    password: btoa(form.password),
  };
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(loginRequest, data);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }

    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.setIsLogin(true));
    yield put(actions.setToken(response.data.data.accessToken));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetMeProfileRequest(action: {
  payload: { token: string; callback: any };
}) {
  yield delay(500);

  if (action.payload.token) {
    try {
      yield put(actions.setLoading(true));
      const response: AxiosResponse = yield call(meProfileRequest);
      if (response && !response.data) {
        CustomToast(response.data.message, "ERROR");
        return;
      }
      yield put(
        actions.setProfileData({
          name: response.data.data.name,
          profilePic: response.data.data.profilePic,
          email: response.data.data.email,
          _id: response.data.data._id,
          loginCount: response.data.data.loginCount,
        })
      );
      yield put(actions.setIsLogin(true));
      yield put(actions.setLoading(true));
      yield call(
        action?.payload?.callback({
          role: response.data.role,
          id: response.data.id,
          loginCount: response.data.data.loginCount,
        })
      );
    } catch (error: any) {
      yield put(actions.setLoading(false));
      if (error?.response?.data?.statusCode == 404) {
        yield put(actions.clearLocalStorage());
        return;
      } else {
        CatchBlockFunction(error);
      }
    }
  } else {
    yield put(actions.setLoading(false));
    yield put(actions.setIsLogin(false));
  }
}
export function* doVerifyCodeRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: EmailVerificationInterface = yield select(
    selectEmailVerificationCodeForm
  );
  if (form.code.length == 0) {
    CustomToast(`${t("ERROR_MESSAGE.AUTH_MESSAGES.ENTER_OTP")}`, "ERROR");
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(verifyCodeRequest, form);
    yield put(actions.clearLocalStorage());
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    CustomToast(response.data.message, "SUCCESS");
    yield put(actions.setToken(response.data.data.accessToken));
    yield call(action?.payload?.callback(response.data.data.accessToken));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetResendEmailRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: EmailVerificationInterface = yield select(
    selectEmailVerificationCodeForm
  );
  const data = {
    hash: form.hash,
  };
  try {
    const response: AxiosResponse = yield call(resendEmailRequest, data);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    } else {
      CustomToast(response.data.message, "SUCCESS");
    }
    yield put(actions.setVerificationCode(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetDetailsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      doGetUserDetails,
      `?token=${action.payload.id}`
    );
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setUserEmail(response.data.data.email));
    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doDeactivateAccountRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: AccountDeactivateInterface = yield select(
    selectAccountDeactivateForm
  );
  try {
    const response: AxiosResponse = yield call(doDeactivateAccount, form);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield call(action.payload.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doVerifyUpdatedEmailRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: AccountDeactivateInterface = yield select(selectVerifyEmailForm);
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doVerifyEmailUpdated, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setToken(response.data.data.accessToken));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* authRepoSaga() {
  yield takeLatest(actions.doLogin, doLoginRequest);
  yield takeLatest(actions.doGoogleLogin, doGoogleLoginRequest);
  yield takeLatest(actions.doSendVerificationCode, doRegisterRequest);
  yield takeLatest(actions.doVerifyCode, doVerifyCodeRequest);
  yield takeLatest(actions.doGetProfile, doGetMeProfileRequest);
  yield takeLatest(actions.doResendEmail, doGetResendEmailRequest);
  yield takeLatest(actions.doGetDetails, doGetDetailsRequest);
  yield takeLatest(actions.doDeactivateAccount, doDeactivateAccountRequest);
  yield takeLatest(actions.doVerifyUpdatedEmail, doVerifyUpdatedEmailRequest);
}
