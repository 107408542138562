import { ProfileState } from ".";
import { PlanEnumInterface } from "../../../Subscription/Overview/types";

export const initialState: ProfileState = {
  loading: false,
  updateLoading: false,
  form: {
    _id: "",
    name: "",
    email: "",
    profilePic: "",
    language: "",
    passwordUpdateCount: 0,
    googleId: "",
    emailVerified: false,
    googleAuthentication: "",
    loginCount: 0,
    canDeleteProject: false,
    planExpiryDate: "",
    japaneseName: "",
    planId: "",
    planType: PlanEnumInterface.FREE,
    planTitle: "",
    canCancelled: false,
    versioningAllowed: false,
    projectsAllowed: 0,
    projects: 0,
    teams: 0,
    teamsAllowed: 0,
  },
  confirmProfile: false,
  password: "",
};
