import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../../types";

const selectDomain = (state: RootState) => {
  if (state && state.sharedProjectState) {
    return state.sharedProjectState;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectForm = createSelector([selectDomain], (state) => state.form);
export const selectUserForm = createSelector(
  [selectDomain],
  (state) => state.userForm
);
export const selectShareProject = createSelector(
  [selectDomain],
  (state) => state.shareProject
);
export const selectProjectMembersList = createSelector(
  [selectDomain],
  (state) => state.projectMemberList
);
export const selectProjectInvitationForm = createSelector(
  [selectDomain],
  (state) => state.projectInvitation
);
export const selectProjectInvitationDetailsForm = createSelector(
  [selectDomain],
  (state) => state.projectInvitationDetails
);
export const selectAddUser = createSelector(
  [selectDomain],
  (state) => state.addUser
);
export const selectProjectMemberForm = createSelector(
  [selectDomain],
  (state) => state.projectMember
);
export const selectProjectNotificationInvitationDetailsForm = createSelector(
  [selectDomain],
  (state) => state.projectNotificationInvitationDetails
);
