import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import {
  AddTeamInterface,
  IProjectList,
  TeamInterface,
  TeamListInterface,
  TeamMemberInterface,
  TeamMemberRoleEnum,
} from "./types";
import { set } from "lodash";

export const useClinicSlice = createSlice({
  name: "manageTeamState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    toggleAddTeam: (state, action: PayloadAction<AddTeamInterface>) => {
      state.addTeam = action.payload;
    },
    toggleDeleteTeam: (state, action: PayloadAction<boolean>) => {
      state.deleteTeam = action.payload;
    },
    editTeamMemberAccess: (
      state,
      action: PayloadAction<{
        Index: number;
        value: any;
      }>
    ) => {
      state.form.teamMember[action.payload.Index].teamRole =
        action.payload.value;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },

    clearFrom: (state) => {
      state.form._id = "";
      state.form.name = "";
      state.form.search = "";
      state.form.teamMember = [];
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},
    doDeleteTeam: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    setList: (state, action: PayloadAction<Array<IProjectList>>) => {
      state.list = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<TeamInterface>) => {
      state.form = action.payload;
    },
    setUserDataForEdit: (state, action: PayloadAction<TeamMemberInterface>) => {
      state.userForm.name = action.payload.name;
      state.userForm.email = action.payload.email;
      state.userForm._id = action.payload._id;
      state.userForm.teamRole = action.payload.teamRole;
      state.userForm.profilePic = action.payload.profilePic;
    },
    doGetAllUserList: (
      state,
      action: PayloadAction<{
        id: string;
        search: string;
        callback: () => void;
      }>
    ) => {},
    setAllUserList: (
      state,
      action: PayloadAction<{
        result: Array<TeamMemberInterface>;
        search: string;
      }>
    ) => {
      state.userList = action.payload.result;
    },
    clearSearchUserList: (state) => {
      state.userList = [];
    },
    doAddUser: (state) => {
      state.form.teamMember.push({
        _id: state.userForm._id,
        email: state.userForm.email,
        name: state.userForm.name,
        teamRole: state.userForm.teamRole,
        profilePic: state.userForm.profilePic,  
        isNew: state.userForm.isNew,  
      });
    },
    clearAddUserFrom: (state) => {
      state.form.search = "";
    },
    //Resend invitation
    doResendInvitation: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    updateResendInvitationFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `resendInvitationForm.${action.payload.key}`,
        action.payload.value
      );
    },
    clearResendInvitationFrom: (state) => {
      state.resendInvitationForm.email = "";
      state.resendInvitationForm.teamRole = TeamMemberRoleEnum.VIEWER;
    },
    clearAutoComplete: (state, action: PayloadAction<boolean>) => {
      state.clearAutoComplete = action.payload;
    },
    setUserSearch: (state, action: PayloadAction<boolean>) => {
      state.userSearch = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
